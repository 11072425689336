import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import { getTimePast } from 'helpers';
import { Badge, Col, Image, Row } from 'react-bootstrap';

import chatPng from '../../assets/icons/notificaciones/report.png'


const obtenerBadgeTypeMessage = ( idType, message ) => {

  const colorMap = {
    1: 'danger',
    2: 'primary',
    3: 'warning',
  };

  return <Badge pill bg={ colorMap[idType] || 'info' }>{ message }</Badge>
};

const Notification = ({
  avatar,
  time,
  className,
  unread,
  flush,
  icon,
  children,
  typePriority
}) => (
  <Link
    className={classNames(
      'notification',
      { 'notification-unread': unread, 'notification-flush': flush },
      className
    )}
    to="#!"
  >
    {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} className="me-3" />
      </div>
    )}
    <div className="notification-body w-100">
      <div className='row'>

        {/* IMAGEN */}
        <div className='col-3 d-flex align-items-center justify-content-center'>
          {/* <Image src={chatPng} alt="Local GIF" className="img-fluid w-75 mx-auto" /> */}
          { icon 
            ? (<Image src={icon} alt="Local GIF" className="img-fluid w-75 mx-auto" />)
            : (<p></p>)
          }
        </div> 

        {/* CONTENIDO NOTIFICACION */}
        <div className='col-9 d-flex flex-column'>
          <p className='fw-bold m-0'>{children}</p>

          <Row className='d-flex justify-content-between mt-1'>
            <Col xl={6} xs={12} ><p className='fw-light p-0 m-0'>{getTimePast(new Date(time))}</p></Col>
            <Col xl={6} xs={12} >{ ( typePriority ) ? obtenerBadgeTypeMessage(typePriority.id, typePriority.description) : ''}</Col>
          </Row>
        </div> 

      </div>
      {/* <span className="notification-time">
        {emoji && (
          <span className="me-2" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
        <br></br>
      </span> */}
    </div>
  </Link>
);

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
  typePriority: PropTypes.node
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;

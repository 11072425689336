import {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";

import useAuth from "hooks/useAuth";
import axios from 'utils/axios';
import { toastNotification } from 'helpers/utils';

const getAllAvisosMantenciones = async () => {
  const url = `${process.env.REACT_APP_ADMIN_SERVICE}/avisoMantencion/checkAvisoMantencion`;
  try {
      const { data } = await axios.get(url);
      return (data.statusCode === 200) ? data.data : [];
  } catch (error) {
      console.error(error);
      toastNotification('error', 'Ocurrió un error al intentar obtener los avisos de mantenciones del sistema')
      return [];
  }
};

const SessionTimeout = () => {
  const { user } = useAuth();
  const [ openModal, setOpenModal ] = useState(false);
  const [ avisoMantencion, setAvisoMantencion ] = useState([]);

  const cerrarModal = () =>{
    setOpenModal(false);
  }

  useEffect( async () => {
    if( localStorage.getItem('showAvisoMantencion') ) return;
    const avisosMantencion = await getAllAvisosMantenciones();
    if( avisosMantencion.length > 0 ){
      setAvisoMantencion(avisosMantencion[0]);
      setOpenModal(true);
      localStorage.setItem('showAvisoMantencion', true);
    }
  }, [user]);

  return <div><Modal show={openModal}
                backdrop="static"
                keyboard={false}
                size='lg'
  >
    <Modal.Header>
      <Modal.Title>Aviso de Mantención</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Estimado usuario,
      </p>
      <p>
        Le informamos que el sistema estará en mantención programada desde el <strong>{new Date(avisoMantencion.fechaInicio).toLocaleString()}</strong> hasta el <strong>{new Date(avisoMantencion.fechaFin).toLocaleString()}</strong>.
      </p>
      <p>
        Durante este período, el sistema no estará disponible. Le recomendamos que guarde su trabajo y cierre sesión antes del inicio de la mantención para evitar cualquier inconveniente.
      </p>
      <p>
        Agradecemos su comprensión y paciencia mientras trabajamos para mejorar nuestros servicios.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={cerrarModal} >Aceptar</Button>
    </Modal.Footer>
  </Modal>
  </div>;
};

export default SessionTimeout;
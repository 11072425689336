import useAuth from "hooks/useAuth";
import RedirectByRole from "components/RedirectByRole";
import {Navigate} from "react-router-dom";

// ----------------------------------------------------------------------



export default function RoleBasedGuard({ accessibleRoles, children }) {
    const {user,isAuthenticated} = useAuth();
    if( user.userFirstLogin === true ){
        return <Navigate to={"/firstLogin/"} />;
    }

    if (!accessibleRoles.includes(user.idTypeProfile)) {
        if (isAuthenticated) {
            return <RedirectByRole/>
        }
    }
    return <>{children}</>;
}

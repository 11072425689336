import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button} from 'react-bootstrap';

const ModalRegiones = ({ showRegionModal, setShowRegionModal, idToUpdate, setIdToUpdate, regionToUpdate, setRegionToUpdate, createNewRegion, updateRegion, paises }) => {

  const [ nombre, setNombre ] = useState( ( idToUpdate > 0 ) ? regionToUpdate.nombre : '' )
  const [ pais, setPais ] = useState({})
  const [ validated, setValidated ] = useState(false);


  useEffect(() => {
    if(showRegionModal == false) return
    if( idToUpdate > 0 ){
        setNombre(regionToUpdate.nombre)
        setPais(regionToUpdate.country.id)
    }else{
        setNombre('')
        setPais(0)
    }
  }, [showRegionModal])

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }
    
    if( idToUpdate > 0 ){
        const region = { id: idToUpdate, nombre, idPais: Number(pais)  }
        updateRegion(region)
    }else{
        createNewRegion({ nombre, idPais: Number(pais) })
    }
  }



  return (
    <>
    <Modal
        show={showRegionModal}
        centered
        onHide={() => {
            setShowRegionModal(false)
            setIdToUpdate(0)
            setRegionToUpdate({})
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? 'Actualizar Región' : "Nueva Región" }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowRegionModal(false)
                    setIdToUpdate(0)
                    setRegionToUpdate({})
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>


                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                type="text"
                                name="Nombre"
                                required
                                value={nombre}
                                onChange={(e) => {
                                    setNombre(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar un nombre valido.
                            </Form.Control.Feedback>              
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label> Seleccione un País</Form.Label>
                            <Form.Select
                                value={pais}
                                onChange={(e) => {
                                    setPais(e.target.value)
                                }}
                            >  
                                <option value={0} >Seleccione Opcion</option>
                                { paises.map( pais => 
                                    (<option key={pais.id} value={pais.id}>{pais.nombre}</option>)
                                 ) }
                            </Form.Select>
                        </Form.Group>

                        <Form.Group aria-disabled={true}>
                            <Button
                                variant="secondary"
                                className="m-2"
                                onClick={() => {
                                    setShowRegionModal(false)
                                    setIdToUpdate(0)
                                    setRegionToUpdate({})
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" variant="success" className="m-2">
                                Guardar
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default ModalRegiones
import { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap"

import axios from 'utils/axios';

import FalconCardHeader from "components/common/FalconCardHeader"
import BasicBarChart from 'components/common/BasicBarChart'

export const CantidadEquiposPorAreaChart = () => {
    
    useEffect( () => getData(), [] );

    const [ readyContent, setReadyContent ] = useState(false);
    const [ data, setData ] = useState([]);

    const getData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/area/getCountEquipmentsByAreasUser`);
            const dataRefactorized = (data.statusCode === 200) ? data.data.map( dato => { return { name: dato.nameArea, value: dato.countEquipos } } ) : [];
            setData(dataRefactorized);
        } catch (error) {
            console.error('Error al intentar obtener la cantidad de equipos por area');
        }
        setReadyContent(true);
    };

    return (
        <Card className="my-3">
            <FalconCardHeader light title={'Cantidad de Equipos por Área'} titleTag="h6" className="py-3" />
            <Card.Body className='p-3'>

                { !readyContent && <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> }

                { readyContent && (
                    data.length > 0 
                    ? <BasicBarChart data={data} key={'EquiposPorArea'} />
                    : <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}>
                        <p>No se han encontrado datos</p>
                      </div>
                ) }

            </Card.Body>
        </Card>
    )
}

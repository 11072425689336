import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import IconButton from "components/common/IconButton";
import { Button, Col, Modal, Row, Spinner} from "react-bootstrap";
import { columns } from "./data";
import { NuevoReporte } from "./NuevoReporte";
import useAuth from "hooks/useAuth";
import axios from "utils/axios";
import EditarReport from "./EditarReport";
import { getWeekOnYear } from "helpers/index";

import BitacoraData from 'pages/common/components/Bitacora'
import { toastNotification } from "helpers/utils";
import ValidateAccessEquipment from "components/pages/ValidateAccessEquipment";
import moment from "moment";
import TipoPerfilEnum from 'utils/TipoPerfilEnum';
import { TablaArchivos } from "pages/common/components";
import { DetalleReporte } from "./DetalleReporte";
import useLoadingContext from "hooks/useLoadingContext";

const idEntidad = 25;
let idDatoBitacora = 0;

export const TablaReportes = () => {

  const navigate = useNavigate();
  let params = useParams();

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);

  const [ reports, setReports ] = useState([]);

  const [ showDetailReportModal, setShowDetailReportModal ] = useState(false);
  const [ showReportModal, setShowReportModal ] = useState(false);
  const [ showEditReportModal, setShowEditReportModal ] = useState(false);
  const [ showDeleteReportModal, setShowDeleteReportModal ] = useState(false);
  const [ showFilesModal, setShowFilesModal ] = useState(false);
  const [ deleteReport, setDeleteReport ] = useState();
  const [ editarReport, setEditarReport ] = useState();
  const [ files, setFiles ] = useState([]);

  //? Bitacora
  const [ showBitacora, setShowBitacora ] = useState(false);

  const [ reportToDetail, setReportToDetail ] = useState({});

  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    !hasAccess ? navigate("/vendedor/inicio") : startResetApp();
  }, []);
  
  const startResetApp = async () => {
    getReportes();
  }

  const getReportes = async () => {
  
    let isAdmin = user.idTypeProfile == TipoPerfilEnum.administrador ? true : false;

    if (user != null) {
      await axios
        .get(`${process.env.REACT_APP_EQUIPO_SERVICE}/report/getReportByEquipment/${params.id}`)
        .then((response) => {
          const list = response.data.data;
          list.forEach((element) => {
            element.published = getWeekOnYear(element.dateVisit);
            element.dateVisit = moment(element.dateVisit).format('DD-MM-YYYY');
            element.accion = [
              <IconButton
                style={{ 
                  width: isAdmin ? '20%' : '',
                  boxShadow: 'none',
                  display: isAdmin ? '' : 'none'
                }}
                title="Editar"
                variant="white"
                size="sm"
                icon="edit"
                onClick={() => {
                  setShowEditReportModal(true);
                  setEditarReport(element);
                }}
              ></IconButton>,
              <IconButton
                style={{ 
                  width: isAdmin ? '20%' : '',
                  boxShadow: 'none',
                  display: isAdmin ? '' : 'none'
                }}
                title="Eliminar"
                variant="white"
                size="sm"
                icon="trash-alt"
                onClick={() => {
                  setShowDeleteReportModal(true);
                  setDeleteReport(element.id);
                }}
              ></IconButton>,
              <IconButton
                title="Ver Reporte"
                variant="white"
                size="sm"
                icon="eye"
                style={{ 
                  width: isAdmin ? '20%' : '50%',
                  boxShadow: 'none'
                }}
                onClick={() => {
                  setShowDetailReportModal(true)
                  setReportToDetail(element);
                }}
              ></IconButton>,
              <IconButton
                title="Adjuntos"
                variant="white"
                size="sm"
                icon="paperclip"
                style={{ 
                  width: isAdmin ? '20%' : '50%',
                  boxShadow: 'none'
                }}
                onClick={() => {
                  setShowFilesModal(true);
                  setFiles(element.files)
                }}
              ></IconButton>,
              <IconButton
                title="Bitácora"
                variant="white"
                size="sm"
                icon="book"
                style={{ 
                  width: isAdmin ? '20%' : '',
                  boxShadow: 'none',
                  display: isAdmin ? '' : 'none'
                }}
                onClick={() => {
                  setShowBitacora(true);
                  idDatoBitacora = element.id
                }}
              ></IconButton>,
            ];
          });
          setReports(list);
          setReadyContent(true);
        })
        .catch( e => {
          setReadyContent(true);
        });
    }
  }

  const openFile = async(e) => {
      window.open(`${process.env.REACT_APP_EQUIPO_SERVICE}/file/downloadFileById/${e.id}`, '_blank');
  }

  const handleDeleteReport = async () => {
    try {
      setIsLoading(true);
      const resp = await axios.delete(`${process.env.REACT_APP_EQUIPO_SERVICE}/report/${deleteReport}`);
      if( resp.status === 200 ){
        toastNotification('success', 'Elimiado correctamente', 2000)
        setShowDeleteReportModal(false);
        startResetApp();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      console.error("Error al intentar eliminar el reporte");
    }
  };
    
  return (
    <>
    { !readyContent ? 
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
    : (
      <div>
        <AdvanceTableWrapper
          columns={columns}
          data={reports}
          sortable
          pagination
          perPage={5}
        >
          <Row className="d-flex justify-content-center">
            <Col xs={12} className='mx-auto mb-2'>
              { user.idTypeProfile !== TipoPerfilEnum.cliente && (
                <div id="new-report" className="text-sm-center text-lg-start">
                  <IconButton
                    variant="falcon-success"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => setShowReportModal(!showReportModal)}
                  >
                    Nuevo Reporte
                  </IconButton>
                </div>
              ) }
            
            </Col>
          </Row>
          <AdvanceTable
            title="Reportes"
            subtitle="Tabla de Reportes"
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: "fs--1 mb-0 overflow-hidden",
            }}
          />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={reports.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </div>

    ) }
    
      {/* LOS MODALES ESTARAN DISPONIBLE SOLO CUANDO EL USUARIO NO SEA DE TIPO CLIENTE */}
      { user.idTypeProfile !== TipoPerfilEnum.cliente && (
        <>
          {/* Crear Nuevo Reporte */}
          <NuevoReporte startResetApp={startResetApp} showReportModal={showReportModal} setShowReportModal={setShowReportModal} />
          <EditarReport startResetApp={startResetApp} reporte={editarReport} setReporte={setEditarReport} showEditReportModal={showEditReportModal} setShowEditReportModal={setShowEditReportModal} />


          <div> {/* MODAL PARA ELIMINAR UN REPORTE */}
            <Modal
              show={showDeleteReportModal}
              onHide={() => setShowDeleteReportModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar Reporte</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro que quiere eliminar el reporte?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteReportModal(false)}
                  disabled={isLoading}
                >
                  Cancelar
                </Button>
                <Button variant="danger" onClick={handleDeleteReport} disabled={isLoading}>
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

        </>
      )}

      {/* MODAL DE ARCHIVOS */}
      <Modal
        show={showFilesModal}
        onHide={() => setShowFilesModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Archivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <TablaArchivos key={'archivos'} archivos={files} isCrud={false} />

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setShowFilesModal(false)}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal> 

      <DetalleReporte reporte={reportToDetail} showDetailReportModal={showDetailReportModal} setShowDetailReportModal={setShowDetailReportModal} />
      <BitacoraData 
        title="Bitacora del Reporte" 
        titleToExport="Bitácora"
        subtitleToExport="Tabla de Bitácora"
        showBitacoraData={showBitacora} 
        setShowBitacoraData={setShowBitacora} 
        idData={idDatoBitacora}
        idEntidad={idEntidad}
      />
    </>
  );
};

export default TablaReportes;
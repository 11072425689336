import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TablaBiblioteca from './TablaBiblioteca';

const Biblioteca = () => {
    return (
          <Card.Body className="overflow-hidden p-lg-6">
            <Row className="justify-content-between">
              <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                <h3 className="text-primary">Biblioteca</h3>
              </Col>
            </Row>
            <Card>
              <Card.Body>
                <TablaBiblioteca />
              </Card.Body>
            </Card>
          </Card.Body>
      );
    };

export default Biblioteca;

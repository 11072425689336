import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification } from "helpers/utils";

export const EditarItem = ({ data, change, setShowEditCostoModal }) => {
  const [validated, setValidated] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [showPeriodo, setShowPeriodo] = useState(
    data.periodo > 0 ? true : false
  );

  const [idTipoCosto, setIdTipoCosto] = useState(data.idTipoCosto);
  const [valor, setValor] = useState(data.valor);
  const [descripcion, setDescripcion] = useState(data.descripcion);
  const [periodo, setPeriodo] = useState(data.periodo);
  const [diaCosto, setDiaCosto] = useState(data.diaCosto);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_EQUIPO_SERVICE}/tipoCosto/getAllTypeCost`)
      .then((response) => {
        const list = response.data.data;
        setItemList(list);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formCostoAnterior = {
        idTipoCosto: idTipoCosto,
        valor: valor,
        descripcion: descripcion,
        periodo: periodo,
        diaCosto: diaCosto,
      };

      await axios
        .put(
          `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/${data.id}`,
          formCostoAnterior
        )
        .then(() => {
          change(true);
          setShowEditCostoModal(false);
        })
        .catch((err) => {
          console.error(err);
          toastNotification("error", "Costo Anterior no se pudo editar.", 2000);
        });
      setValidated(true);
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Ítem</Form.Label>
          <Form.Select
            required
            name="idTipoCosto"
            value={idTipoCosto}
            onChange={(e) => setIdTipoCosto(e.target.value)}
          >
            <option value="">Seleccione ítem</option>
            {itemList.map((item) => (
              <option name="tipoCosto" key={item.id} value={item.id} disabled={(item.estado === false) ? true : false}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione un ítem.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            autoComplete="off"
            as="textarea"
            rows={3}
            name="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Descripción.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            value={valor}
            name="valor"
            onChange={(e) => setValor(e.target.value)}
            type="number"
            min={1}
            pattern="^[0-9]+"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Costo, número entero y positivo (ej: 1000)
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="m-4">
          <Form.Label>Tipo de costo:</Form.Label>
          <br />
          <div className="text-center ">
            <Form.Check
              inline
              label={`Costo Unitario`}
              name="type"
              type={"radio"}
              id={`fijo`}
              required
              onChange={(e) => {
                setShowPeriodo(false);
                setPeriodo(0);
              }}
              checked={!showPeriodo ? true : false}
            />
            <Form.Check
              inline
              label={`Costo Periódico`}
              name="type"
              type={"radio"}
              id={`periodico`}
              required
              onChange={(e) => {
                setShowPeriodo(true);
              }}
              checked={showPeriodo ? true : false}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Por favor seleccionar Tipo de Costo.
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label>Día Primer Costo (En Días):</Form.Label>
              <Form.Control
                autoComplete="off"
                value={diaCosto}
                name="diaCosto"
                onChange={(e) => setDiaCosto(e.target.value)}
                type="number"
                min={0}
                pattern="^[0-9]+"
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Día Costo, número entero y positivo (ej: 5)
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="6">
            {showPeriodo && (
              <Form.Group className="mb-3">
                <Form.Label>Periodicidad (En Días):</Form.Label>
                <Form.Control
                  autoComplete="off"
                  value={periodo}
                  name="periodo"
                  onChange={(e) => setPeriodo(e.target.value)}
                  type="number"
                  min={1}
                  pattern="^[0-9]+"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Periodo, número entero y positivo sobre 0
                  (ej: 5)
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Col>
        </Row>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditCostoModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

import { useEffect, useState } from 'react'
import { Card, Row, Col, Modal, CloseButton, Button, Badge, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import moment from 'moment';
import { columnsContacto, columnsMesaAyuda } from './data';
import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from 'hooks/useAuth';
import { ModalCerrarSolicitud } from './ModalCerrarSolicitud';
import { ModalConfirmacion } from 'pages/common/components/ModalConfirmacion';
import { faHandsHelping, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { MostrarDetalle } from './MostrarDetalle';
import useLoadingContext from 'hooks/useLoadingContext';

const InicioAdmin = () => {

  const { user } = useAuth();
  const { setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false)

  const [ contactos, setContactos] = useState([]);
  const [ solicitudesMesa, setSolicitudesMesa ] = useState([]);

  const [ deleteContacto, setDeleteContacto] = useState();
  const [ mensaje, setMensaje] = useState([]);

  const [ soliManipulada, setSoliManipulada ] = useState({});

  // MODAL
  const [ showDeleteModal, setShowDeleteModal]                  = useState(false);
  const [ showMensajeModal, setShowMensajeModal]                = useState(false);
  const [ showDetalleModal, setShowDetalleModal ]               = useState(false);
  const [ showCerrarSolicitud, setShowCerrarSolicitud ]         = useState(false);
  const [ showModalTomarSolicitud, setShowModalTomarSolicitud ] = useState(false);

  // DATA
  const [ plantas, setPlantas ] = useState([]);
  const [ perfiles, setPerfiles ] = useState([]);
  const [ usuarios, setUsuarios ] = useState([]);
  const [ equipos, setEquipos ] = useState([]);
  

  const startResetApp = async () => {
    let isCatchExecuted = false; // Variable de bandera
    Promise.all([ getAllContactos(), getAllSoliMesaAyuda(), getAllPlantas(), getAllPerfiles(), getAllUsuarios(), getAllEquipos() ])
      .then(([ allContactos, soliMesaAyuda, allPlantas, allPerfiles, allUsuarios, allEquipos ]) => {
        setReadyContent(true)
    })
    .catch(error => {
      if( !isCatchExecuted ){
        setReadyContent(true)
        isCatchExecuted = true;
        toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
      };
    })
  }

  const getEstadoSolicitud = (estado) => {
    if( estado.codigoEstado.trim() == 'open' ) return ( <Badge pill bg='warning'>{ estado.nombre_estado }</Badge> );
    if( estado.codigoEstado.trim() == 'closed' ) return ( <Badge pill bg='success'>{ estado.nombre_estado }</Badge> );
    if( estado.codigoEstado.trim() == 'in_progress' ) return ( <Badge pill bg='primary'>{ estado.nombre_estado }</Badge> );
  }

  const soliMesaAyudaToTable = (solicitudes) => {

    const solisMesa = solicitudes.map( soli => {
      let solicitud = {};
      solicitud.usuarioSoli = `${soli.usuarioSolicitud.name} ${soli.usuarioSolicitud.lastName}`;
      solicitud.asunto = soli.asunto;
      solicitud.mensaje = soli.mensaje;
      solicitud.shortMsg = `${soli.mensaje.slice(0, 20)}...`;
      solicitud.fechaIngreso = moment(soli.fecha_creacion).format('DD-MM-YYYY');
      solicitud.estado = getEstadoSolicitud(soli.estado_solicitud);
      solicitud.usuarioAten = ( soli.usuarioAtencion != null ) ? `${ soli.usuarioAtencion.name } ${soli.usuarioAtencion.lastName}` : '';
      solicitud.fechaAten = ( soli.fecha_atencion != null ) ? moment(soli.fecha_atencion).format('DD-MM-YYYY') : '';
      solicitud.acciones = [
        <IconButton
          title="Ver Detalle"
          variant="white"
          size="sm"
          icon="eye"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setSoliManipulada(soli);
            setShowDetalleModal(true);
          }}
        />
      ];

      // Asignando botones
      if( soli.estado_solicitud.codigoEstado.trim() == 'open' ){
        solicitud.acciones.push(
          <IconButton
              title="Tomar Solicitud"
              variant="white"
              size="sm"
              icon={faHandsHelping}
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setSoliManipulada(soli)
                setShowModalTomarSolicitud(true)
              }}
          />
        )
      }
      if( soli.estado_solicitud.codigoEstado.trim() == 'in_progress' 
          && soli.usuarioAtencion.id === user.id ){
        
        solicitud.acciones.push(
          <IconButton
              title="Cerrar Solicitud"
              variant="white"
              size="sm"
              icon={faCheckDouble}
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setSoliManipulada(soli)
                setShowCerrarSolicitud(true);
              }}
          />
        )
      }

      return solicitud;
    })
    setSolicitudesMesa(solisMesa);

  }


  //! OBTIENE TODAS LAS SOLICITUDES DE LA MESA DE AYUDA
  const getAllSoliMesaAyuda = async () => {

    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/mesaAyuda/getSolicitudesMesaAyuda`;

    try {
      const { data } = await axios.get(url);
      if( data.statusCode != 200 ) {
        toastNotification('warning', 'Ha ocurrido un error al intentar obtener las solicitudes de la mesa de ayuda.');
        return
      }
      soliMesaAyudaToTable(data.data);
    } catch (error) {
      console.log(error)
    }
  }


  //! CIERRA LA SOLICITUD
  const cerrarSolicitud = async ( descripcionResolucion = '' ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/mesaAyuda/endSolicitudMesaAyuda`;

    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, { idSolicitud: soliManipulada.id, descripcionResolucion })
      if ( data.statusCode != 200 ) {
        toastNotification('warning', 'Ocurrio un error al intentar cerrar la solicitud');
      } else if( data.statusCode == 200 ) {
        toastNotification('success', 'Solicitud cerrada correctamente');
        sendEmailCierreSolicitud(soliManipulada.usuarioSolicitud.id);
        setShowCerrarSolicitud(false);
        getAllSoliMesaAyuda();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toastNotification('danger', 'Ocurrio un error inesperado al intentar cerrar la solicitud');
    }
  }


  //! ENVIA NOTIFICACION AL USUARIO QUE REALIZO LA SOLICITUD
  const sendEmailCierreSolicitud = async ( idUserSoli ) => {
    const url = `${process.env.REACT_APP_EMAILS_SERVICE}/mesaAyudaEmails/sendEmailCloseSolicitud/${idUserSoli}`;
    try {
      await axios.post(url);
    } catch (error) {
      console.error(error);
    }
  }


  //! LE ASIGNA LA SOLICITUD AL USUARIO
  const tomarSolicitud = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/mesaAyuda/updateEstadoSolicitud`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, { idSolicitudMesa: soliManipulada.id, idNewEstado: 2 });
      if( data.statusCode != 200 ) toastNotification('warning', data.message)
      if( data.statusCode == 200 ) {
        toastNotification('success', 'Se ha tomado la solicitud en la mesa de ayuda correctamente');
        setSoliManipulada({});
        getAllSoliMesaAyuda();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error)
      toastNotification('danger', 'Ocurrio un error al intentar tomar la solicitud')
    }
    setShowModalTomarSolicitud(false);
  }

  const getAllContactos = async () => {
    return new Promise((resolve, reject) => {
      
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/contacto/getAllContacts`;
      axios.get(url).then((response) => {
        if(response.data.length < 1) {
          setContactos([])
          return;
        }
        const list = response.data.data
        list.forEach((element) => {
          element.fecha_contacto = moment(element.fecha_contacto).format('DD-MM-YYYY');
          element.acciones = [
            <IconButton
            title="Ver Mensaje"
            variant="white"
            size="sm"
            icon="eye"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={() => {
              setShowMensajeModal(true)
              setMensaje(element.mensaje)
            }}
            ></IconButton>,
            <IconButton
            title="Deshabilitar"
            variant="white"
            size="sm"
            icon="trash-alt"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={() => {
              setShowDeleteModal(true)
              setDeleteContacto(element.id)
            }}
            ></IconButton>
          ]
        })
        setContactos(list.reverse());
        resolve(); // Resuelve la promesa
      })
      .catch((err) => {
        setContactos([]);
        console.error("Error al obtener los contactos");
        reject(err);
      })

    })
  }

  const getAllPlantas = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getAllFloors`;
    axios.get(url).then((response) => {
      if( response.status === 401 ) return;
      if(response.data.length < 1) {
        setPlantas([])
        return;
      }
      const list = response.data.data
      setPlantas(list);
    })
    .catch((err) => {
      console.error("Error al obtener todas las plantas");
    });
  }

  const getAllPerfiles = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/getAllPerfiles`;
    axios.get(url).then((response) => {
      if( response.status === 401 ) return;
      if(response.data.length < 1) {
        setPerfiles([])
        return;
      }

      const list = response.data.data
      setPerfiles(list);
    })
    .catch((err) => {
      console.error("Error al obtener todos los perfiles");
    })
  }

  const getAllUsuarios = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAllUsuarios`;
      axios.get(url).then((response) => {
        if( response.status === 401 ) return;
        if(response.data.length < 1) {
          setUsuarios([])
          return;
        }
  
        const list = response.data.data
        setUsuarios(list);
      })
      .catch((err) => {
        console.error("Error al obtener todos los usuarios")
      })
  }

  const getAllEquipos = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getAllEquipments`;
    axios.get(url).then((response) => {
      if( response.status === 401 ) return;
      if(response.data.length < 1) {
        setEquipos([])
        return;
      }

      const list = response.data.data
      setEquipos(list);
    })
    .catch((err) => {
      console.error("Error al obtener todos los equipos");
    })
  }

  useEffect( () => {
    startResetApp();
  }, [])

    // DESHABILITAR
    const handleDelete = async () => {
      await axios.delete(`${process.env.REACT_APP_ADMIN_SERVICE}/contacto/${deleteContacto}`)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 4) ) return;
        startResetApp()
        setShowDeleteModal(false)
        })
      .catch((err) => {
        console.error("Ha ocurrido un error al intentar elimiar el contacto");
      })
  };

  const dataPlantas = [
    {
      title: 'Plantas',
      value: plantas.length,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/administrador/planta',
      linkText: 'Ver Clientes'
    }
  ];

  const dataPerfiles = [
    {
      title: 'Perfiles',
      value: perfiles.length,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/administrador/perfiles',
      linkText: 'Ver Perfiles'
    }
  ];

  const dataUsuarios = [
    {
      title: 'Usuarios',
      value: usuarios.length,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/administrador/usuarios',
      linkText: 'Ver Usuarios'
    }
  ];

  const dataEquipos = [
    {
      title: 'Equipos',
      value: equipos.length,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/administrador/equipos',
      linkText: 'Ver Equipos'
    }
  ];


  return (
    <>
      <div>
        <Card.Body className="overflow-hidden p-lg-6">

            {/* INFORMACION RELEVANTE DEL SISTEMA */}
            <Row className="justify-content-between mb-4">
              <Col>
                  <h2 className="text-primary">Panel de Administración</h2>
              </Col>
            </Row>
            { !readyContent ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> : (
              <>
                <Row className="g-6 mb-6">
                  {dataPlantas.map((stat, index) => (
                    <Col key={stat.title} sm={index === 2 ? 12 : 6} md={3}>
                      <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                    </Col>
                  ))}
                  {dataPerfiles.map((stat, index) => (
                    <Col key={stat.title} sm={index === 2 ? 12 : 6} md={3}>
                      <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                    </Col>
                  ))}
                  {dataUsuarios.map((stat, index) => (
                    <Col key={stat.title} sm={index === 2 ? 12 : 6} md={3}>
                      <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                    </Col>
                  ))} 
                  {dataEquipos.map((stat, index) => (
                    <Col key={stat.title} sm={index === 2 ? 12 : 6} md={3}>
                      <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                    </Col>
                  ))}
                </Row>

              
                <Card className="h-md-100">

                  <Row className="justify-content-between pt-3">
                    <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                      <h4 className="text-primary">Mesa de Ayuda</h4>
                    </Col>
                  </Row>

                  <Card.Body>
                    <AdvanceTableWrapper
                      columns={columnsMesaAyuda}
                      data={solicitudesMesa}
                      sortable
                      pagination
                      perPage={10}
                    >

                      <AdvanceTable
                        title='Mesa de Ayuda'
                        subtitle='Tabla de Mesa de Ayuda'
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />

                      <div className="mt-3">
                        <AdvanceTableFooter
                          rowCount={solicitudesMesa.length}
                          table
                          rowInfo
                          navButtons
                          rowsPerPageSelection
                        />
                      </div>

                    </AdvanceTableWrapper>
                  </Card.Body>
                </Card>


              <Card className="h-md-100 mt-5">

                <Row className="justify-content-between pt-3">
                  <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                    <h4 className="text-primary">Contactos desde la Web</h4>
                  </Col>
                </Row>

                <Card.Body>
                  <AdvanceTableWrapper
                    columns={columnsContacto}
                    data={contactos}
                    sortable
                    pagination
                    perPage={10}
                  >

                    <AdvanceTable
                      title='Contactos Desde la Web'
                      subtitle='Tabla de Contactos Desde la Web'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />

                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={contactos.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>

                  </AdvanceTableWrapper>
                </Card.Body>
              </Card>
          </>
          ) }
        </Card.Body>
      </div>

      {/* MODAL CERRAR SOLICITUD */}
      <ModalCerrarSolicitud key={1} show={showCerrarSolicitud} setShow={setShowCerrarSolicitud} handleSubmit={cerrarSolicitud} />

      {/* MODAL DE DETALLE */}
      <MostrarDetalle key={2} show={showDetalleModal} setShow={setShowDetalleModal} solicitud={soliManipulada} />

      {/* MODAL CONFIRMACION */}
      <ModalConfirmacion 
        key={3} 
        showModal={ showModalTomarSolicitud } 
        setShowModal={ setShowModalTomarSolicitud } 
        confirmar={tomarSolicitud} 
        title='Tomar Solicitud' 
        msg='¿Esta seguro de tomar la solicitud?. Si toma la solicitud, quedará asignado a esta'  
      />
      
      <h1></h1>

      {/* MODAL DESHABILITAR CLASIFICACION */}
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
            Eliminar Contacto
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowDeleteModal(false)}
            />
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere deshabilitar el Contacto?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleDelete}
            >
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* MODAL BITACORA INDIVIDUAL */}
      <div>
        <Modal
          show={showMensajeModal}
          onHide={() => setShowMensajeModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Mensaje de Contacto
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowMensajeModal(false)}
            />
          </Modal.Header>
          <Modal.Body>
              <p>{mensaje}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowMensajeModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}

export default InicioAdmin

const VideoPlayer = ({ base64Data }) => {
    return (
        <div>
            <video width="100%" controls>
                {/* Se especifica el tipo de video en la URL base64 */}
                <source src={`data:video/mp4;base64,${base64Data}`} type="video/mp4" />
                Tu navegador no soporta el reproductor de video.
            </video>
        </div>
    );
};

export default VideoPlayer;

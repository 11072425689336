import React, { useState, useEffect } from 'react'
import axios from "utils/axios";
import { columnsSizeEquipos } from '../../data'
import { Card, Row, Col, Modal, Spinner, CloseButton, Button } from 'react-bootstrap'

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalDesabilitados = ({ showModalDisabled, setShowModalDisabled, enableData }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ dataDisabled, setDataDisabled ] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idTamañoEquipoToEnable, setIdTamañoEquipoToEnable] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showModalDisabled) {
        setReadyContent(false);
        getAllTamañosDisabled();
    };
  }, [showModalDisabled]);


  useEffect( () => {
    const msgActions = dataDisabled.map( data => {
        data.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                  setShowConfirmation(true);
                  setIdTamañoEquipoToEnable(data.id)
                }}
            ></IconButton>
        ]
        return data
    } )
    setMensajesToTable(msgActions)
    setReadyContent(true);
  }, [dataDisabled]);

    //! GET
  //? Obtener los tamaños de equipos deshabilitados
  const getAllTamañosDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/sizeEquipo/getAllSizeEquiposDisabled`;
    try {
      const { data } = await axios.get(url);
      if(verifyResponseUnauthorizedAwait(data, 1)) return;
      if( data.statusCode == 200 ) setDataDisabled(data.data);
    } catch (error) {
      console.error('Ha ocurrido un error interno al intentar consultar los tamaños de los equipos deshabilitados');
      setReadyContent(true);
    }
  }

  return (
    <>
      <Modal
          size='xl'
          show={showModalDisabled}
          centered
          className={ showConfirmation ? 'd-none' : '' }
          onHide={() => {
              setShowModalDisabled(false)
          }}
          >
          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                  Datos Deshabilitados
              </Modal.Title>
              <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0"
                  onClick={ () => {
                      setShowModalDisabled(false)
                  }}
              />
          </Modal.Header>

          <Modal.Body className="p-3">
          { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
              <Card className='p-3'>
                  { ( dataDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                      <AdvanceTableWrapper
                          columns={columnsSizeEquipos}
                          data={mensajesToTable}
                          sortable
                          pagination
                          perPage={5}
                      >
                      
                      <AdvanceTable
                          title='Tamaños de Equipos Deshabilitados'
                          subtitle='Tabla de Tamaños de Equipos Deshabilitados'
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                          }}
                      />
                      <div className="mt-3">
                          <AdvanceTableFooter
                          rowCount={dataDisabled.length}
                          table
                          rowInfo
                          navButtons
                          rowsPerPageSelection
                          />
                      </div>
                      </AdvanceTableWrapper>
                  ) }
              </Card>
              )}
          </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmation}
        onHide={() => {
          setShowConfirmation(false);
          setIdTamañoEquipoToEnable(0);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Habilitar Tamaño Equipo</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de querer habilitar el tamaño de equipo?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmation(false);
              setIdTamañoEquipoToEnable(0);
            }}
          >
            Cancelar
          </Button>
          <Button variant="success" 
            onClick={() => {
              setShowConfirmation(false);
              setShowModalDisabled(false);
              enableData(idTamañoEquipoToEnable);
            }}
          >
            Habilitar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalDesabilitados
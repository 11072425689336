
export const statsData = [
  {
    id: 1,
    title: 'Propuesta Promather',
    amount: 20,
    target: '10000 vs 8000',
    icon: 'bolt',
    caret: 'caret-down',
    color: 'success',
    caretColor: 'danger',
    data: [200, 150, 175, 130, 150, 115, 130, 100]
  }
];

import React, { useState, useEffect } from 'react'
import { columnsTiposMensajes } from '../../data'
import axios from "utils/axios";
import { Card, Modal, Spinner, CloseButton, Button } from 'react-bootstrap'

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalDesabilitados = ({ showModalDisabled, setShowModalDisabled, enableTipoMensajeById }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ tiposMensajesDisabled, setTiposMensajesDisabled ] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idTipoMensajeToEnable, setIdTipoMensajeToEnable] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  useEffect(() => {
    if (showModalDisabled) {
        getAllTypesMensajesDisabled();
    };
  }, [showModalDisabled]);

  useEffect( () => {
    const msgActions = tiposMensajesDisabled.map( tipo => {
        tipo.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                    setShowConfirmation(true);
                    setIdTipoMensajeToEnable(tipo.id)
                }}
            ></IconButton>
        ]
        return tipo
    } )
    setMensajesToTable(msgActions)
  }, [tiposMensajesDisabled]);

  const getAllTypesMensajesDisabled = async () => {
    setReadyContent(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/getAllTiposMensajesDisabled`;
    try {
      const { data } = await axios.get(url);
      if(verifyResponseUnauthorizedAwait(data, 1)) return;
      if( data.statusCode == 200 ) setTiposMensajesDisabled(data.data)
      setReadyContent(true);
    } catch (error) {
      console.error('Error al intentar obtener los tipos de mensajes deshabilitados');
      setReadyContent(true);
    }
  }

  return (
    <>
        <Modal
            size='xl'
            show={showModalDisabled}
            centered
            className={ showConfirmation ? 'd-none' : '' }
            onHide={() => {
                setShowModalDisabled(false)
            }}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tipos de Mensajes Deshabilitados
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowModalDisabled(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( tiposMensajesDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                        <AdvanceTableWrapper
                            columns={columnsTiposMensajes}
                            data={mensajesToTable}
                            sortable
                            pagination
                            perPage={5}
                        >
                        
                        <AdvanceTable
                            title='Tipos de Mensajes Deshabilitados'
                            subtitle='Tabla de Tipos de Mensajes Deshabilitados'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={tiposMensajesDisabled.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) }
                </Card>
                )}
            </Modal.Body>
        </Modal>

        <Modal
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setIdTipoMensajeToEnable(0);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title>Habilitar Tipo de Mensaje</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de querer habilitar el tipo de mensaje?</Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={() => {
                    setShowConfirmation(false);
                    setIdTipoMensajeToEnable(0);
                }}
            >
                Cancelar
            </Button>
            <Button variant="success" 
                onClick={() => {
                    setShowConfirmation(false);
                    setShowConfirmation(false);
                    enableTipoMensajeById(idTipoMensajeToEnable);
                }}
            >
                Habilitar
            </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default ModalDesabilitados
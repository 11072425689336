export const columnsBitacora = [
    {
      accessor: 'nombreUser',
      Header: 'Usuario'
    },
    {
      accessor: 'descripcion',
      Header: 'Descripción'
    },
    {
      accessor: 'fecha_transaccion',
      Header: 'Fecha'
    },
    {
      accessor: 'hora_transaccion',
      Header: 'Hora'
    },
    {
      accessor: 'nombreEntidad',
      Header: 'Entidad'
    },
]
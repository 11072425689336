/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import Flex from '../Flex';
import IconButton from '../IconButton';

export const AdvanceTableFooter = ({
  page,
  pageSize,
  pageIndex,
  rowCount,
  setPageSize,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  rowInfo,
  rowsPerPageSelection,
  navButtons,
  rowsPerPageOptions = [5, 10, 25],
  className
}) => {
  return (
    <Flex
      className={classNames(
        className,
        'align-items-center justify-content-between'
      )}
    >
      <Flex alignItems="center" className="fs--1">
        {rowInfo && (
          <p className="mb-0 d-none d-md-block">
            <span className="d-none d-sm-inline-block me-2">
              {pageSize * pageIndex + 1} a {pageSize * pageIndex + page.length}{' '}
              de {rowCount} registros
            </span>
          </p>
        )}
        {rowsPerPageSelection && (
          <>
            <p className="mb-0 mx-2 d-none d-md-block">Mostrar</p>
            <Form.Select
              size="sm"
              className="w-auto"
              onChange={e => setPageSize(Number(e.target.value))}
              defaultValue={pageSize}
            >
              {rowsPerPageOptions.map(value => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </Form.Select>
            <p className="mb-0 mx-2">Registros</p>
          </>
        )}
      </Flex>
      
      {navButtons && (
        <Flex className={'gap-3'}>
          <IconButton
              variant={canPreviousPage ? 'primary' : 'light'}
              size="sm"
              icon="arrow-left"
              onClick={() => previousPage()}
              className={classNames({ disabled: !canPreviousPage })}
              iconAlign='left'

            >
              <span className='d-none d-lg-inline-block'>Anterior</span>
            </IconButton>
            <IconButton
              onClick={() => nextPage()}
              variant={canNextPage ? 'primary' : 'light'}
              size="sm"
              icon="arrow-right"
              iconAlign='right'
              className={classNames({ disabled: !canNextPage })}
            >
              <span className='d-none d-lg-inline-block'>Siguiente</span>
            </IconButton>
        </Flex>
      )}
    </Flex>
  );
};

export default AdvanceTableFooter;

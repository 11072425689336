import { Card, Col, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

import { TableWrapper } from "pages/common/components/TableWrapper";
import { columnasTabla } from "../config/ColumnasTabla";

export const TablaComponent = ({ equipos = [] }) => {
    return (
        <>
            <Row className="justify-content-between">
                <Col lg={6} className="ps-lg-4 my-5text-lg-start mt-4">
                    <h3 className="text-primary">Seguimiento de Equipos</h3>
                </Col>
            </Row>

            <Card className="h-md-100">

                <Card.Body>
                    <TableWrapper
                        key={'tableEquipos'}
                        columnas={columnasTabla}
                        data={equipos}
                        elementosPorPagina={10}
                        titleToExport={'Equipos'}
                        subtitleToExport={'Equipos del usuario'}
                    />

                </Card.Body>
            </Card>
        </>
    )
}

TablaComponent.propTypes = {
    equipos: PropTypes.arrayOf(PropTypes.object),
};
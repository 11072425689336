import React, { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  preUser: null
};



const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  PRELOGIN: (state, action) => {
    const { user, isAuthenticated} = action.payload;
    return {
      ...state,
      user: user,
      isAuthenticated: isAuthenticated
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  prelogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};


function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, refreshToken);
          const descodeToken = jwtDecode(accessToken);

          const user = {
            id: descodeToken.user_id,
            displayName: descodeToken.user_name,
            userFirstLogin: descodeToken.user_firstLogin,
            displayLastName: descodeToken.user_lastName,
            userRut: descodeToken.rut_user,
            profileId: descodeToken.profile_id,
            profileDimensional: descodeToken.profile_dimensional,
            profileOperational: descodeToken.profile_operational,
            profileReport: descodeToken.profile_report,
            profileMessage: descodeToken.profile_message,
            profileTecnica: descodeToken.profile_tecnica,
            profileEconomic: descodeToken.profile_economic,
            profileEstadisticaEquipo: descodeToken.profile_estadisticaEquipo,
            userGraphicsConfiguration: descodeToken.userGraphicsConfiguration,
            profileIsPromather: descodeToken.profile_isPromather,
            idTypeProfile: descodeToken.typeProfile._id,
            nameTypeProfile: descodeToken.typeProfile._nombre,
            acccessToken: accessToken,
            refreshToken: refreshToken,

            profilePerfilesAdmin: descodeToken.profile_perfilesAdmin,
            profileUsuariosAdmin: descodeToken.profile_usuariosAdmin,
            profileCorporativosAdmin: descodeToken.profile_corporativosAdmin,
            profilePlantasAdmin: descodeToken.profile_plantasAdmin,
            profileLineAdmin: descodeToken.profile_lineaAdmin,
            profileAreaAdmin: descodeToken.profile_areaAdmin,
            profileAtributosAdmin: descodeToken.profile_atributosAdmin,
            profileEquiposAdmin: descodeToken.profile_equiposAdmin,
            profileTipoEquipoAdmin: descodeToken.profile_tipoEquipoAdmin,
            profileIndustriasAdmin: descodeToken.profile_industriasAdmin,
            profileClasificacionAdmin: descodeToken.profile_clasificacionAdmin,
            profileMarcasAdmin: descodeToken.profile_marcasAdmin,
            profileModelosAdmin: descodeToken.profile_modelosAdmin,
            profileCargosAdmin: descodeToken.profile_cargosAdmin,
            profileTipoAreaAdmin: descodeToken.profile_tipoAreaAdmin,
            profileTipoLineaAdmin: descodeToken.profile_tipoLineaAdmin,
            profileTipoMensajeAdmin: descodeToken.profile_tipoMensajeAdmin,
            profilePaisesAdmin: descodeToken.profile_paisesAdmin,
            profileRegionesAdmin: descodeToken.profile_regionesAdmin,
            profileCiudadesAdmin: descodeToken.profile_ciudadesAdmin,
            profileEstadoPropuestaAdmin: descodeToken.profile_estadoPropuestaAdmin,
            profileTipoCostoAdmin: descodeToken.profile_tipoCostoAdmin,
            profileUnidadMedidaAdmin: descodeToken.profile_unidadMedidaAdmin,
            profileTipoUnidadMedidaAdmin: descodeToken.profile_tipoUnidadMedidaAdmin,
            profileSizeEquiposAdmin: descodeToken.profile_sizeEquiposAdmin,
            profileCategoriaMensajeAdmin: descodeToken.profile_categoriaMensajeAdmin,
            profileAvisoMantencionAdmin: descodeToken.profile_avisoMantencionAdmin,
          };

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
        else if(refreshToken && isValidToken(refreshToken))
        {

          axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/auth/refreshToken`, {
            "refresh_token": refreshToken,
          },{
            auth:{
              Username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
              Password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
            },
          }).then((rs) => {
            const accessToken = rs.data.access_token;
            const descodeToken = jwtDecode(accessToken);

            setSession(accessToken, rs.data.refresh_token);

            const user = {
              id: descodeToken.user_id,
              displayName: descodeToken.user_name,
              displayLastName: descodeToken.user_lastName,
              userRut: descodeToken.rut_user,
              profileId: descodeToken.profile_id,
              profileDimensional: descodeToken.profile_dimensional,
              profileOperational: descodeToken.profile_operational,
              profileReport: descodeToken.profile_report,
              profileMessage: descodeToken.profile_message,
              profileTecnica: descodeToken.profile_tecnica,
              profileEconomic: descodeToken.profile_economic,
              profileEstadisticaEquipo: descodeToken.profile_estadisticaEquipo,
              userGraphicsConfiguration: descodeToken.userGraphicsConfiguration,
              profileIsPromather: descodeToken.profile_isPromather,
              idTypeProfile: descodeToken.typeProfile._id,
              nameTypeProfile: descodeToken.typeProfile._nombre,
              acccessToken: accessToken,
              refreshToken: refreshToken,

              profilePerfilesAdmin: descodeToken.profile_perfilesAdmin,
              profileUsuariosAdmin: descodeToken.profile_usuariosAdmin,
              profileCorporativosAdmin: descodeToken.profile_corporativosAdmin,
              profilePlantasAdmin: descodeToken.profile_plantasAdmin,
              profileLineAdmin: descodeToken.profile_lineaAdmin,
              profileAreaAdmin: descodeToken.profile_areaAdmin,
              profileAtributosAdmin: descodeToken.profile_atributosAdmin,
              profileEquiposAdmin: descodeToken.profile_equiposAdmin,
              profileTipoEquipoAdmin: descodeToken.profile_tipoEquipoAdmin,
              profileIndustriasAdmin: descodeToken.profile_industriasAdmin,
              profileClasificacionAdmin: descodeToken.profile_clasificacionAdmin,
              profileMarcasAdmin: descodeToken.profile_marcasAdmin,
              profileModelosAdmin: descodeToken.profile_modelosAdmin,
              profileCargosAdmin: descodeToken.profile_cargosAdmin,
              profileTipoAreaAdmin: descodeToken.profile_tipoAreaAdmin,
              profileTipoLineaAdmin: descodeToken.profile_tipoLineaAdmin,
              profileTipoMensajeAdmin: descodeToken.profile_tipoMensajeAdmin,
              profilePaisesAdmin: descodeToken.profile_paisesAdmin,
              profileRegionesAdmin: descodeToken.profile_regionesAdmin,
              profileCiudadesAdmin: descodeToken.profile_ciudadesAdmin,
              profileEstadoPropuestaAdmin: descodeToken.profile_estadoPropuestaAdmin,
              profileTipoCostoAdmin: descodeToken.profile_tipoCostoAdmin,
              profileUnidadMedidaAdmin: descodeToken.profile_unidadMedidaAdmin,
              profileTipoUnidadMedidaAdmin: descodeToken.profile_tipoUnidadMedidaAdmin,
              profileSizeEquiposAdmin: descodeToken.profile_sizeEquiposAdmin,
              profileCategoriaMensajeAdmin: descodeToken.profile_categoriaMensajeAdmin,
              profileAvisoMantencionAdmin: descodeToken.profile_avisoMantencionAdmin,
            };

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user,
              },
            });

          }).catch((error) => {
            console.error(error);
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
              }
          )
        }
        else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (user) => {
    dispatch({
      type: "LOGIN",
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  const prelogin =  async (userEmail,userPassword) =>
  {
    const response = await axios.post(`${process.env.REACT_APP_AUTH_SERVICE}/auth/getToken`, {
      "userEmail": userEmail,
      "userPassword": userPassword,
    },
        {
          auth:{
            username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
            password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
          },
        });
        
    const { access_token, refresh_token, user_id, user_name, user_lastName } = response.data;
    const userJwtDecode = jwtDecode(access_token);


    const user = {
      id: user_id,
      displayName: user_name,
      displayLastName: user_lastName,
      userRut: userEmail,
      userFirstLogin: userJwtDecode.user_firstLogin,
      location: null,
      userPreferredLocation: userJwtDecode.userPreferredLocation,
      profileId: userJwtDecode.profile_id,
      profileDimensional: userJwtDecode.profile_dimensional,
      profileOperational: userJwtDecode.profile_operational,
      profileReport: userJwtDecode.profile_report,
      profileMessage: userJwtDecode.profile_message,
      profileTecnica: userJwtDecode.profile_tecnica,
      profileEconomic: userJwtDecode.profile_economic,
      profileEstadisticaEquipo: userJwtDecode.profile_estadisticaEquipo,
      userGraphicsConfiguration: userJwtDecode.userGraphicsConfiguration,
      profileIsPromather: userJwtDecode.profile_isPromather,
      idTypeProfile: userJwtDecode.typeProfile._id,
      nameTypeProfile: userJwtDecode.typeProfile._nombre,
      acccessToken: access_token,
      refreshToken: refresh_token,

      profilePerfilesAdmin: userJwtDecode.profile_perfilesAdmin,
      profileUsuariosAdmin: userJwtDecode.profile_usuariosAdmin,
      profileCorporativosAdmin: userJwtDecode.profile_corporativosAdmin,
      profilePlantasAdmin: userJwtDecode.profile_plantasAdmin,
      profileLineAdmin: userJwtDecode.profile_lineaAdmin,
      profileAreaAdmin: userJwtDecode.profile_areaAdmin,
      profileAtributosAdmin: userJwtDecode.profile_atributosAdmin,
      profileEquiposAdmin: userJwtDecode.profile_equiposAdmin,
      profileTipoEquipoAdmin: userJwtDecode.profile_tipoEquipoAdmin,
      profileIndustriasAdmin: userJwtDecode.profile_industriasAdmin,
      profileClasificacionAdmin: userJwtDecode.profile_clasificacionAdmin,
      profileMarcasAdmin: userJwtDecode.profile_marcasAdmin,
      profileModelosAdmin: userJwtDecode.profile_modelosAdmin,
      profileCargosAdmin: userJwtDecode.profile_cargosAdmin,
      profileTipoAreaAdmin: userJwtDecode.profile_tipoAreaAdmin,
      profileTipoLineaAdmin: userJwtDecode.profile_tipoLineaAdmin,
      profileTipoMensajeAdmin: userJwtDecode.profile_tipoMensajeAdmin,
      profilePaisesAdmin: userJwtDecode.profile_paisesAdmin,
      profileRegionesAdmin: userJwtDecode.profile_regionesAdmin,
      profileCiudadesAdmin: userJwtDecode.profile_ciudadesAdmin,
      profileEstadoPropuestaAdmin: userJwtDecode.profile_estadoPropuestaAdmin,
      profileTipoCostoAdmin: userJwtDecode.profile_tipoCostoAdmin,
      profileUnidadMedidaAdmin: userJwtDecode.profile_unidadMedidaAdmin,
      profileTipoUnidadMedidaAdmin: userJwtDecode.profile_tipoUnidadMedidaAdmin,
      profileSizeEquiposAdmin: userJwtDecode.profile_sizeEquiposAdmin,
      profileCategoriaMensajeAdmin: userJwtDecode.profile_categoriaMensajeAdmin,
      profileAvisoMantencionAdmin: userJwtDecode.profile_avisoMantencionAdmin,
    };

    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    setSession(user.acccessToken,user.refreshToken);

    dispatch({
      type: "PRELOGIN",
      payload: {
        isAuthenticated: true,
        user, 
      },
    });
  }

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    sessionStorage.clear();
    localStorage.removeItem('showAvisoMantencion');
    setSession(null, null);
    dispatch({ type: "LOGOUT" });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        prelogin,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

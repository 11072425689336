import React, { useState, useEffect } from 'react'
import axios from 'utils/axios';
import moment from 'moment'
import { Card, CloseButton, Modal, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { columnsBitacoraEntity } from '../data'
import { capitalizarString } from 'helpers';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const BitacoraEntidad = ({ title, idEntidad,  showBitacoraEntidad, setShowBitacoraEntidad }) => {

    const [ bitacoraToTable, setBitacoraToTable ] = useState([]);
    const [ bitacoraEntity, setBitacoraEntity ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);
  
    useEffect(() => {
      if (showBitacoraEntidad) {
          setReadyContent(false);
          getBitacoraByIdEntity(idEntidad);
      }
    }, [showBitacoraEntidad]);
  
    useEffect( () => {
      if( bitacoraEntity.length < 1 ) return;
      const bitacoraAdapted = bitacoraEntity.map( bitacora => {
          const fechaHora = moment(bitacora.fecha_transaccion).format('DD-MM-YYYY, HH:mm:ss a')
          bitacora.fecha_transaccion = fechaHora.slice(0, 10) 
          bitacora.hora_transaccion = fechaHora.slice(12, 20)
          bitacora.nombreUser = `${bitacora.user.name} ${bitacora.user.lastName}`
          bitacora.nombreEntidad = capitalizarString(bitacora.typeEntity.nombre)
          return bitacora
      })
      setBitacoraToTable(bitacoraAdapted)
    }, [bitacoraEntity] )
  
      //! OBTENER BITACORA BY ID DE ENTIDAD
      const getBitacoraByIdEntity = async ( idEntidad ) => {
          const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getBitacoraByIdEntity/${idEntidad}`
          try {
            const { data } = await axios.get(url);
            if(verifyResponseUnauthorizedAwait(data, 1)) return;
            if(data.statusCode === 200 || data.statusCode === 204) {
              setBitacoraEntity(data.data);
              setReadyContent(true);
            }
          } catch (error) {
            console.error("Error al intentar obtener la bitacora de la entidad");
          }
        }

  return (
    <Modal
        size='xl'
        show={showBitacoraEntidad}
        centered
        onHide={() => {
            setShowBitacoraEntidad(false)
        }}
        >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { title }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowBitacoraEntidad(false)
                }}
            />
        </Modal.Header>

        <Modal.Body className="p-3">
            { !readyContent ? 
                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    <AdvanceTableWrapper
                        columns={columnsBitacoraEntity}
                        data={bitacoraToTable}
                        sortable
                        pagination
                        perPage={5}
                    >
                    
                    <AdvanceTable
                        title='Bítacora'
                        subtitle='Tabla de Bítacora'
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                    <div className="mt-3">
                        <AdvanceTableFooter
                        rowCount={bitacoraEntity.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                        />
                    </div>
                    </AdvanceTableWrapper>
                </Card>
            )}
        </Modal.Body>
    </Modal>
  )
}

export default BitacoraEntidad
import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Form, Spinner} from 'react-bootstrap';
import * as echarts from 'echarts/core';

import moment from 'moment';
import 'moment/locale/es';

import axios from 'utils/axios';
import { showAlertaTokenExpired, toastNotification } from 'helpers/utils';
import { BasicEChartsAdmin } from 'components/common/BasicEChart';
import ChartOptions from 'components/common/DoughnutRoundedChart';
import { configSelectTop, years, months } from '../data/index';
import { ModalDetalle } from './components/ModalDetalle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


const EstadisticasAdmin = () => {

  const [ topThree, setTopThree ] = useState([]);
  const [ dataPerMonth, setDataPerMonth ] = useState([]);
  const [ dataUsersAccess, setDataUsersAccess ] = useState([]);
  
  const [ plantasOriginal, setPlantasOriginal ] = useState([])
  const [ plantas, setPlantas ] = useState([])
  const [ configGraph, setConfigGraph ] = useState({ planta: { id: 0, nombre: '' }, month: new Date().getMonth() + 1, year: new Date().getFullYear() });
  
  const [ modalDetalleAcceso, setModalDetalleAcceso ] = useState(false);

  const [ daySelected, setDaySelected ] = useState('');
  const [ timeSelected, setTimeSelected ] = useState('');

  const [ readyAccessPlanta, setReadyAccesosPlanta ] = useState(false);
  const [ readyTopThree, setReadyTopThree ] = useState(false);
  
  useEffect( () => getAllPlantas(), []);
  useEffect( () => getTopThree(), [timeSelected]);

  useEffect( () => {
    if( plantas.length < 1 ) return;
    handleChangeConfigGraph({ planta: plantas[0].plantas[0].id });
  }, [plantas]);
  
  useEffect( () => {
    if( configGraph.planta.id < 1 ) return;
    getDataGraphicsPlanta();
  }, [configGraph] );

  const handleChangeConfigGraph = ({planta, month, year}) => {

    
    setConfigGraph({
      planta: {
        id: planta !== undefined ? Number(planta) : configGraph.planta.id,
        nombre: planta !== undefined
                  ? plantasOriginal.find( plant => plant.id === Number(planta) )?.nombre
                  : configGraph.planta.nombre
      },
      month: month !== undefined ? month : configGraph.month,
      year: year !== undefined ? year : configGraph.year,
    })
  }

  //* OBTIENE LOS DATOS DE ACCESO DE UNA PLANTA
  const getDataGraphicsPlanta = async () => {
    setReadyAccesosPlanta(false);
    try {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getConectionsByFloorByMonth/${configGraph.planta.id}`;
      const { data } = await axios.post(url, { mes: Number(configGraph.month), anio: Number(configGraph.year) });
      setDataPerMonth(data.data);
    } catch (error) {
      console.error(error);
      console.error('Hubo un error al intentar consultar las conexiones del cliente');
    }
    setReadyAccesosPlanta(true);
  };

  //* OBTIENE EL TOP 3 DE CORPORATIVOS CONECTADOS LA ULTIMA SEMANA
  const getTopThree = async () => {
    setReadyTopThree(false);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getTopConectionsOfCorporations`;
    try {
      const { data } = await axios.post(url, { cantidadTiempo: timeSelected });
      if( data.statusCode === 200 ){
        setTopThree(
          data.data.map( 
            dato => {
              return { name: dato.corporativo_nombre, value: Number(dato.cantidad_accesos) };
            }
          )
        );
      }
    } catch (error) {
      console.error('Hubo un error al consultar por el top 3');
    }
    setReadyTopThree(true);
  }

  //* OBTIENE TODAS LAS PLANTAS HABILITADAS
  const getAllPlantas = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getAllFloors`;
    try {
      const { data } = await axios.get(url);
      if( !data || data === undefined || data.statusCode === 401 ) {
        showAlertaTokenExpired()
        return;
      }

      if( data.statusCode !== 200 ) {
        toastNotification('warning', 'No se han encontrado plantas cargadas en el sistema');
        return;
      }

      agruparPlantas(data.data);

    } catch (error) {
      console.error('Hubo un error al consultar por las plantas');
    }
  }

  //* OBTIENE EL DETALLE DE LOS ACCESOS DE USUARIOS EN UN DIA EN ESPECIFICO POR PLANTA
  const getDetailsOfAccess = async ( day = '' ) => {
    setDaySelected(day);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getConectionsByDateByIdFloor/${configGraph.planta.id}`;
    try {
      const { data } = await axios.post(url, { fecha: moment(day, 'DD MMMM YYYY', 'es').toDate() });
      setDataUsersAccess(data.data);
      setModalDetalleAcceso(true);
    } catch (error) {
      console.error('Hubo un error al consultar por el top 3');
      toastNotification('warning', 'Ocurrió un error al intentar obtener los detalles de acceso');
    }
  }

  //* AGRUPA LAS PLANTAS POR SU CORPORATIVO
  const agruparPlantas = useCallback(( plantas = [] ) => {

    // Validar que venga un arreglo o que el status sea 200
    let plantasAgrupadas = plantas.reduce((acc, planta) => {
      // Encontrar el grupo correspondiente
      let corporativo = acc.find(g => g.corporativo === planta.corporativo);
      
      // Si no existe el grupo, crearlo
      if (!corporativo) {
        corporativo = { corporativo: planta.corporativo, plantas: [] };
        acc.push(corporativo);
      }
      
      // Agregar la planta al grupo correspondiente
      corporativo.plantas.push({ id: planta.id, nombre: planta.nombre });
  
      return acc;
    }, []);

    plantasAgrupadas = plantasAgrupadas.sort((a,b) => {
      if( a.corporativo.toLowerCase() < b.corporativo.toLowerCase() ) return -1;
      if( a.corporativo.toLowerCase() > b.corporativo.toLowerCase() ) return 1;
      return 0;
    });
    
    setPlantasOriginal(plantas);
    setPlantas(plantasAgrupadas);
  }, []);

  //* SELECT DE PLANTAS CON AGRUPADOR
  const selectPlantas = () => {
    return (
      <div className='d-flex align-items-center gap-1'>
        <Form.Label className='mb-0 me-2'>Planta:</Form.Label>
        <Form.Select 
          size="sm"
          value={configGraph.plantaSelected}
          onChange={(e) => handleChangeConfigGraph({ planta: e.target.value })}
        >
          <option disabled={true}>Seleccione Planta</option>
          {plantas.map((corporativo, index) => (
            <optgroup key={index} label={corporativo.corporativo} style={{ fontWeight: 'bold', fontSize: '11', }}>
              {corporativo.plantas.map(planta => (
                <option key={planta.id} value={planta.id}>{planta.nombre}</option>
              ))}
            </optgroup>
          ))}
        </Form.Select>
      </div>
    )
  }

  //* SELECT DE MES Y ANIO
  const selectMonthYear = () => {
    return (
      <>
        <Col>
          <div className="d-flex align-items-center gap-1">
            <Form.Label className='mb-0 me-2' style={{ minWidth: '50px' }}>Mes:</Form.Label>
            <Form.Select 
              size="sm"
              value={configGraph.month}
              onChange={(e) => handleChangeConfigGraph({ month: e.target.value })}
            >
              <option disabled={true} value="">Seleccione Mes</option>
              {months.map((month) => (
                <option key={month.id} value={month.id}>{month.name}</option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center">
            <Form.Label className='mb-0 me-2'>Año:</Form.Label>
            <Form.Select 
              size="sm"
              value={configGraph.year}
              onChange={(e) => handleChangeConfigGraph({ year: e.target.value })}
            >
              <option disabled={true} value="">Seleccione Año</option>
              {years.map((year) => (
                <option key={year.id} value={year.id}>{year.name}</option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </>
    )
  };
  

  //* SELECT DE TOP 3 CORPORATIVOS
  const selectTopThree = () => {
    return (
      <Form.Select 
        size="sm"
        value={timeSelected}
        onChange={(e) => setTimeSelected(e.target.value)}
      >
        <option disabled={true} >Seleccione tiempo</option>
        { configSelectTop.map( config => (<option key={config.id} value={config.value}>{config.label}</option>) ) }
      </Form.Select>
    )
  };
  

  return (
    <>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="justify-content-between mb-4">
          <Col> <h3 className="text-primary">Estadísticas Conexiones de Clientes</h3> </Col>
        </Row>

        <Row className="justify-content-between gap-2 gap-xxl-0">
          <Col xl={12} xxl={5} className="d-flex align-items-stretch">
            <Card className='px-2 flex-grow-1'>
              <Card.Header>
                <Row className='justify-content-center'>
                  <Col className='w-50'> <p className='h6'>Top 3 Conexiones de Corporativos</p> </Col>
                  <Col> { selectTopThree() } </Col>
                </Row>
              </Card.Header>
              <Card.Body className='pt-5'>
                { readyTopThree
                  ? (
                    <>
                      { topThree.length > 0 
                        ? ( <ChartOptions key={"Top3"} data={topThree} leyendText='Cantidad de Accesos Totales:' /> )
                        : <p className='text-center'>No se han encontado datos de conexion</p>
                      }
                    </>
                  )
                  : <div className='text-center'> <Spinner className='' animation="border" variant="success" /> </div>
                }
              </Card.Body>
            </Card>
          </Col>

          <Col xl={12} xxl={7} className="d-flex align-items-stretch">
            <Card className='px-2 flex-grow-1'>
              <Card.Header>
                <Row className='justify-content-center mb-3'>
                  <p className='h6'>Accesos a Plantas</p>
                </Row>
                <Row>
                  {selectPlantas()}
                </Row>
                <Row className='justify-content-center mt-2 '> { selectMonthYear() } </Row>
              </Card.Header>
              <Card.Body>
                { ( !readyAccessPlanta ) 
                    ? <div className='text-center'> <Spinner className='' animation="border" variant="success" /> </div> 
                    : <BasicEChartsAdmin
                        echarts={echarts}
                        prefix={'Accesos Totales:'}
                        onClick={getDetailsOfAccess}
                        labelAxisX={'Días del Mes'}
                        labelAxisY={'Cantidad Accesos Totales'}
                        options={{
                          xAxis: { data: dataPerMonth.map( data => moment(data.fecha).format('DD MMMM YYYY')) },
                          series: [{ data: dataPerMonth.map( data => data.accesos ) }]
                        }}
                      />
                }
              </Card.Body>
              <Card.Footer>
                <small className='fw-light text-muted'> <FontAwesomeIcon icon={faInfoCircle} className={`text-muted me-1`} /> Al hacer clic en un punto de interés, podrá ver el detalle de los usuarios que se conectaron durante ese periodo de tiempo.</small>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

      </Card.Body>

      <ModalDetalle 
        key={'modalDetalle'} 
        
        showModal={modalDetalleAcceso} 
        nombrePlanta={configGraph.planta.nombre}
        setShowModal={setModalDetalleAcceso} 
        dataToShow={dataUsersAccess}
        daySelected={daySelected}
      />
    </>
  )
}

export default EstadisticasAdmin;
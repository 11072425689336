import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification } from "helpers/utils";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import useLoadingContext from "hooks/useLoadingContext";

export const EditarItem = ({data, fechaInicio, change, setShowEditCostoModal }) => {
    registerLocale('es', es)

    const { isLoading, setIsLoading } = useLoadingContext();

    const [validated, setValidated] = useState(false);
    const[ itemList, setItemList] = useState([]);

    // DATOS DEL FORMULARIO
    const [descripcion, setDescripcion] = useState(data.descripcion);
    const [valor, setValor] = useState(data.valor);
    const [idTipoCosto, setIdTipoCosto] = useState(data.idTipoCosto);

    const [ fecha, setFecha] = useState();
    const [fechaCosto , setFechaCosto] = useState(new Date(data.fechaCosto));

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/tipoCosto/getAllTypeCost`).then((response) => {
            const list = response.data.data;
            setItemList(list);
        });
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {

          const formCostoReal = {
            descripcion: descripcion,
            valor,
            idTipoCosto: Number(idTipoCosto),
            fechaCosto: fecha
          }

          setIsLoading(true);
          await axios.put(`${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/${data.id}`,formCostoReal)
            .then(() => {
              setIsLoading(false)
              change(true);
              setShowEditCostoModal(false);
            })
            .catch((err) => {
              setIsLoading(false)
              console.error(err)
              toastNotification('error', 'Costo Real no se pudo editar.', 2000)
            })
            setValidated(true);
        }

    };
    
    const handleChangeDate = (e) => {
      const fechaFormat = moment(e).format();
      setFecha(fechaFormat);
    };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
            <Form.Label>Ítem</Form.Label>
            <Form.Select 
                required
                name="idTipoCosto" 
                value={idTipoCosto}
                // defaultChecked={data.idTipoCosto}
                onChange={(e) => setIdTipoCosto(e.target.value)}>
                <option value=''>Seleccione ítem</option>
                {itemList.map((item) => 
                    <option 
                    name="tipoCosto"
                    selected={data.idTipoCosto} 
                    key={item.id} 
                    value={item.id}
                    disabled={(item.estado === false) ? true : false}>
                            {item.nombre}
                    </option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Por favor seleccione un ítem.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            name="valor"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            type="number"
            min={1}
            pattern="^[0-9]+"
            required            
          />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Costo, número entero y positivo sobre 0 (ej: 1000)
            </Form.Control.Feedback> 
        </Form.Group>

        <Form.Group className="mb-4 me-4">
        <Form.Label>Fecha:</Form.Label>
          <DatePicker
            locale='es'
            selected={fechaCosto}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(fechaInicio)}
            maxDate={new Date()}
            onChange={(date)=>{
              setFechaCosto(date);
              handleChangeDate(date)
            }}
            // value={date}
            formatWeekDay={day => day.slice(0, 3)}
            className='form-control'
            placeholderText="Seleccione Fecha"
          />
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditCostoModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2" disabled={isLoading}>
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

import { useEffect } from "react";
import { useState } from "react"
import { Button, Card, CloseButton, Form, Modal } from "react-bootstrap"

export const ModalCerrarSolicitud = ({ show = false, setShow, handleSubmit }) => {

    const [ validated, setValidated ] = useState(false);
    const [ descripcion, setDescripcion ] = useState('');
    const [ botonDisabled, setBotonDisabled ] = useState(false);

    useEffect( () => {
        if( !show ){
            setDescripcion('')
            setBotonDisabled(false)
            setValidated(false)
        }
    }, [show])

    const validateForm = ( e ) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return
        }

        handleSubmit(descripcion);
        setBotonDisabled(true);
    }

  return (
    <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header>
            
            <Modal.Title id="contained-modal-title-vcenter">
                Cerrar solicitud
            </Modal.Title>

            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShow(false)}
            />
        </Modal.Header>

        <Modal.Body>
                
            <Form noValidate validated={validated} onSubmit={validateForm}>


                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Descripción de la resolución:</Form.Label>
                    <Form.Control 
                        autoComplete="off"
                        type="text"
                        name="resolucion"
                        required
                        pattern=".{20,}"
                        value={descripcion}
                        onChange={(e) => setDescripcion(e.target.value) }
                    />
                    <Form.Control.Feedback type="invalid">
                        La resolucion no puede estar vacia y debe contener como minimo 20 caracteres.
                    </Form.Control.Feedback>              
                </Form.Group>

                <Form.Group aria-disabled={true}>
                    <Button
                        variant="secondary"
                        className="m-2"
                        disabled={botonDisabled}
                        onClick={() => setShow(false)}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        type="submit"
                        variant="success" 
                        className="m-2" 
                        disabled={botonDisabled}
                    >
                        Enviar
                    </Button>
                </Form.Group>

            </Form>
        </Modal.Body>
    </Modal>
  )
}

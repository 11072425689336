import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button} from 'react-bootstrap';

const ModalTamañoEquipo = ({ showModalAddEdit, setShowModalAddEdit, idToUpdate, setIdToUpdate, tamañoToUpdate, setTamañoToUpdate, createNewSize, updateSize }) => {

  const [ descripcion, setDescripcion ] = useState( ( idToUpdate > 0 ) ? tamañoToUpdate.descripcion : '' )
  const [ validated, setValidated ] = useState(false);

  useEffect( () => {
    ( idToUpdate > 0 ) ? setDescripcion(tamañoToUpdate.descripcion) : setDescripcion('');
  }, [showModalAddEdit])

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( idToUpdate > 0 ){
        const tamaño = { id: idToUpdate, descripcion };
        updateSize(tamaño);
    }else{
        createNewSize({descripcion});
    }
  }

  return (
    <>
        <Modal
            show={showModalAddEdit}
            centered
            onHide={() => {
                setShowModalAddEdit(false)
                setIdToUpdate(0)
                setTamañoToUpdate({})
            }}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { ( idToUpdate > 0 ) ? 'Editar Tamaño de Equipo' : "Agregar Tamaño de Equipo" }
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowModalAddEdit(false)
                        setIdToUpdate(0)
                        setTamañoToUpdate({})
                    }}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                    
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Descripcion:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    name="descripcion"
                                    required
                                    value={descripcion}
                                    onChange={(e) => {
                                        setDescripcion(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresar una descripción valida.
                                </Form.Control.Feedback>              
                            </Form.Group>
                            

                            <Form.Group aria-disabled={true}>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={() => {
                                        setShowModalAddEdit(false)
                                        setIdToUpdate(0)
                                        setTamañoToUpdate({})
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>

                        </Form>

                    </Card.Body>

                </Card>
            </Modal.Body>
        </Modal>
    </>
  )
}

export default ModalTamañoEquipo
import {profiles} from "../data/profiles";
import {Navigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
// import FirstLogin from '../components/authentication/FirstLogin'


const RedirectByRole = () => {
    const {user} = useAuth();
    
    switch (user.idTypeProfile) {
        case profiles.vendedor:
            return <Navigate to={"/vendedor/"} />;
        case profiles.administrador:
            return <Navigate to={"/administrador/"} />;
        case profiles.cliente:
            return <Navigate to={"/cliente/"} />;
        default: 
            return <Navigate to={"/administrador/"} />;

    };
}

export default RedirectByRole;
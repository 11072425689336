import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';

export const TableActionIcon = ({ title = '', width = '', icon = '', onClick }) => {
    return (
        <IconButton
            key={title}
            title={title}
            variant="white"
            size="sm"
            icon={icon}
            style={{ boxShadow: "none", width: width }}
            onClick={onClick}
        />
    )
}

TableActionIcon.propTypes = {
    title: PropTypes.string.isRequired,
    width: PropTypes.string,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

TableActionIcon.defaultProps = {
    width: 'auto'
};

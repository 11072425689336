import React, { useState, useEffect, useDeferredValue } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ActiveUsersChart from './GraficoPropuestasChart';
import ActiveUsersChart from '../../components/GraficoPropuestasChart';
import classNames from 'classnames';
// import { activeUsersChart } from './data';
import { formatterPesoChile, getDaysBetweenDates, graficar } from 'helpers/utils';
import moment from 'moment';
import { findAllInRenderedTree } from 'react-dom/test-utils';

const GraficoPropuestas = ({ propuesta, costosPropuesta, situacionAnterior, costosSituacionAnterior, periodoPropuestaSelect, handleTotal, ...rest }) => {
  
  const [ periodo, setPeriodo ] = useState();
  const [ objToGraf, setObjToGraf ] = useState({
    promather: [],
    anterior: []
  });


  const [stats, setStats] = useState([
    { label: 'S/Ant', color: 'danger', value: 0 },
    { label: `Prop`, color: 'primary', value: 0 }
  ]);

  useEffect(() => {
    if( costosPropuesta.length < 1 || propuesta == undefined ) return
    setearLineas();
  }, [costosPropuesta])

  const setearLineas = () => {

    // let lineCostos = objToGraf;
    let lineCostos = {};
    const fechaInicio = moment().format();
    const fechaTermino = moment(fechaInicio).add(propuesta.periodo, 'days').format()

    if(costosSituacionAnterior.length > 0){
      lineCostos.anterior = graficar(fechaInicio, fechaTermino, costosSituacionAnterior)
    }else{
      lineCostos.anterior = graficar(fechaInicio, fechaTermino, [])
    }
    
    if( costosPropuesta.length > 0 ){
      lineCostos.promather = graficar(fechaInicio, fechaTermino, costosPropuesta, setPeriodo)
    }else{
      lineCostos.promather = graficar(fechaInicio, fechaTermino, [], setPeriodo)
    }

    setObjToGraf(lineCostos)
  }


  useEffect(() => {
    if( objToGraf.promather.length < 1 || objToGraf.anterior.length < 1 ) return
    const stat = [
      {label: 'S/Ant', color: 'danger', value: objToGraf.anterior[objToGraf.anterior.length - 1] },
      {label: 'Prop', color: 'primary', value: objToGraf.promather[objToGraf.promather.length - 1] }
    ]
    setStats(stat);    
  }, [objToGraf.promather])
  
  useEffect(() => {
    if( stats[0].value < 1 || stats[1].value < 1 ) return
    handleTotal(stats[0].value, stats[1].value)
  }, [stats])

  
  return (
    <Card {...rest}>
      <FalconCardHeader
        title={ !propuesta.id ? ('Propuesta vs Situación Anterior') : ( `${propuesta.nombre} vs Situación Anterior` ) }
        titleTag="h6"
        className="py-2"
        light
      />
      <Card.Body>
        { !propuesta.id ? (
          <h6>Ninguna propuesta seleccionada</h6>
        ) : (
          <Row>
            <Col md="auto" className="mt-md-0">
              <Row className="flex-md-column justify-content-between h-md-100 ms-0">
                {stats.map((stat, index) => (
                  <Col
                    key={stat.label}
                    className={classNames('pt-3', {
                      'border-end border-md-end-0 border-md-bottom': index !== 2,
                      'pt-md-4': index !== 0
                    })}
                  >
                    <h6 className="fs--2 text-7">
                      <FontAwesomeIcon
                        icon="circle"
                        className={`text-${stat.color} me-2`}
                      />
                      {stat.label}
                    </h6>
                    <h5 className="text-700 fs-0">
                      {formatterPesoChile(stat.value)}
                    </h5>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md="auto" className="echart-active-users-report-container w-75">
              {/* <ActiveUsersChart datos={objToGraf} periodo={periodo} propuesta={propuesta}/> */}
              <ActiveUsersChart key={'keyPrpopuesta'} situacionAnterior={objToGraf.anterior} propuesta={objToGraf.promather} situacionReal={[]} fechaInicio={null} fechaTermino={null} />
            </Col>
          </Row>
        ) }
      </Card.Body>
    </Card>
  );
};

export default GraficoPropuestas;

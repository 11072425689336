import React from 'react'
import { Carousel } from 'react-bootstrap'
import Carousel1 from 'assets/img/chat/99.jpg';
import Carousel2 from 'assets/img/chat/88.jpg';
import Carousel3 from 'assets/img/chat/77.jpg';
import Carousel4 from 'assets/img/chat/66.jpg';

export const CarouselPage = () => {
  return (
    <>
      <Carousel className='light'>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel4}
            alt="First slide"
          />
          <div className="p-4 bg-dark rounded-4 position-absolute top-50 start-50 translate-middle" style={{width:'70vw'}}>
            <h2 className="text-white text-center">Información Dimensional y Operacional de sus Equipos Rotativos</h2>
            <hr></hr>
            <h4 className="text-white text-center">
              Disponga de toda la información dimensional y operacional de sus equipos rotativos, de manera rápida y expedita, en tiempo real y permanente.
            </h4>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel3}
            alt="Second slide"
          />
          <div className="p-4 bg-dark rounded-4 position-absolute top-50 start-50 translate-middle" style={{width:'70vw'}}>
            <h2 className="text-white text-center">
              Biblioteca
            </h2>
            <hr></hr>
            <h4 className="text-white text-center">
              Comparta con su equipo de trabajo toda la información y literatura técnica disponible de sus equipos y manténgala actualizada.
            </h4>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel2}
            alt="Third slide"
          />
          <div className="p-4 bg-dark rounded-4 position-absolute top-50 start-50 translate-middle" style={{width:'70vw'}}>
            <h2 className="text-white text-center">
              Análisis Económico
            </h2>
            <hr></hr>
            <h4 className="text-white text-center">
              Lleve el control de costos de mantención de sus equipos y el rol, de manera actualizada en tiempo real.
            </h4>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Carousel1}
            alt="Third slide"
          />
          <div className="p-4 bg-dark rounded-4 position-absolute top-50 start-50 translate-middle" style={{width:'70vw'}}>
            <h2 className="text-white text-center">
              Canal de Comunicación Técnica Expedito y Permanente
            </h2>
            <hr></hr>
            <h4 className="text-white text-center">
              Solicite asistencia técnica inmediata y obtenga todos los reportes de sus equipos, a través de un canal seguro, rápido y oportuno.
            </h4>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

import classNames from "classnames";
import React, { useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Section from 'components/common/Section';
import { Container, Navbar, Form, Button, Card } from 'react-bootstrap'
import AppContext from "context/Context";
import { topNavbarBreakpoint } from "config";
import handleNavbarTransparency from "helpers/handleNavbarTransparency";
import FooterStandard from "components/pages/landing/FooterStandard";
import { useParams } from 'react-router-dom';
import axios from 'utils/axios';
import { toastNotification, validatePass } from "helpers/utils";
import { ToastContainer } from "react-toastify";
import { Navigate } from 'react-router-dom';

const RecuperarPassword = () => {
    const {
        config: { isDark },
    } = useContext(AppContext);
    const [navbarCollapsed, setNavbarCollapsed] = useState(true);

    const { token } = useParams();

    const [ validated, setValidated ] = useState(false);
    const [ newPassword, setNewPassword ] = useState('');
    const [ repeatNewPassword, setRepeatNewPassword ] = useState('');

    const [ isValid, setIsValid ] = useState(true);

    useEffect(() => {
        validarToken();
        window.addEventListener("scroll", handleNavbarTransparency);
        return () => window.removeEventListener("scroll", handleNavbarTransparency);
    }, []);

    const validarToken = async () => {
        const url = `${process.env.REACT_APP_EMAILS_SERVICE}/sendEmails/consultarToken/${token}`;
        try {
            const { data } = await axios.get(url);
            if( data.statusCode !== 200 ){
                setIsValid(false)
                toastNotification('error', 'Token no valido', 2000);
            } else{
                setIsValid(true)
            }
        } catch (error) {
            toastNotification('error', 'Se ha producido un error al intentar validar el token');
        }
    }

    const asignarPassword = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return
        }

        if( newPassword !== repeatNewPassword ) { 
            toastNotification('error', 'Las contraseñas no coinciden')
            return
        }

        const passValid = validatePass(newPassword);
        if( !passValid ) return

        const url = `${process.env.REACT_APP_EMAILS_SERVICE}/sendEmails/assignNewPassword`;
        try {
            const { data } = await axios.post(url, { contraseña: newPassword, token: token })
            if( data.statusCode === 200 ){
                toastNotification('success', 'Ya puede iniciar sesion con su nueva contraseña', 2000)
                setTimeout(() => {
                    setIsValid(false);
                }, 2000)
            }else{
                toastNotification('warning', 'Ha ocurrido un error al intentar asignar su contraseña', 2000)
            }
        } catch (error) {
            toastNotification('error', 'Se ha producido un error al intentar enviar las instrucciones');
        }


    }

    return (
        <main style={{    
            backgroundColor: ' #0b1727',
            // display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh'}}>
            <Navbar
            variant={isDark ? "light" : "dark"}
            fixed="top"
            expand={topNavbarBreakpoint}
            className={classNames("navbar-standard navbar-theme", {
                "bg-100": !navbarCollapsed && isDark,
                "bg-dark": !navbarCollapsed && !isDark,
            })}
            >
            {!isValid && <Navigate to="/" />}

            <ToastContainer/>
            <Container>
                <Navbar.Brand className="text-white dark__text-white" as={Link} to="/" style={{fontSize:'35px'}}>
                Promanet
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
            </Container>
            </Navbar>

            <Section bg="dark" className="pt-6 pb-6 pb-1 light d-flex justify-content-center h-75 align-items-center">
                
                <Card className="w-50 mx-auto" style={{ backgroundColor: '#0b1727' }}>
                    <Card.Body>
                        <Card.Title className='text-center fs-3 mb-5 pt-2 text-white'>
                            Recuperar Contraseña
                        </Card.Title>
                        
                        <Form noValidate validated={validated} onSubmit={asignarPassword}>

                            <Form.Group className="mb-3" controlId="exampleForm.newPassword">
                                <Form.Control 
                                    placeholder='Nueva Contraseña'
                                    autoComplete="off"
                                    type="password"
                                    style={{ color: "#d8e2ef", backgroundColor: "#0b1727" }}
                                    name="newPassword"
                                    required
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor, ingrese la nueva contraseña
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.repeatNewPassword">
                                <Form.Control 
                                    placeholder='Repita la nueva contraseña'
                                    autoComplete="off"
                                    type="password"
                                    style={{ color: "#d8e2ef", backgroundColor: "#0b1727" }}
                                    name="repeatNewPassword"
                                    required
                                    value={repeatNewPassword}
                                    onChange={(e) => {
                                        setRepeatNewPassword(e.target.value)
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor, repita la nueva contraseña
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group aria-disabled={true} className='mt-4 text-end'>
                                <Button type='submit' variant="success" className="m-2" disabled={false} >
                                    Cambiar Contraseña
                                </Button>
                            </Form.Group>

                        </Form>
                    </Card.Body>
                </Card>

            </Section>
            <FooterStandard />
        </main>
    )
}

export default RecuperarPassword
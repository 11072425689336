import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import { toast } from 'react-toastify';

dayjs.extend(duration);

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};


export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const coloresGraphics = [
  '#2471A3', // Azul
  '#229954', // Verde
  '#F1C40F', // Amarillo
  '#D35400', // Naranja
  '#884EA0', // Morado 
  '#C0392B', // Rojo
  '#2E86C1', // Azul Claro
  '#34495E', // Gris Azulado
  '#9C640C', // Marrón
  '#17A589', // Verde Azulado
  '#E59866', // Naranja Claro
  '#4A235A', // Violeta
  '#E6B0AA', // Rosa
  '#717D7E', // Gris
  '#212F3D', // Gris Oscuro
  '#AED6F1', // Azul Claro Pastel
  '#7DCEA0', // Verde Pastel
  '#186A3B', // Verde Oscuro
  '#F1948A', // Rosa Claro
  '#5499C7', // Azul Claro
  '#E74C3C', // Rojo Claro
  '#AF7AC5', // Morado Claro
  '#73C6B6', // Turquesa Claro
  '#BA4A00', // Naranja Oscuro
  '#48C9B0', // Turquesa
  '#CB4335', // Rojo Oscuro
  '#58D68D', // Verde Claro
  '#DC7633', // Naranja
  '#82E0AA', // Verde Claro Pastel
  '#85C1E9', // Azul Claro
  '#1ABC9C', // Verde Claro
  '#E8DAEF', // Lila
  '#641E16', // Marrón Oscuro
  '#D7BDE2', // Lavanda
  '#F8C471', // Naranja Claro
  '#5DADE2', // Azul Claro
  '#F5B041', // Naranja
  '#FAD7A0', // Amarillo Claro
  '#7D3C98', // Morado Oscuro
  '#5D6D7E', // Gris Azulado
  '#F39C12', // Naranja
  '#196F3D', // Verde Oscuro
  '#2E4053', // Gris Azulado Oscuro
  '#6C3483', // Violeta Oscuro
  '#7B7D7D', // Gris
  '#2874A6', // Azul Claro
  '#239B56', // Verde Claro
  '#B7950B', // Amarillo Oscuro
  '#A04000', // Naranja Oscuro
  '#1A5276', // Azul Oscuro
  '#5B2C6F', // Morado Oscuro
  '#6E2C00', // Marrón Oscuro
  '#943126', // Rojo Oscuro
  '#0E6655', // Verde Oscuro
  '#F7DC6F', // Amarillo Claro
];


export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});
//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    childrens.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const getExtension = (cadena) => {
  const ultimoPuntoIndex = cadena.lastIndexOf('.');
    
    if (ultimoPuntoIndex !== -1) {
        const primeraParte = cadena.substring(0, ultimoPuntoIndex);
        const segundaParte = cadena.substring(ultimoPuntoIndex + 1);

        return [primeraParte, segundaParte];
    } else {
        // Si no se encuentra ningún punto, devolver la cadena original y una cadena vacía
        return [cadena, ''];
    }
}

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div className='ms-1'> 
        <h6 className="text-700"><span className="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p className='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

// get file size

export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

/* Get Past Dates */
export const getPastDates = (duration, propuesta) => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }
  let endDate;
  let startDate;




  if( !propuesta ) {

    /* 
      date = fecha hoy
      endDate = fecha hoy + periodo 
    */

    //! ANTES
    const date = new Date(); 
    endDate = date;
    startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  }else{
    //! AHORA
    const sta = new Date();
    const end = new Date(sta);
    end.setMonth(end.getMonth() + Number(propuesta.periodo))
    return getDates(sta, end)
  }

  return getDates(startDate, endDate);
  
};

export const getPastDatesSituacionAnterior = duration => {
  const date = new Date();
  const startDate = date;
  const endDate = moment().add((duration), 'months');
  const final = new Date(endDate);
  return getDates(startDate, final);

};

export const getPastDatesPropuesta = (fechaInicio, fechaTermino) => {
  const startDate = new Date(fechaInicio);
  const endDate = new Date(fechaTermino);
  return getDates(startDate, endDate);
};

// Add id to items in array
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

export const toastNotification = (type, text, timeToClose=3000, unique=false) => {
  if( unique ) toast.dismiss();
  toast(text, {
    type,
    position: "top-right",
    autoClose: timeToClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
}

export const showAlertaTokenExpired = () => {
  toastNotification('warning', 'Hubo un error al cargar los datos, por favor inténtelo nuevamente.');
}

export const getDaysBetweenDates = ( fechaInicio, fechaTermino ) => {
  const fechaInicioFormat = moment(fechaInicio).startOf('day');
  const fechaTerminoFormat = moment(fechaTermino).startOf('day');
  const diferenciaEnDias = fechaTerminoFormat.diff(fechaInicioFormat, 'days');
  return diferenciaEnDias;
}
  
//! NUEVA FUNCION GRAFICAR
// Actualmente esta recibiendo un parametro func. Este parametro recibe una funcion. Yo lo estoy utilizando para setear un valor en el GraficoPropuestas.js. Sin embargo no es necesario que se lo pases
export const graficar = ( fechaInicio, fechaTermino, costos, func=undefined ) => {
  const periodoPropuesta = getDaysBetweenDates(fechaInicio, fechaTermino); // Calculamos el periodo a graficar
  let arrCostos = [];  // Arreglo de arreglos con costos = deberia quedar algoa si "[ [], [], [] ]"

  if( func !== undefined ) func(periodoPropuesta)

  //? Recorremos cada uno de los costos de la propuesta y devolvemos un nuevo arreglo con mas arreglos correspondientes los costos y a los dias en los que se realizan
  let arrayCosto = costos.map( (costo, idx) => {
    const { valor, periodo, diaCosto } = costo;

    // Crea un arreglo con una extension igual a la del periodo y en todas sus posiciones les asiga un 0
    const arrayCosto = new Array(periodoPropuesta)
    arrayCosto.fill(0);

    // Este acomulador sevira para contar la cantidad transcurridos entre los costos y poder insertar costos en su dia correspondiente
    //! ESTE ACOMULADOR SE USA SOLO EN LOS COSTOS PERIODICOS
    let acomuladorOfDays = 0;

    // Hacemos un bucle for que itere una cantidad de veces determinada por el periodo de la propuesta
    for( let i = 0; i <= periodoPropuesta; i++ ){
      //? Si el costo actual es de periodo 0, quiere decir que se debe agregar solo una vez, por ende, lo agregamos en el indice correspondiente y continuamos al siguiente costo
      if( periodo === 0 ){
        if( diaCosto === i ){
          // arrayCosto[i] = valor;
          arrayCosto[i] = valor;
          continue;
        }
      }

      //? Si el periodo es mayor a 0, tenemos que calcular el dia costo y su periodicidad para ir agregando nuevos valores
      if( periodo > 0 ){

        //? Si el i es menor al dia del costo, quiere decir que el costo aun no debe insertarse en el arreglo y continuamos a la siguiente iteracion
        if( i < diaCosto ) continue;

        
        //? Si el iterador es igual o mayor al dia del costo
        if( i >= diaCosto ){

          //? Si el dia en el que se incurre el gasto es hoy, lo agregamos al array y se incrementa en uno el acomulador para llevar el conteo de los dias
          if( i === diaCosto ){
            arrayCosto[i] = valor;
            acomuladorOfDays = acomuladorOfDays + 1;
            continue;
          }

          //? Si el acomulador llega a ser igual al periodo, quiere decir que se debe ingresar nuevamente el costo al arreglo y reiniciamos el acomulador
          if( acomuladorOfDays === periodo ){
            arrayCosto[i] = valor;
            acomuladorOfDays = 1
            continue;
          }

          //? Si ninguna de las condiciones se cunple, es porque aun no estamos en una fecha en la que se deba agregar un costo, por lo tanto, pasamos a la siguiente iteracion y aumentamos el acomulador en 1;
          acomuladorOfDays = acomuladorOfDays + 1;
        }
      }
    }

    return arrayCosto
  })
  let acomulator = 0;

  // Hacemos un for para poder ir asignando los costos de cada dia al last array
  for( let i = 0; i <= (periodoPropuesta - 1); i++ ){
    for( let a = 0; a <= (arrayCosto.length - 1); a++ ){
      acomulator = acomulator + arrayCosto[a][i]
    }
    arrCostos.push(acomulator);
  }
  
  return arrCostos;
}

export const formatterPesoChile = (valor) =>{
  const formatValor = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  });

  const valorFormat = formatValor.format(valor)
  return valorFormat;
} 

export const formatNumber = (num) => {
  if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + 'k';
  } else {
    return num.toString();
  }
}


export function formatRut(rut) {
  var actual = rut.replace(/^0+/, "");
  if (actual !== rut) {
    rut = actual;
  }
  var sinPuntos = rut.replace(/\./g, "");
  var actualLimpio = sinPuntos.replace(/-/g, "");
  var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
  var rutPuntos = "";
  var i = 0;
  var j = 1;
  for (i = inicio.length - 1; i >= 0; i--) {
    var letra = inicio.charAt(i);
    rutPuntos = letra + rutPuntos;
    if (j % 3 === 0 && j <= inicio.length - 1) {
      rutPuntos = "." + rutPuntos;
    }
    j++;
  }
  var dv = actualLimpio.substring(actualLimpio.length - 1);
  rutPuntos = rutPuntos + "-" + dv;
  return rutPuntos;
}

export const validateRut = ( rutCompleto = '' ) => {
  rutCompleto = rutCompleto.replace("‐","-");
  rutCompleto = rutCompleto.replace(/\./g, ''); // Eliminar puntos
  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
    return false;
  var tmp 	= rutCompleto.split('-');
  var digv	= tmp[1]; 
  var rut 	= tmp[0];
  if ( digv == 'K' ) digv = 'k' ;
  
  return (validateDv(rut) == digv );
};

const validateDv = (T) => {
  var M=0,S=1;
  for(;T;T=Math.floor(T/10))
    S=(S+T%10*(9-M++%6))%11;
  return S?S-1:'k';
};

export const validatePass = ( pass ) => {
  // mayusculas, minusculas, numeros, y minimo 5 caracteres en total
  let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  let result = regex.test(pass);
  if( !result ) toastNotification('error', 'La contraseña debe contener números, mayúsculas, minúsculas y al menos 6 caracteres', 4000);
  return result;
}

export const isTokenExpired = () => {
  const token = localStorage.getItem('accessToken');
  if( !token ) return false;
  const decodedToken = jwtDecode(token);
  const expirationDate = new Date(decodedToken.exp * 1000);
  const currentDate = new Date();
  return (expirationDate < currentDate) ? true : false;
};

export const verificarTokenExpirado = () => {
  // Obtener el token de acceso almacenado en el local storage
  const accessToken = localStorage.getItem('accessToken');

  // Verificar si el token está presente
  if (accessToken) {
    // Decodificar el token para obtener la fecha de expiración
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const tokenData = JSON.parse(rawData);
    const expirationDate = new Date(tokenData.exp * 1000); // Multiplicar por 1000 para convertir a milisegundos

    // Obtener la fecha y hora actual
    const currentDate = new Date();

    // Comparar la fecha y hora de expiración con la fecha y hora actual
    if (currentDate.getTime() < expirationDate.getTime()) {
      // El token no ha expirado
      return false;
    } else {
      // El token ha expirado
      return true;
    }
  } else {
    // No se encontró el token en el local storage
    return true;
  }
}

export const verifyResponseUnauthorizedFetch = ( response, accion = 1 ) => {
  if( !response.status || response.status === 401 ) {
    if( accion === 1 ) toastNotification('warning', 'Hubo un error al cargar los datos, por favor inténtelo nuevamente.');;
    if( accion === 2 ) toastNotification('warning', 'Hubo un error al crear, por favor inténtelo nuevamente.');;
    if( accion === 3 ) toastNotification('warning', 'Hubo un error al actualizar los datos, por favor inténtelo nuevamente.');;
    if( accion === 4 ) toastNotification('warning', 'Hubo un error al eliminar el dato, por favor inténtelo nuevamente.');;
    return true;
  }
  return false;
};

export const verifyResponseUnauthorizedAwait = ( data, accion = 1 ) => {
  if( !data || data === undefined || data.statusCode === 401 ) {
    if( accion === 1 ) toastNotification('warning', 'Hubo un error al cargar los datos, por favor inténtelo nuevamente.');;
    if( accion === 2 ) toastNotification('warning', 'Hubo un error al crear, por favor inténtelo nuevamente.');;
    if( accion === 3 ) toastNotification('warning', 'Hubo un error al actualizar los datos, por favor inténtelo nuevamente.');;
    if( accion === 4 ) toastNotification('warning', 'Hubo un error al eliminar el dato, por favor inténtelo nuevamente.');;
    return true;
  }
  return false;
};

export const getSixWords = ( text ) => {
  var words = text.split(" "); // Dividir el string en un array de palabras
  var resultado = words.slice(0, 6).join(" "); // Tomar las primeras 6 palabras y unirlas nuevamente en un string
  resultado = `${resultado}...`
  return resultado;
}

// Convierte bytes(numero) a una string formato MB
export const bytesToMB = (bytes = 0) => {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2) + 'MB';
}
import React, { useState, useEffect } from 'react'
import { columnsEquipos } from '../data'
import { Card, Modal, CloseButton, Spinner, Button } from 'react-bootstrap'
import axios from "utils/axios";

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalDesabilitados = ({title, showDisabled, setShowDisabled, habilitarTypeEquipo }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ equiposDisabledData, setEquiposDisabledData ] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idPerfilTypeEquipo, setIdPerfilTypeEquipo] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showDisabled) {
        setReadyContent(false);
        getAllTypesEquiposDisabled();
    };
  }, [showDisabled]);

  useEffect( () => {
    const msgActions = equiposDisabledData.map( tipo => {
        tipo.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                    setShowConfirmation(true);
                    setIdPerfilTypeEquipo(tipo.id)
                }}
            ></IconButton>
        ]
        return tipo
    } )
    setReadyContent(true);
    setMensajesToTable(msgActions)
  }, [equiposDisabledData]);

    //! OBTENER TIPOS DE EQUIPOS DESHABILITADOS
    const getAllTypesEquiposDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposEquipoDisabled`;
        try {
          const { data } = await axios.get(url);
          if(verifyResponseUnauthorizedAwait(data, 1)) return;
          if (data.statusCode === 200 || data.statusCode === 204) setEquiposDisabledData(data.data);
        } catch (error) {
          console.error('Error al intentar obtener los tipos de equipos deshabilitados');
        }
      }

  return (
    <>
        <Modal
            size='xl'
            show={showDisabled}
            className={ showConfirmation ? 'd-none' : '' }
            centered
            onHide={() => {
                setShowDisabled(false)
            }}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { title }
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowDisabled(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( equiposDisabledData.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                        <AdvanceTableWrapper
                            columns={columnsEquipos}
                            data={mensajesToTable}
                            sortable
                            pagination
                            perPage={5}
                        >
                        
                        <AdvanceTable
                            title='Equipos Deshabilitados'
                            subtitle='Tabla Tipos de Equipos Deshabilitados'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={equiposDisabledData.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) }
                </Card>
                )}
            </Modal.Body>
        </Modal>

        <Modal
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setIdPerfilTypeEquipo(0);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Habilitar Tipo de Equipo</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de querer habilitar el tipo de equipo?</Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowConfirmation(false);
                        setIdPerfilTypeEquipo(0);
                    }}
                >
                    Cancelar
                </Button>
                <Button variant="success" 
                    onClick={() => {
                        setShowConfirmation(false);
                        setShowDisabled(false);
                        habilitarTypeEquipo(idPerfilTypeEquipo);
                    }}
                >
                    Habilitar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default ModalDesabilitados
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsBitacora } from '../data'
import axios from 'utils/axios';
import moment from 'moment';
import { toastNotification, verifyResponseUnauthorizedFetch } from 'helpers/utils';

export const BitacoraEstadoPropuesta = () => {

    const [ bitacoraGeneral, setBitacoraGeneral] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);

    useEffect(() => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/estadoPropuesta/getAllBitacora/`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          list.forEach(element => {
            element.fecha = moment(element.fecha_transaccion).format('DD-MM-YYYY');
            element.hora = moment(element.fecha_transaccion).format('hh:mm:ss');
          })
          setBitacoraGeneral(list);
          setReadyContent(true);
        }
      }).catch(error => {
        setReadyContent(true);
        console.error(error);
        toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
      });
    }, [])

  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
      : (
        <>
                { !bitacoraGeneral.length > 0 ? (
                <h6>No hay historial en la Bitácora</h6>
              ) :
              (
                <div>
                  <AdvanceTableWrapper
                    columns={columnsBitacora}
                    data={bitacoraGeneral}
                    sortable
                    pagination
                    perPage={10}
                  >  
                    <AdvanceTable
                      title='Bítacora de Estados de Propuestas'
                      subtitle='Tabla de Bítacora de Estados de Propuestas'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={bitacoraGeneral.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                </div>
              )
            }
        </>
      )}
    </>
  )
}

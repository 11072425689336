export const columnasTabla = [
    {
        accessor: 'id',
        Header: 'Id'
    },
    {
        accessor: 'fechaInicio',
        Header: 'Fecha de Inicio'
    },
    {
        accessor: 'fechaFin',
        Header: 'Fecha de Fin'
    },
    {
        accessor: 'nombre_estado',
        Header: 'Estado'
    },
    {
        accessor: 'acciones',
        Header: 'Acciones'
    },
];
import { useEffect, useState } from 'react';
import { Button, Card, CloseButton, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from 'utils/axios';
import { toastNotification } from 'helpers/utils'
import DraggableComponent from 'pages/common/components/DraggableComponent'
import useLoadingContext from 'hooks/useLoadingContext';


const modifyMessageTypePriority = async ( prioridadTiposMensajes = [] ) => {
    try {
        const { data } = await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/tiposMensajes/modifyMessageTypePriority`, {priorities: prioridadTiposMensajes});
        if( data.statusCode === 200 ) {
            toastNotification('success', 'Prioridades de tipos de mensajes actualizadas correctamente');
            return data.data;
        } else return [];
    } catch (error) {
        toastNotification('error', 'Ocurrio un error al intentar actualizar las prioridades de tipos de mensajes');
        return [];
    }
}

export const ModalOrderTypeMessage = ({ showModal = false, setShowModal, typeMessages, setTypeMessages }) => {    

    const [ itemsReordered, setItemsReordered ] = useState([]);
    const { isLoading, setIsLoading } = useLoadingContext();

    const saveNewOrder = async () => {
        setIsLoading(true);
        const maxPriority = itemsReordered.length * 10;
        const objToSend = itemsReordered.map((item, index) => {
            return { id: Number(item.id), priority: maxPriority - (index * 10) };
        });
        const newTypeMessages = await modifyMessageTypePriority(objToSend);
        if( newTypeMessages.length > 0 ) {
            setShowModal(false);
            setTypeMessages(newTypeMessages);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if( !showModal ) return;

        const items = typeMessages.map(( typeMessage ) => {
            typeMessage.id = typeMessage.id.toString();
            return typeMessage;
        }).sort((a, b) =>  b.priority - a.priority);

        setItemsReordered(items);
    }, [typeMessages, showModal]);

    const onCloseModal = () => {
        setTimeout(() => setItemsReordered([]), 150);
        setShowModal(false);
    }

    return (
        <Modal
            show={showModal}
            centered
            onHide={onCloseModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Orden de Prioridad</Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={onCloseModal}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        <p>Arrastra y suelta para organizar las prioridades. Los tipos de mensajes mas importantes deben quedar en la parte superior y los menos importantes en la parte inferior.</p>
                        { itemsReordered.length > 0 && (
                            <DraggableComponent 
                                key={'orderTypeMessage'}
                                elements={itemsReordered}
                                onItemsReordered={setItemsReordered}
                            />
                        ) }
                    </Card.Body>
                </Card>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    disabled={isLoading}
                    onClick={onCloseModal}
                >
                    Cancelar
                </Button>
                <Button
                    variant="success"
                    disabled={isLoading}
                    onClick={() => saveNewOrder()}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

ModalOrderTypeMessage.prototype = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    typeMessage: PropTypes.array,
    setTypeMessages: PropTypes.func
};
import useAuth from "hooks/useAuth";
import RedirectByRole from "components/RedirectByRole";

// ----------------------------------------------------------------------


export default function AttributeProfileGuard({identifier, children}) {
    const {user, isAuthenticated} = useAuth();
    const canRedirectProfile = user[identifier];
    if (!isAuthenticated || !canRedirectProfile ) {
        return <RedirectByRole/>
    }

    return <>{children}</>;
}

import PropTypes from 'prop-types';

import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

export const TableWrapper = ({ 
    columnas = [],
    data = [],
    elementosPorPagina = 10,
    titleToExport = '',
    subtitleToExport = '',
    children = <></>
 }) => {
    return (
        <AdvanceTableWrapper
            columns={columnas}
            data={data}
            sortable
            pagination
            perPage={elementosPorPagina}
        >

            { children }

            <AdvanceTable
                title={titleToExport}
                subtitle={subtitleToExport}
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                }}
            />
            <div className="mt-3">
                <AdvanceTableFooter
                    rowCount={data.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                />
            </div>
        </AdvanceTableWrapper>
    )
}

TableWrapper.propTypes = {
    columnas: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    elementosPorPagina: PropTypes.number,
    titleToExport: PropTypes.string,
    subtitleToExport: PropTypes.string,
    children: PropTypes.node
};
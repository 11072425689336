
export const columnsCorporativo = [
    {
      accessor: 'id',
      Header: 'Id'
    },
    {
      accessor: 'nombre',
      Header: 'Nombre'
    },    
    {
      accessor: 'descripcion',
      Header: 'Descripción'
    },    
    {
      accessor: 'rut',
      Header: 'Rut'
    },    
    {
      accessor: 'razonSocial',
      Header: 'Razón Social'
    },
    {
      accessor: 'industria',
      Header: 'Industria'
    },
    {
      accessor: 'clasificacion',
      Header: 'Clasificación'
    },
    {
      accessor: 'ciudad',
      Header: 'Ciudad'
    },
    {
      accessor: 'acciones',
      Header: 'Acciones'
    },
];


export const columnsPlanta = [
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },    
  {
    accessor: 'codigoErp',
    Header: 'Código ERP'
  },    
  {
    accessor: 'corporativo',
    Header: 'Corporativo'
  },
  {
    accessor: 'ciudad',
    Header: 'Ciudad'
  },
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
];

export const columnsLinea = [
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },    
  {
    accessor: 'especialidad',
    Header: 'Especialidad'
  },   
  {
    accessor: 'corporativo',
    Header: 'Corporativo'
  }, 
  {
    accessor: 'planta',
    Header: 'Planta'
  },
  {
    accessor: 'tipoLinea',
    Header: 'Tipo Línea'
  },
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
];

export const columnsArea = [
  {
    accessor: 'id',
    Header: 'Id'
  },
  {
    accessor: 'nombre',
    Header: 'Nombre'
  },    
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  },
  {
    accessor: 'corporativo',
    Header: 'Corporativo'
  },
  {
    accessor: 'planta',
    Header: 'Planta'
  },    
  {
    accessor: 'linea',
    Header: 'Línea'
  },
  {
    accessor: 'tipoArea',
    Header: 'Tipo Área'
  },
  {
    accessor: 'acciones',
    Header: 'Acciones'
  },
];

export const columnsBitacora = [
  {
    accessor: 'id_usuario',
    Header: 'Id Usuario'
  },
  {
    accessor: 'nombre_usuario',
    Header: 'Nombre Usuario'
  },
  {
    accessor: 'apellido_usuario',
    Header: 'Apellido Usuario'
  },
  {
    accessor: 'fecha',
    Header: 'Fecha'
  },
  {
    accessor: 'hora',
    Header: 'Hora'
  },
  {
    accessor: 'nombre_evento',
    Header: 'Evento'
  },
  {
    accessor: 'id_data',
    Header: 'Id Data'
  },
  {
    accessor: 'descripcion',
    Header: 'Descripción'
  }
]



const PreviewImage = ({ base64Data, altText = '' }) => {
    return (
        <div>
            <img 
                src={`data:image/png;base64,${base64Data}`} 
                alt={altText} 
                style={{ maxWidth: '100%', height: 'auto' }}
            />
        </div>
    );
};

export default PreviewImage;

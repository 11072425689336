import axios from 'utils/axios';
import { sectionsCodes } from '../data';

// Registra el acceso a las secciones de los equipos
export const registerAccessToSection = async ( sectionCode = '', idEquipo = 0 ) => {
    const { data } = await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/registerAccessToSection`, {idEquipo, codigoSection: sectionCode});
    if( data.statusCode !== 201 ) console.error('Ha ocurrido un error al registrar el acceso a la sección');
}

// Verifica si una url termina con numero
export const isSectionOpen = () => {
    var regex = /\/(\d+)\/?$/; // Expresión regular para buscar un número al final de la URL
    return !regex.test(window.location.href); // Realizando la validacion
}

// Redirecciona al usuario segun el nivel de permiso que este contenga
export const redirectByPermission = (user, idEquipo) => {
    let profile = '';
    if( user.idTypeProfile === 1) profile = 'vendedor';
    if( user.idTypeProfile === 2) profile = 'cliente';
    if( user.idTypeProfile === 3) profile = 'administrador';
  
    let section = '';
    section = (user.profileTecnica) ? 'literatura' : '';
    section = (user.profileMessage) ? 'mensajes' : '';
    section = (user.profileReport) ? 'reportes' : '';
    section = (user.profileOperational) ? 'operacional' : '';
    section = (user.profileDimensional) ? 'dimensional' : '';

    if( user.idTypeProfile !== 3 ) registerAccessBeforeRedirect(section, idEquipo);
    
    return `/${profile}/equipo/${idEquipo}/${section}`;
};

// Registra el acceso al equipo antes de redireccionar
const registerAccessBeforeRedirect = (section = '', idEquipo = 0) => {
    switch (section) {
        case 'literatura':
            registerAccessToSection(sectionsCodes.LiteraturaTecnica, Number(idEquipo));
            break;
        case 'mensajes':
            registerAccessToSection(sectionsCodes.Mensajes, Number(idEquipo));
            break;
        case 'reportes':
            registerAccessToSection(sectionsCodes.Reportes, Number(idEquipo));
            break;
        case 'operacional':
            registerAccessToSection(sectionsCodes.InformacionOperacional, Number(idEquipo));
            break;
        case 'dimensional':
            registerAccessToSection(sectionsCodes.InformacionDimensional, Number(idEquipo));
            break;
        default:
            break;
    }
}

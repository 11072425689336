import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from 'hooks/useAuth';
import useLoadingContext from "hooks/useLoadingContext";

export const CreateClasificacion = ({ handleChange, setShowCreateModal}) => {

    const { user } = useAuth();
    const { setIsLoading } = useLoadingContext();

    const [validated, setValidated] = useState(false);

    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        id_usuario: user.id,
      });

    const handleSubmit = async(e) => {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/clasificacion/createClasification/`, formData)
        .then((response) => {
            setIsLoading(false);
            if( verifyResponseUnauthorizedFetch(response, 2) ) return;
            handleChange(true)
            setShowCreateModal(false);
            toastNotification('success', 'Clasificación creada correctamente', 2000)
          })
          .catch((err) => {
            setIsLoading(false);
            console.error(err)
            toastNotification('error', 'Ocurrió un error al intentar crear la clasificación', 2000)
            })
          }
          setValidated(true);
    };
    
    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
                type="text"
                name='nombre'
                value={formData.nombre}
                required
                onChange={handleFieldChange}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={formData.descripcion}
                onChange={handleFieldChange}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

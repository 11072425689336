import { Button, Card, CloseButton, Modal, Row } from "react-bootstrap"

export const DetalleReporte = ({ showDetailReportModal, setShowDetailReportModal, reporte }) => {

  return (
    <Modal
        size="lg"
        show={showDetailReportModal}
        centered
        onHide={() => setShowDetailReportModal(false)}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Detalle del Reporte
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowDetailReportModal(false)}
            />
        </Modal.Header>

        <Modal.Body className="m-3">
            
            <Row className="ml-2">

                <div className="row text-center">
                    <p className="col-lg-4 col-sm-12"> Usuario: <span className="fw-bold">{`${reporte.author}`}</span></p>
                    <p className="col-lg-4 col-sm-6 "> Fecha: <span className="fw-bold">{`${reporte.dateVisit}`}</span></p>
                    <p className="col-lg-4 col-sm-6 "> Semana: <span className="fw-bold">{`${reporte.published}`}</span></p>
                </div>

            </Row>
            
            <Row>
                <Card className="mx-auto py-3">

                    <div className="row mb-4">
                        <div className="col-md-3 col-xs-12">
                            <span className="fw-bold">Titulo:</span>
                        </div>
                        <div className="col-md-9 col-xs-12">
                            { reporte.title }
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-3 col-xs-12">
                            <span className="fw-bold">Detalle del Reporte:</span>
                        </div>
                        <div className="col-md-9 col-xs-12">
                            { reporte.report }
                        </div>
                    </div>
                </Card>
            </Row>

        </Modal.Body>

        <Modal.Footer>
        <Button
            variant="success"
            onClick={() => setShowDetailReportModal(false)}
        >
            Aceptar
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";

export const UpdateItem = ({ tiposCostos, setUpdateShowItemModal, updateItem, itemToUpdate }) => {

    const [validated, setValidated] = useState(false);

    const [ valor, setValor] = useState(itemToUpdate.valor);
    const [ periodo, setPeriodo ] = useState(itemToUpdate.periodo);
    const [ idTipoCosto, setIdTipoCosto ] = useState(Number(itemToUpdate.idTipoCosto));
    const [ descripcion, setDescripcion ] = useState(itemToUpdate.descripcion);
    const [ diaCosto, setDiaCosto ] = useState(itemToUpdate.diaCosto);

    const [ isPeriod, setIsPeriod ] = useState( (periodo > 0) ? true : false);
    const [ showPeriodo, setShowPeriodo ] = useState(false);
      
    const handleSubmit = async(e) => {
      e.preventDefault();  

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
      }

      const costoPropuesta = { valor, periodo, idTipoCosto, descripcion, diaCosto };
      
      updateItem( itemToUpdate.id, costoPropuesta);
      setUpdateShowItemModal(false);
    };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="formGroupGender">
            <Form.Label>Tipo de Costo</Form.Label>
            <Form.Select 
                required
                name="tipoCosto" 
                onChange={(e) => setIdTipoCosto(Number(e.target.value))}
                value={idTipoCosto}
              >
                <option value=''>Seleccione ítem</option>
                {tiposCostos.map((item) => 
                    <option
                        name="tipoCosto" 
                        key={item.id} 
                        value={item.id}
                        disabled={(item.estado === false) ? true : false}
                      >
                        {item.nombre}
                    </option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid"> {/* REACT BOOTSTREAP */}
                Por favor seleccione un ítem.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                onChange={(e) => setDescripcion(e.target.value)}
                value={descripcion}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            value={valor}
            name="costo"
            onChange={(e) => setValor(Number(e.target.value))}
            type="number"
            min={1}
            pattern="^[0-9]+"
            required            
          />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Costo, número entero y positivo sobre 0 (ej: 1000)
            </Form.Control.Feedback> 
        </Form.Group>

        <Form.Group  className="m-4">
          <Form.Label>Tipo de costo:</Form.Label>
            <br/>
            <div className='text-center'>
              <Form.Check
                inline
                label={`Costo Unitario`}
                name="type"
                type={'radio'}
                id={`fijo`}
                required
                onChange={ () => {
                  setPeriodo(0)
                  setIsPeriod(false)
                } }
                checked={ ( !isPeriod ) ? true : false }
              />

              <Form.Check
                inline
                label={`Costo Periodico`}
                name="type"
                type={'radio'}
                id={`periodico`}
                required
                onChange={ () => setIsPeriod(true) }
                checked={ ( isPeriod ) ? true : false }
              />
            </div>
          <Form.Control.Feedback type="invalid">
                Por favor seleccionar Estado.
          </Form.Control.Feedback>
        </Form.Group>

        { !isPeriod ? (
          <Form.Group className="mb-3">
            <Form.Label>Ingrese el día del costo Unitario:</Form.Label>
              <Form.Control
                autoComplete="off"
                value={diaCosto}
                name="periodo"
                onChange={(e) => setDiaCosto(Number(e.target.value))}
                type="number"
                min={0}
                pattern="^[0-9]+"
                required
              />
            <Form.Control.Feedback type="invalid">
              Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
            </Form.Control.Feedback> 
          </Form.Group>
        ) : (

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Día Primer Costo (En Días):</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    value={diaCosto}
                    name="periodo"
                    onChange={(e) => setDiaCosto(Number(e.target.value))}
                    type="number"
                    min={0}
                    pattern="^[0-9]+"
                    required
                  />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
                </Form.Control.Feedback> 
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Periodicidad (En Días):</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    value={(periodo == 0) ? '': periodo}
                    name="periodo"
                    onChange={(e) => setPeriodo(Number(e.target.value))}
                    type="number"
                    min={1}
                    pattern="^[0-9]+"
                    required
                  />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
                </Form.Control.Feedback> 
              </Form.Group>
            </Col>
          </Row>         

        ) }

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => {setUpdateShowItemModal(false);}}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

import IconButton from 'components/common/IconButton';
import React from 'react';

export const columns = [
    {
      accessor: 'titulo',
      Header: 'Título'
    },
    {
      accessor: 'fecha',
      Header: 'Fecha'
    },
    {
      accessor: 'descripcion',
      Header: 'Descripción'
    },
      {
        accessor: 'adjuntos',
        Header: 'Adjuntos'
      }
  ];
  
  export const data = [
    {
      titulo: 'Literatura Técnica 1',
      fecha: '2022-08-31',
      descripcion: 'Esta es la información técnica del equipo',
      adjuntos: [
        <IconButton
          title='Adjuntos'
          variant="white"
          size="sm"
          icon="paperclip"
          style={{ boxShadow: 'none', width: '100%' }}
        >
        </IconButton>
      ]
    },
    {
      titulo: 'Literatura Técnica 2',
      fecha: '2022-09-01',
      descripcion: 'Esta es otra información técnica del equipo',
      adjuntos: [
        <IconButton
          title='Adjuntos'
          variant="white"
          size="sm"
          icon="paperclip"
          style={{ boxShadow: 'none', width: '100%' }}
        >
        </IconButton>
      ]
    }
  ];
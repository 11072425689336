import axios from 'utils/axios';
import React, { useEffect,  useState } from 'react'
import { toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import { verifyAccess } from 'helpers';
import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'


import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";


import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import IconButton from 'components/common/IconButton';

import ModalUsuarios from './components/ModalUsuarios';
import ModalUsuariosDisabled from './components/ModalUsuariosDeshabilitados'

import BitacoraEntity from './components/BitacoraEntity';
import BitacoraData from './components/BitacoraData';

import { columnsUsuarios } from './data'
import useLoadingContext from 'hooks/useLoadingContext';

const UsuariosAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  //! BITACORAS
  const [ idBitacoraData, setIdBitacoraData ] = useState([]);

  //! GENERALES
  const [ usuarios, setUsuarios ] = useState([]);
  const [ usuariosToTable, setUsuariosToTable ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false)
  
  //! MODALES
  const [ showModalDisabled, setShowModalDisabled ] = useState(false);
  const [ showModalUsuarios, setShowModalUsuarios ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
  const [ showBitacoraData, setShowBitacoraData ] = useState(false);
  
  //! TO ACTION
  const [ idToDelete, setIdToDelete ] = useState(0);
  const [ idToUpdate, setIdToUpdate ] = useState({});
  const [ usuarioToUpdate, setUsuarioToUpdate ] = useState({});

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileUsuariosAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  useEffect( () => {
    if( usuarios.length < 1 ) return
    const toTable = usuarios.map( usuario => {
      let toReturn = {};
      toReturn.nombre = `${usuario.name} ${usuario.lastName} ${usuario.mothersLastName}`;
      toReturn.telefono = usuario.phone;
      toReturn.email = usuario.email;
      toReturn.rut = `${usuario.rut}-${usuario.dv}`;
      toReturn.acciones = [
        <IconButton
              title="Editar"
              variant="white"
              size="sm"
              icon="edit"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setUsuarioToUpdate(usuario);
                setIdToUpdate(usuario.id);
                setShowModalUsuarios(true);
              }}
        ></IconButton>,
          <IconButton
              title="Deshabilitar"
              variant="white"
              size="sm"
              icon="trash-alt"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setShowDeleteModal(true)
                setIdToDelete(usuario.id)
              }}
          ></IconButton>,
          <IconButton
              title="Ver Bitácora"
              variant="white"
              size="sm"
              icon="book"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={ () => {
                setIdBitacoraData(usuario.id)
                setShowBitacoraData(true)
              }}
          ></IconButton>
      ];
      return toReturn
    } )
    setUsuariosToTable(toTable)
  }, [usuarios])

  const startResetApp = () => {
    setShowBitacoraEntidad(false)
    setShowBitacoraData(false)
    setShowDeleteModal(false);
    setShowModalUsuarios(false);
    setIdToDelete(0);
    Promise.all([ obtenerTodosLosUsuarios() ])
      .then(([ obtenerTodosLosUsuarios, getAllUsuariosDisabled ]) => {
      })
      .catch( error => {
        console.error("Error al inicar la sección")
        toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
      })
  }
  
    //! OBTENER USUARIOS HABILITADOS
  const obtenerTodosLosUsuarios = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAllUsuarios`;
    try {
      const { data } = await axios.get(url);
      if( verifyResponseUnauthorizedAwait(data, 1) ) return;
      if( data.statusCode == 200) setUsuarios(data.data)
      setReadyContent(true)
    } catch (error) {
      console.error('Ha ocurrido un error al consultar los usuarios');
      throw 'Ha ocurrido un error al consultar los usuarios';
      setReadyContent(true)
    }
  }

  //! CREAR UN NUEVO USUARIO
  const crearNewUsuario = async ( usuario ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/createNewUser`;
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, usuario);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 2) ) return;
      if( data.statusCode === 201) toastNotification('success', 'Usuario creado correctamente')
      if( data.statusCode !== 201) toastNotification('warning', "Algo salio mal al intentar crear el usuario.");
      startResetApp();
    } catch (error) {
      setIsLoading(false);
      console.error('Ha ocurrido un error al crear un nuevo usuario');
      toastNotification('error', "Ocurrió un error al crear el usuario.");
    }
  }

  //! ACTUALIZAR UN USUARIO
  const updateUsuario = async ( usuario ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/updateUsuario/${usuario.id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, usuario);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode === 200) toastNotification('success', 'Usuario editado correctamente')
      if( data.statusCode !== 200) toastNotification('warning', 'Algo salio mal al intentar actualizar el usuario.')
      startResetApp()
    } catch (error) {
      setIsLoading(false);
      console.error('Ha ocurrido un error intentar actualizar el usuario');
      toastNotification('error', 'Ocurrió un error al editar el usuario')
    }
  }
  
  //! HABILITAR UN USUARIO
  const habilitarUsuario = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/enableUsuario/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode === 200) toastNotification('success', 'Usuario habilitado correctamente')
      if( data.statusCode !== 200) toastNotification('warning', "Algo salio mal al intentar habilitar el usuario.");
      startResetApp();
    } catch (error) {
      setIsLoading(false);
      console.error('Ha ocurrido un error al habilitar el usuario');
      toastNotification('error', "Ocurrió un error al habilitar el usuario.");
    }
  }

  //! DESHABILITAR UN USUARIO
  const disableUsuario = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/disableUsuario/${idToDelete}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 4) ) return;
      if( data.statusCode === 200) toastNotification('success', 'Usuario deshabilitado correctamente')
      if( data.statusCode !== 200) toastNotification('warning', "Algo salio mal al intentar deshabilitar el usuario.");
      startResetApp();
    } catch (error) {
      setIsLoading(false);
      toastNotification('warning', "Ocurrió un error al deshabilitar el usuario");
      console.error('Ha ocurrido un error al deshabilitar el usuario');
    }
  }


  return (
    <>
      {/* <ToastContainer /> */}

        <Card.Body className="overflow-hidden p-lg-6">

          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Usuarios</h3>
            </Col>
          </Row>

          { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <Card className="h-md-100">
              <Card.Body>

              <AdvanceTableWrapper
                columns={columnsUsuarios}
                data={usuariosToTable}
                sortable
                pagination
                perPage={10}
              >
        
                <Row className="flex-end-center mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center mb-sm-2'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowModalUsuarios(true)
                        }}
                      >
                        Nuevo Usuario
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center mb-sm-2'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowBitacoraEntidad(true)
                        }}
                      >
                        Bitácora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center mb-sm-2'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowModalDisabled(true)
                        }}
                      >
                        Deshabilitados
                      </IconButton>
                    </div>
                  </Col>
                </Row>
                  
                  <AdvanceTable
                    title='Usuarios'
                    subtitle='Tabla de Usuarios'
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  
                  <div className="mt-3">
                    <AdvanceTableFooter
                      rowCount={usuarios.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>

              </Card.Body>
            </Card>
          ) }
        </Card.Body>

      {/* <ModalUsuariosTest /> */}

      {/* MODALES */}
      <ModalUsuarios showModalUsuarios={showModalUsuarios} setShowModalUsuarios={setShowModalUsuarios} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} usuarioToUpdate={usuarioToUpdate} setUsuarioToUpdate={setUsuarioToUpdate} updateUsuario={updateUsuario} crearNewUsuario={crearNewUsuario}/>

      <ModalUsuariosDisabled showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableData={habilitarUsuario}/>

      <BitacoraEntity title='Bitácora de Usuarios' entidad={'usuario'} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>

      <BitacoraData title='Bitacora del Usuario' entidad={'usuario'} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData} idBitacoraData={idBitacoraData}/>


      <div>{/* MODAL PARA DESHABILITAR */}
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Usuario</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de deshabilitar el Usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => {
                setShowDeleteModal(false)
              }}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger"
              disabled={isLoading}
              onClick={() => {
                disableUsuario()
                setShowDeleteModal(false)
              }}

            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{/* MODAL PARA DESHABILITAR */}
    </>
  )
}

export default UsuariosAdmin
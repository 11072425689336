import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const EditPlanta = ({ data, handleChange, setShowEditModal }) => {
  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [validated, setValidated] = useState(false);

  const [nombre, setNombre] = useState(data.nombre);
  const [codigoErp, setCodigoErp] = useState(data.codigoErp);
  const [idCorporativo, setIdCorporativo] = useState(data.idCorporativo);
  const [idCiudad, setIdCiudad] = useState(data.idCiudad);
  const [idPais, setIdPais] = useState(data.idPais);
  const [idRegion, setIdRegion] = useState(data.idRegion);

  const [corporativos, setCorporativos] = useState([]);
  const [paises, setPaises] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  useEffect(() => {
    Promise.all([ getAllCorporativos(), getAllPaises(), getAllCiudades(), getRegion(idPais), getCiudad(idRegion) ])
      .then(([ allCorporativos, allPaises, allCiudades, region, ciudad ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar el formulario, Por favor intentelo nuevamente');
    })
  }, []);

  const getAllCorporativos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporations`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setCorporativos(list);
      }
    }).catch(error => {
      console.error("Ha ocurrido uin error al obtener los corporativos");
    });
  };

  const getAllPaises = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setPaises(list);
      }
    }).catch(error => {
      console.error("Ha ocurrido un error al obtener los paises");
    });
  };

  const getAllCiudades = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getAllCities`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setCiudades(list);
      }
    }).catch( error => {
      console.error("Ha ocurrido un error al obtener las ciudades");
    } );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formData = {
        nombre: nombre,
        codigo_erp: codigoErp,
        id_corporativo: idCorporativo,
        id_ciudad: idCiudad,
      };

      setIsLoading(true);
      await axios
      .put(
        `${process.env.REACT_APP_ADMIN_SERVICE}/planta/${data.id}/${user.id}`,
        formData
        )
        .then((response) => {
          setIsLoading(false);
          if( verifyResponseUnauthorizedFetch(response, 3) ) return;
          handleChange(true);
          setShowEditModal(false);
          toastNotification(
            "success",
            "Planta editada correctamente"
            );
          })
          .catch((err) => {
          setIsLoading(false);
          console.error(err);
          toastNotification("error", "Ocurrió un error al editar la planta");
        });
    }
    setValidated(true);
  };

  const getRegion = (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getRegionsByIdCountry/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setRegiones(list);
        }
      })
      .catch((err) => {
        console.error("Ha ocurrido un error al obtener la region por el ID");
        setRegiones([]);
      });
  };

  const getCiudad = (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getCitiesByIdRegion/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setCiudades(list);
        }
      })
      .catch((err) => {
        console.error("Ha ocurrido un error al obtener la ciudad por el ID");
        setCiudades([]);
      });
  };

  const handleChangePais = (e) => {
    const id = e.target.value;
    setRegiones([]);
    setCiudades([]);
    setIdRegion(0);
    setIdCiudad(0);
    setIdPais(id);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getRegionsByIdCountry/${id}`;
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setRegiones(list);
          setCiudades([]);
        }
      })
      .catch((err) => {
        setRegiones([]);
        setCiudades([]);
        getAllCiudades();
      });
  };

  const handleChangeRegion = (e) => {
    const id = e.target.value;
    setCiudades([]);
    setIdCiudad(0);
    setIdRegion(id);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getCitiesByIdRegion/${id}`;
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setCiudades(list);
        }
      })
      .catch((err) => {
        setCiudades([]);
      });
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
          <Form.Label>Nombre:</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            value={nombre}
            required
            onChange={(e) => setNombre(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Nombre.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Código ERP:</Form.Label>
          <Form.Control
            type="text"
            name="codigoErp"
            value={codigoErp}
            onChange={(e) => setCodigoErp(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Código ERP.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Corporativo:</Form.Label>
          <Form.Select
            required
            name="idCorporativo"
            value={idCorporativo}
            onChange={(e) => setIdCorporativo(e.target.value)}
          >
            <option value="">Seleccione Corporativo</option>
            {corporativos.map((item) => (
              <option name="corporativo" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Corporativo.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>País:</Form.Label>
          <Form.Select
            name="id_pais"
            value={idPais}
            onChange={handleChangePais}
          >
            <option value="">Seleccione País</option>
            {paises.map((item) => (
              <option name="pais" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Región:</Form.Label>
          <Form.Select
            name="id_region"
            value={idRegion}
            onChange={handleChangeRegion}
          >
            <option value="">Seleccione Región</option>
            {regiones.map((item) => (
              <option name="region" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Ciudad:</Form.Label>
          <Form.Select
            required
            name="id_ciudad"
            value={idCiudad}
            onChange={(e) => setIdCiudad(e.target.value)}
          >
            <option value="">Seleccione Ciudad</option>
            {ciudades.map((item) => (
              <option name="ciudad" key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Ciudad.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditModal(false)}
            disabled={isLoading}
            >
            Cancelar
          </Button>
          <Button 
            type="submit" 
            variant="success" 
            className="m-2"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Form.Group>
        
      </Form>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification } from "helpers/utils";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import useLoadingContext from "hooks/useLoadingContext";

export const NuevoItem = ({data, fechaInicio, change, setShowItemModal}) => {
  
    registerLocale('es', es)

    const { setIsLoading } = useLoadingContext();
  
    const [validated, setValidated] = useState(false);
    const[ itemList, setItemList] = useState([]);
    
    // DATOS DEL FORMULARIO
    const [description, setDescription] = useState();
    const [valor, setValor] = useState();
    const [idTipoCosto, setIdTipoCosto] = useState();
    const [date, setDate] = useState();
    const [ fecha, setFecha] = useState();

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/tipoCosto/getAllTypeCost`).then((response) => {
            const list = response.data.data;
            setItemList(list);
        });
    }, []);

    const handleSubmit = async(e) => {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {

        const formCostoReal = {
          descripcion: description,
          valor,
          idTipoCosto: Number(idTipoCosto),
          idSituacionReal: Number(data.id),
          periodo: Number(0),
          fechaCosto: fecha
        }

        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/createCostoReal/`,formCostoReal)
        .then(() =>{
          setIsLoading(false);
          change(true)
          setShowItemModal(false);
        })
          .catch((err) => {
            setIsLoading(false);
            console.error(err)
            toastNotification('error', 'Costo Real no se pudo agregar.', 2000)
          })
        }
        setValidated(true);
  };

    const handleChangeDate = (e) => {
      const fechaFormat = moment(e).format();
      setFecha(fechaFormat);
    };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="formGroupGender">
            <Form.Label>Ítem</Form.Label>
            <Form.Select 
                required
                name="idTipoCosto" 
                onChange={(e) => setIdTipoCosto(e.target.value)}>
                <option value=''>Seleccione ítem</option>
                {itemList.map((item) => 
                  item.estado ? (
                    <option 
                        name="TipoCosto" 
                        key={item.id}
                        value={item.id}
                        disabled={(item.estado === false) ? true : false}>
                            {item.nombre}
                    </option>
                  ) : ('')
                  )}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Por favor seleccione un ítem.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            name="valor"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            type="number"
            min={1}
            pattern="^[0-9]+"
            required            
          />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Valor, número entero y positivo sobre 0 (ej: 1000)
            </Form.Control.Feedback> 
        </Form.Group>

        <Form.Group className="mb-4 me-4">
        <Form.Label>Fecha:</Form.Label>
          <DatePicker
            selected={date}
            onChange={(date)=>{
              setDate(date)
              handleChangeDate(date)
            }}
            minDate={new Date(fechaInicio)}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            value={date}
            formatWeekDay={day => day.slice(0, 3)}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
            required
          />
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowItemModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

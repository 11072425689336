import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'hooks/useAuth';
// pages

import FooterStandard from 'components/pages/landing/FooterStandard';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import { CarouselPage } from 'components/pages/landing/CarouselPage';
import HeaderStandar from 'components/pages/landing/HeaderStandar';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default function AuthGuard({ children }) {
    const { isAuthenticated, user } = useAuth();
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if( user ) sessionStorage.setItem('user', user.userRut);

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            sessionStorage.clear();
            setRequestedLocation(pathname);
        }
        return   <>
            <NavbarStandard></NavbarStandard>
            <HeaderStandar />
            <CarouselPage />
            <FooterStandard></FooterStandard>
        </>

    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
}


import IconButton from "components/common/IconButton";
import { Button, Modal } from "react-bootstrap";

export const ModalFiles = ({ show = false, setShow, files }) => {

    const downloadFile = async(id) => {
        window.open(`${process.env.REACT_APP_EQUIPO_SERVICE}/file/downloadFileById/${id}`, '_blank');
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header closeButton>
                    <Modal.Title>Archivos</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    {files.length > 0 && files.map((file) => {
                        return (
                            <IconButton
                                title="Adjuntos"
                                variant="white"
                                size="sm"
                                icon="copy"
                                key={file.id} 
                                onClick={() => downloadFile(file.id)}
                            >
                                {file.name_file}.{file.extension}
                            </IconButton>
                        )
                    })}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" onClick={() => setShow(false)} >Aceptar</Button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Col, Form, Row } from "react-bootstrap";

import PropTypes from 'prop-types';

import es from 'date-fns/locale/es';
registerLocale('es', es);

export const FiltrosComponent = ({ handleChnageStructure, 
                                   handleConfigFilters, 
                                   configFilters, 
                                   corporativos, 
                                   plantasToSelect, 
                                   linaesToSelect, 
                                   areasToSelect, 
                                   tiposEquipos,
                                }) => {

  return (
    <>
      <Row className='mb-3' >

        <Col lg={3} md={6} >

          <Form.Label className='mb-0 me-2'>Corporativo:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.corporativoSelected}
            onChange={(e) => handleChnageStructure({ corporativo: Number(e.target.value) })}
          >
            <option key={'corporativo0'} value={0} disabled={corporativos.length > 0 ? true : false} >Seleccione Corporativo</option>
            {corporativos.map((corporativo) => (
              <option key={`${corporativo.id}${corporativo.nombre}`} value={corporativo.id}>{corporativo.nombre}</option>
            ))}
          </Form.Select>

        </Col>

        <Col lg={3} md={6}>

          <Form.Label className='mb-0 me-2'>Planta:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.plantaSelected}
            onChange={(e) => handleChnageStructure({ planta: Number(e.target.value) })}
          >
            <option key={'planta0'} value={0} disabled={plantasToSelect.length > 0 ? true : false} >Seleccione Planta</option>
            {plantasToSelect.map((planta) => (
              <option key={`${planta.id}${planta.nombre}`} value={planta.id}>{planta.nombre}</option>
            ))}
          </Form.Select>

        </Col>

        <Col lg={3} md={6}>
        
          <Form.Label className='mb-0 me-2'>Línea:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.lineaSelected}
            onChange={(e) => handleChnageStructure({ linea: Number(e.target.value) })}
          >
            <option key={'linea0'} value={0} disabled={linaesToSelect.length > 0 ? true : false} >Seleccione Línea</option>
            {linaesToSelect.map((linea) => (
              <option key={`${linea.id}${linea.descripcion}`} value={linea.id}>{linea.descripcion}</option>
            ))}
          </Form.Select>

        </Col>

        <Col lg={3} md={6}>
        
          <Form.Label className='mb-0 me-2'>Área:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.areaSelected}
            onChange={(e) => handleConfigFilters({ area: Number(e.target.value) })}
          >
            <option key={'area0'} value={0} disabled={areasToSelect.length > 0 ? true : false} >Seleccione Área</option>
            {areasToSelect.map((area) => (
              <option key={`${area.id}${area.nombre}`} value={area.id}>{area.nombre}</option>
            ))}
          </Form.Select>

        </Col>

      </Row>

      <Row>

        <Col md={12} lg={4}>

          <Form.Label className='mb-0 me-2'>Tipo de Equipo:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.tipoEquipoSelected}
            onChange={(e) => handleConfigFilters({ tipoEquipo: Number(e.target.value) })}
          >
            <option key={'tipoEquipo0'} value={0} disabled={tiposEquipos.length > 0 ? true : false} >Seleccione Tipo de Equipo</option>
            {tiposEquipos.map((tipoEquipo) => (
              <option key={`${tipoEquipo.id}${tipoEquipo.name}`} value={tipoEquipo.id}>{tipoEquipo.name}</option>
            ))}
          </Form.Select>

        </Col>

        <Col md={6} lg={4}>

          <Form.Label className='mb-0 me-2'>Desde:</Form.Label>
          <DatePicker
            selected={configFilters.desde}
            onChange={date => handleConfigFilters({ dateDesde: new Date(date) })}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            value={configFilters.desde}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
          />

        </Col>

        <Col md={6} lg={4}>

          <Form.Label className='mb-0 me-2'>Hasta:</Form.Label>
          <DatePicker
            selected={configFilters.hasta}
            onChange={date => handleConfigFilters({ dateHasta: new Date(date) })}
            maxDate={new Date()}
            minDate={configFilters.desde}
            dateFormat="dd/MM/yyyy"
            value={configFilters.hasta}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
          />

        </Col>

      </Row>
    </>
  )

}

FiltrosComponent.propTypes = {
  handleChnageStructure: PropTypes.func,
  handleConfigFilters: PropTypes.func,
  configFilters: PropTypes.shape({
    corporativoSelected: PropTypes.number,
    plantaSelected: PropTypes.number,
    lineaSelected: PropTypes.number,
    areaSelected: PropTypes.number,
    tipoEquipoSelected: PropTypes.number,
    desde: PropTypes.string,
    hasta: PropTypes.string
  }),
  corporativos: PropTypes.arrayOf(PropTypes.object),
  plantasToSelect: PropTypes.arrayOf(PropTypes.object),
  linaesToSelect: PropTypes.arrayOf(PropTypes.object),
  areasToSelect: PropTypes.arrayOf(PropTypes.object),
  tiposEquipos: PropTypes.arrayOf(PropTypes.object),
};

FiltrosComponent.defaultProps = {
  handleChnageStructure: () => {},
  handleConfigFilters: () => {},
};
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { Card, Col, Row, Spinner } from 'react-bootstrap';
import axios from 'utils/axios';

import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';

import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import BasicBarChart from 'components/common/BasicBarChart'
import ActiveUsers from 'components/dashboards/default/ActiveUsers';
import BandwidthSaved from 'components/dashboards/default/BandwidthSaved';
import BasicAreaLineChart from 'components/common/BasicAreaLineChart';

import { toastNotification } from 'helpers/utils';
import FalconCardHeader from 'components/common/FalconCardHeader';

export const EstadisticaEquipoPage = () => {

  let params = useParams();

  const [ summaryEquipment, setSummaryEquipment ] = useState({numberOfReportes: 0, numberOfMensajes: 0, numberOfLiteraturas: 0, numberOfPropuesta: 0});
  const [ accessToEquipment, setAccessToEquipment ] = useState({months: [], values: []});
  const [ accessToSections, setAccessToSections ] = useState([]);
  const [ accesosUsuarios, setAccesosUsuarios ] = useState([]);
  const [ statusMantencion, setStatusMantencion ] = useState({ isInstaled: false, nameMantencion: '', percentageCompleted: 0, projectedCompletionDate: '' });

  const [ isReadyContent, setIsReadyContent ] = useState(false);

  useEffect(() => startResetApp(), []);

  const startResetApp = async () => {
    try {
      const [ respSummaryEquipment, respNumbersOfAccessToEquipment, respNumbersOfAccessToSections, respAccessPersonas, respProgresoMantencion ] = await Promise.all([
        axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/estadistica/getSummaryDetailEquipment`, { idEquipo: Number(params.id) }),
        axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/estadistica/getNumbersOfAccessToEquipment`, { idEquipo: Number(params.id) }),
        axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/estadistica/getNumbersOfAccessToSectionsEquipment`, { idEquipo: Number(params.id) }),
        axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/estadistica/getAccesosDePersonas`, { idEquipo: Number(params.id) }),
        axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/estadistica/getProgresoSituacionReal`, { idEquipo: Number(params.id) }),
      ]);

      setIsReadyContent(true);

      const { data: dataSumary } = respSummaryEquipment;
      const { data: dataAccessEquipment } = respNumbersOfAccessToEquipment;
      const { data: dataAccessSections } = respNumbersOfAccessToSections;
      const { data: dataAccessPeople } = respAccessPersonas;
      const { data: dataProgress } = respProgresoMantencion;

      setSummaryEquipment(dataSumary.data[0]);
      setAccessToEquipment({ months: dataAccessEquipment.data.map( val => val.name ), values: dataAccessEquipment.data.map( val => val.value) });
      setAccessToSections(dataAccessSections.data.map( val => { return { name: val.nombreSeccion, value: val.accesos } } ));
      setAccesosUsuarios(dataAccessPeople.data.map( val => { return { id: val.id, name: val.nombre, profile: val.perfil, conexiones: val.accesos } } ));
      if(dataProgress.data) setStatusMantencion({ isInstaled: true, nameMantencion: dataProgress.data.nameMantencion, percentageCompleted: dataProgress.data.percentageCompleted, projectedCompletionDate: dataProgress.data.projectedCompletionDate })

    } catch (error) {
      setIsReadyContent(true);
      toastNotification('warning', 'Ocurrio un error al intentar obtener la estadistica de los accesos a equipos')
      console.error(error);
    };
  };

  const statsData = [
    {
      title: 'Reportes',
      value: summaryEquipment.numberOfReportes,
      decimal: false,
      suffix: '',
      prefix: '',
      link: '',
      linkText: '',
      valueClassName: 'text-info',
      image: bg2
    },
    {
      title: 'Mensajes',
      value: summaryEquipment.numberOfMensajes,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-success',
      link: '',
      linkText: '',
      image: bg3
    },
    {
      title: 'Literaturas Técnicas',
      value: summaryEquipment.numberOfLiteraturas,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '',
      linkText: '',
      image: bg1
    },
    {
      title: 'Cantidad de Propuestas',
      value: summaryEquipment.numberOfPropuesta,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '',
      linkText: '',
      image: bg1
    },
  ];

  return (
    <>
      { !isReadyContent
        ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
        : (
          <>
            <Row className="justify-content-between mb-4">
              <Col>
                <h3 className="text-primary">Estadísticas Accesos a Equipos</h3>
              </Col>
            </Row>

            {/* Resumen de Equipos */}
            <Row className="g-3 mb-3">
              { statsData.length > 0 && statsData.map((stat, index) => (
                <Col key={stat.title} xs={12} sm={6} xxl={3}>
                  <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                </Col>
              ))}
            </Row>

            {/* Grafico de Barra y Torta */}
            <Row className="g-3 mb-3">

              <Col key="graficoBarra" sm={12} md={8} >
                <Card className='h-100'>
                  <FalconCardHeader light title={'Accesos al equipo en los últimos 12 meses'} titleTag="h6" className="py-3" />
                  <Card.Body className='p-3'>
                    { accessToEquipment.values.length > 0
                      ? <BasicAreaLineChart key={'conexionesLastYear'} months={accessToEquipment.months} data={accessToEquipment.values} />
                      : <p className='text-center d-flex align-items-center justify-content-center h-75'>No se encontró información de accesos al equipo</p>
                    }
                  </Card.Body>
                </Card>
              </Col>

              <Col key="graficoBarraa" sm={12} md={4} >
                <Card className='h-100'>
                  <FalconCardHeader light title={'Mantención actual del equipo'} titleTag="h6" className="py-3" />
                  <Card.Body className='p-3 m-0 '>
                    <BandwidthSaved 
                      key={'SR'} 
                      isInstaled={statusMantencion.isInstaled} 
                      nameMantencion={statusMantencion.nameMantencion} 
                      percentageComplented={statusMantencion.percentageCompleted}
                      projectedCompletionDate={statusMantencion.projectedCompletionDate} 
                    />
                  </Card.Body>
                </Card>
              </Col>

            </Row>

            <Row className="g-3 mb-3">

              <Col key="graficoBarra" sm={12} md={12} lg={7} >
                <Card>
                  <FalconCardHeader light title={'Accesos a secciones del equipo'} titleTag="h6" className="py-3" />
                  <Card.Body className='p-3'>
                    { accessToSections.length > 0
                      ? <BasicBarChart data={accessToSections} />
                      : <p className='text-center d-flex align-items-center justify-content-center h-75'>No se ha encontrado información de accesos a las secciones del equipo</p>
                    }
                  </Card.Body>
                </Card>
              </Col>

              <Col key="graficoBarraa" sm={12} md={12} lg={5}>
                <Card>
                  <FalconCardHeader light title={'Top usuarios con más accesos al equipo'} titleTag="h6" className="py-3" />
                  <Card.Body>
                    { accesosUsuarios.length > 0
                      ? <ActiveUsers users={accesosUsuarios} />
                      : <p className='text-center d-flex align-items-center justify-content-center h-75'>No se ha encontrado información de accesos de usuarios</p>
                    }
                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </>
        )
      }
    </>
  )
}
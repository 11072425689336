import { useCallback, useEffect, useState } from "react";
import { Card, Modal, Spinner, CloseButton } from "react-bootstrap";
import PropTypes from 'prop-types';

import axios from "utils/axios";
import useLoadingContext from "hooks/useLoadingContext";
import { toastNotification } from "helpers/utils";
import { TableActionIcon } from "pages/common/components";
import { TableWrapper } from "pages/common/components/TableWrapper";
import { columnsCategoriaMensajes } from "pages/common/data/columnasTablas";

const getDataDisabled = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/getAllCategoriaMensajesDisabled`);
        return ( data.statusCode === 200 ) ? data.data : [];
    } catch (error) {
        console.error(error);
        toastNotification('warning', 'Ocurrió un error al intentar obtener las categorias deshabilitadas')
    }
};

const enableCategoriaMensaje = async ( idCategoria ) => {
    try {
        const { data } = await axios.patch(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/enableCategoriaMensaje`, { idCategoria });
        ( data.statusCode === 200 )
            ? toastNotification('success', 'Categoría de mensaje habilitada correctamente')
            : toastNotification('warning', 'Ocurrió un error al intentar habilitar la categoría de mensaje');
        return data;
    } catch (error) {
        console.error(error);
        toastNotification('warning', 'Ocurrió un error al intentar habilitar la categoría de mensaje')
    }
};

export const ModalCategoriasDeshabilitadas = ({
    showModal,
    setShowModal,
    addCategoria
}) => {

    const { setIsLoading } = useLoadingContext();

    const [ isLoadingContent, setIsLoadingContent ] = useState(false);
    const [ data, setData ] = useState([]);

    const getData = useCallback( async () => {
        setIsLoadingContent(true);
        const datos = await getDataDisabled();
        const datosAdaptados = datos.map( categoria => {
            categoria.acciones = [<TableActionIcon key={'enalbe'} title='Habilitar' width='100%' icon='check' onClick={() => enableCategoria(categoria.id)} />]
            return categoria;
        });
        setData(datosAdaptados);
        setIsLoadingContent(false);
    }, []);


    const enableCategoria = useCallback(async (idCategoria) => {
        setIsLoading(true);
        const response = await enableCategoriaMensaje(idCategoria);
        setIsLoading(false);
    
        if (response.statusCode !== 200) return;
    
        addCategoria(response.data);
        onCloseModal();
    }, [addCategoria, setIsLoading]);


    const onCloseModal = useCallback(() => {
        setShowModal(false);
        setData([]);
    }, [setShowModal]);

    useEffect(() => { if(showModal) getData(); }, [showModal]);

    return (
        <Modal
            size="xl"
            show={showModal}
            centered
            onHide={onCloseModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Categorías de Mensajes Deshabilitadas
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={onCloseModal}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card className='p-3'>
                    {isLoadingContent
                        ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}>
                            <Spinner animation="border" variant="success" />
                          </div>
                        : (data.length < 1
                            ? <p className='text-center'>No se han encontrado datos</p>
                            : <TableWrapper
                                key={'tableDisabled'}
                                columnas={columnsCategoriaMensajes}
                                data={data}
                                elementosPorPagina={10}
                                titleToExport={'Categorías de Mensajes'}
                                subtitleToExport={'Categorías de Mensajes Deshabilitadas'}
                            />
                        )
                    }
                </Card>
            </Modal.Body>
        </Modal>
    )
}


// Definición de PropTypes
ModalCategoriasDeshabilitadas.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    addCategoria: PropTypes.func.isRequired
};

// Valores predeterminados para las props
ModalCategoriasDeshabilitadas.defaultProps = {
    showModal: false,
    setShowModal: () => {},
    addCategoria: () => {},
};
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react'
import {  Card, CloseButton, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { columns} from './data'
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { ModalFiles } from 'components/ui';

export const TablaMensajes = () => {

  const { user } = useAuth();
  const [ messages, setMessages] = useState([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState()
  const [ readyContent, setReadyContent ] = useState(false);
  const [ adjuntos, setAdjuntos ] = useState([]);
  const [ showModalFiles, setShowModalFiles ] = useState(false);

  useEffect(() => {
    if (user != null){
    axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/message/getMessageByUser/${user.id}`).then((response) => {
      if( response.data.length === 0 ){
        setReadyContent(true)
        setMessage([]);
        return;
      }
      const list = response.data.data;
      list.forEach((element, index) => {
        element.date_message = element.date_message.substr(0,10)
        element.accion =  [
          <IconButton
          title='Ver Mensaje'
          variant="white"
          size="sm"
          icon="eye"
          style={{ boxShadow: 'none', width: '50%' }}
          onClick={() => {
            setShowMessageModal(!showMessageModal)
            setMessage(element.message)
          }}
        >
        </IconButton>,
        <IconButton
          title={ element.files.length > 0 ? 'Adjuntos' : 'El mensaje no posee archivos adjuntos' }
          variant="white"
          size="sm"
          icon="paperclip"
          style={{ boxShadow: 'none', width: '50%' }}
          onClick={() => {
            if( element.files.length <= 0 ) return;
            setAdjuntos(element.files);
            setShowModalFiles(!showModalFiles);
          }}
        >
        </IconButton>
        ];
      })
      setMessages(list)
      setReadyContent(true);
  });
  } }, [])

    return (
      <>
        { ( !readyContent ) ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> : 
          <div >
            <Card>
              <Card.Body>
                { ( messages.length < 1 ) ? (
                  <p className='text-center'>Aún no hay mensajes disponibles</p>
                ) : (
                  <AdvanceTableWrapper
                    columns={columns}
                    data={messages}
                    sortable
                    pagination
                    perPage={5}
                  >
                    <AdvanceTable
                      title='Mensajes'
                      subtitle='Tabla de Mensajes'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={messages.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                )}
              </Card.Body>
            </Card>
      
              <Modal
                size='lg'
                show={showMessageModal}
                centered
                onHide={() => setShowMessageModal(false)}
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">Mensaje</Modal.Title>
                  <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowMessageModal(false)}
                  />
                </Modal.Header>
                <Modal.Body className="p-0">
                  <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                      <p>{message}</p>
                    </Card.Body>
                  </Card>
                </Modal.Body>
              </Modal>

              <ModalFiles show={showModalFiles} setShow={setShowModalFiles} files={adjuntos} />
          </div>
        }
      </>
    );
    }

    export default TablaMensajes;


import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const CreateLinea = ({ handleChange, setShowCreateModal }) => {

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [validated, setValidated] = useState(false);

  const [tiposLineas, setTiposLineas] = useState([]);
  const [corporativos, setCorporativos] = useState([]);
  const [plantas, setPlantas] = useState([]);

  const [descripcion, setDescripcion] = useState();
  const [especialidad, setEspecialidad] = useState();
  const [idPlanta, setIdPlanta] = useState();
  const [idTipoLinea, setIdTipoLinea] = useState();

  useEffect(() => {
    Promise.all([ getAllTiposLineas(), getAllCorporativos() ])
      .then(([ allTiposLineas, allCorporativos ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar el formulario,  Por favor intentelo nuevamente');
    })

  }, []);

  const getAllTiposLineas = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tipoLinea/getAllTypeLines`;
    axios.get(url).then((response) => {
      if (response.data?.statusCode === 200) {
        const list = response.data.data;
        setTiposLineas(list);
      }
    }).catch( error => {
      console.error("Error al intentar consultar pos todos los tipos de lineas");
    });
  };

  const getAllCorporativos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporations`;
    axios.get(url).then((response) => {
      if (response.data?.statusCode === 200) {
        const list = response.data.data;
        setCorporativos(list);
      }
    }).catch( error => {
      console.error("Ha ocurrido un error al consultar por todos los corporativos");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formData = {
        descripcion: descripcion,
        especialidad: especialidad,
        id_planta: idPlanta,
        id_tipo_linea: idTipoLinea,
        id_usuario: user.id,
      };

      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_ADMIN_SERVICE}/linea/createLine/`,
        formData
        )
        .then((response) => {
          setIsLoading(false);
          if( verifyResponseUnauthorizedFetch(response, 2) ) return;
          handleChange(true);
          setShowCreateModal(false);
          toastNotification("success", "Línea creada correctamente");
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          toastNotification("error", "Ocurrió un error al crear la línea");
        });
    }
    setValidated(true);
  };

  const handleChangeCorporativo = (e) => {
    setIdPlanta(0);
    const id = e.target.value;
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getFloorsByIdCorporation/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setPlantas(list);
          }
        })
        .catch((err) => {
          setPlantas([]);
        });
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            name="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Descripción.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Especialidad:</Form.Label>
          <Form.Control
            type="text"
            name="especialidad"
            required
            value={especialidad}
            onChange={(e) => setEspecialidad(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Especialidad.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Corporativo:</Form.Label>
          <Form.Select name="id_corporativo" onChange={handleChangeCorporativo}>
            <option value="">Seleccione Corporativo</option>
            {corporativos.map((item) => (
              <option name="corporativo" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Planta:</Form.Label>
          <Form.Select
            required
            name="id_planta"
            value={idPlanta}
            onChange={(e) => setIdPlanta(e.target.value)}
          >
            <option value="">Seleccione Planta</option>
            {plantas.map((item) => (
              <option name="planta" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Planta.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Tipo Línea:</Form.Label>
          <Form.Select
            required
            name="id_tipo_línea"
            value={idTipoLinea}
            onChange={(e) => setIdTipoLinea(e.target.value)}
          >
            <option value="">Seleccione Tipo Línea</option>
            {tiposLineas.map((item) => (
              <option name="tipo_linea" key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Tipo Línea.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button 
            type="submit" 
            variant="success" 
            className="m-2"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Form.Group>
        
      </Form>
    </div>
  );
};

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts'; // Importa la serie de barras
import { TooltipComponent } from 'echarts/components'; // También necesitas importar el componente de tooltip si aún no lo has hecho
import { GridComponent } from 'echarts/components'; // Y el componente de grid si aún no lo has hecho
import { getColor } from "helpers/utils";
import PropTypes from 'prop-types';

// Registra los componentes que necesitas usar
echarts.use([BarChart, TooltipComponent, GridComponent]);

function ChartOptions({ data = [], labelAxisX, labelAxisY }) {
  
    const getOption = () => ({
       tooltip: {
          trigger: 'axis',
          padding: [7, 10],
          backgroundColor: getColor('gray-100'),
          borderColor: getColor('gray-300'),
          textStyle: { color: getColor('gray-1100') },
          borderWidth: 1,
          // formatter: tooltipFormatter,
          transitionDuration: 0,
          axisPointer: {
            type: 'none'
          }
        },
        xAxis: {
          type: 'category',
          data: data.map(val => val.name),
          axisLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'solid'
            }
          },
          axisTick: { show: false },
          axisLabel: {
            color: getColor('gray-400'),
            formatter: value => ( value.split(/\s+/).length > 1 ) ? `${value.split(' ')[0].charAt(0)} ${value.split(' ')[1].charAt(0)}` : `${value.split(' ')[0].charAt(0)}`, // Obteniendo la primera letra de la primera palabra y la primera letra de la segunda palabra
            margin: 15
          },
          splitLine: {
            show: false
          },
          name: labelAxisX, // Etiqueta del eje X
          nameLocation: 'middle',
          nameGap: 32, // Ajuste la distancia de la etiqueta del eje
          nameTextStyle: {
            color: getColor('black'),
            padding: [0, 0, 0, 0]
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: getColor('gray-400'),
            margin: 35
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: getColor('gray-200')
            }
          },
          axisTick: { show: false },
          axisLine: { show: false },
          min: 0,
          name: labelAxisY, // Etiqueta del eje Y
          nameLocation: 'middle',
          nameGap: 5, // Ajuste la distancia de la etiqueta del eje
          nameRotate: 90,
          nameTextStyle: {
            color: getColor('black'),
            padding: [0, 0, 0, 0],
          }
        },
        series: [
          {
            type: 'bar',
            name: 'Total',
            data: data.map(val => val.value),
            lineStyle: { color: getColor('primary') },
            itemStyle: {
              color: getColor('primary'),
              borderRadius: [3, 3, 0, 0]
            },
            showSymbol: false,
            symbol: 'circle',
            smooth: false,
            emphasis: {
              scale: true
            }
          }
        ],
        grid: { right: '3%', left: '10%', bottom: '15%', top: '5%' }
      });
      return (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: '18.75rem' }}
        />
      );
    }
  
ChartOptions.propTypes = {
  echarts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  labelAxisX: PropTypes.string,
  labelAxisY: PropTypes.string,
}

ChartOptions.defaultProps = {
  labelAxisX: '',
  labelAxisY: ''
};

export default ChartOptions;

  
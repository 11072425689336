import React, { useEffect, useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsModelo } from '../data'
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';


export const DisabledModelo = () => {

  const { user } = useAuth();
  const { setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);

  const [ modelosDisabled, setModelosDisabled] = useState([]);
  const [ enableModelo, setEnableModelo] = useState();
  const [ showEnableModal, setShowEnableModal] = useState(false);

  const startResetApp = async () => {
      getAllModelosDisabled();
  }

  const getAllModelosDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/modelo/getAllModelsDisabled`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      const list = response.data.data
      list.forEach((element) => {
        element.acciones = [
          <IconButton
          title="Habilitar"
          variant="white"
          size="sm"
          icon="check"
          style={{ boxShadow: "none", width: "100%" }}
          onClick={() => {
            setShowEnableModal(true)
            setEnableModelo(element.id)
          }}
          ></IconButton>
        ]
      })
      setModelosDisabled(list);
      setReadyContent(true);
    }).catch(error => {
      setReadyContent(true);
      console.error(error);
      toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
    });
  }


  // HABILITAR MODELO
  const handleEnable = async () => {
    setIsLoading(true);
    await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/modelo/enableModel/${enableModelo}/${user.id}`)
    .then((response) => {
      setIsLoading(false);
      if( verifyResponseUnauthorizedFetch(response, 3) ) return;
      setReadyContent(false);
      startResetApp()
      setShowEnableModal(false)
      toastNotification('success', 'Modelo habilitado correctamente')
      }
    )
    .catch((err) => {
      setIsLoading(false)
      setReadyContent(true);
      toastNotification('error', 'Ocurrió un error al intentar habilitar el modelo')
    })
  };

  useEffect( () => startResetApp(), [])
    

  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
      : (
        <>
                { !modelosDisabled.length > 0 ? (
                <h6>No hay Modelos Deshabilitados</h6>
              ) :
              (
                <div>
                  <AdvanceTableWrapper
                    columns={columnsModelo}
                    data={modelosDisabled}
                    sortable
                    pagination
                    perPage={10}
                  >
                    <AdvanceTable
                      title='Modelos de Equipos Deshabilitados'
                      subtitle='Tabla de Modelos de Equipos Deshabilitados'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={modelosDisabled.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                </div>
              )
            }

            {/* MODAL ENABLE */}
            <div>
        <Modal
          show={showEnableModal}
          onHide={() => setShowEnableModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Modelo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere Habilitar el Modelo?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEnableModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleEnable}
            >
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
      )}
    </>
  )
}

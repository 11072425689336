import axios from 'utils/axios';
import { errors } from 'data/errors';
import useAuth from 'hooks/useAuth';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OlvidePassword from '../../components/authentication/olvidePassword'
import useLoadingContext from "hooks/useLoadingContext";

import { toastNotification } from '../../helpers/utils'

const LoginForm = ({ hasLabel, layout }) => {
  const {prelogin} = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [ showModalOlvidePassword, setShowModalOlvidePassword ] = useState(false);

  // State
  const [formData, setFormData] = useState({
    userEmail: '',
    userPassword: ''
  });


  // Recuperar contraseña
  const recuperarContraseña = async ( email ) => {
    const url = `${process.env.REACT_APP_EMAILS_SERVICE}/sendEmails/recoverPassword`;
    try {
      const { data } = await axios.post(url, { email })
      if( data.statusCode === 200 ) toastNotification('success', data.message);
      if( data.statusCode === 204 ) toastNotification('warning', 'El correo electrónico no se encontró en nuestros registros');
    } catch (error) {
      toastNotification('error', 'Se ha producido un error al intentar enviar las instrucciones')
    }
  };

  // Handler
  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    if(!e.target){
    }else{
      // const response = await prelogin(formData.userEmail, formData.userPassword);
      await prelogin(formData.userEmail, formData.userPassword)
      .then(() => {setIsLoading(false);})
      .catch( ({response}) => {
        if(response){
          if(response.data.statusCode === errors.credentialIncorrect){
            toastNotification('error', 'Credenciales incorrectas!')
          }
        }
        setIsLoading(false);
      });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div>
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Correo electrónico</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Correo' : ''}
          value={formData.userEmail}
          name="userEmail"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          value={formData.userPassword}
          name="userPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label className='text-muted cursor-pointer hover:text-dark' onClick={() => { setShowModalOlvidePassword(true) }}>¿Has olvidado tus datos de inicio de sesión?</Form.Label>
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="btn btn-primary mt-3 w-100"
          disabled={!formData.userEmail || !formData.userPassword || isLoading}
        >
          Ingresar
        </Button>
      </Form.Group>
    </Form>
    <div>
      <OlvidePassword modalShow={showModalOlvidePassword} setModalShow={setShowModalOlvidePassword} handleOlvide={recuperarContraseña}/>
    </div>
    </div>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

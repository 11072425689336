import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button, Row, Spinner } from 'react-bootstrap';
import axios from 'utils/axios';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';

import { columnsListaAtributos } from '../data.js'

import ModalAddEditOpcion from './ModalAddEditOpcion';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils.js';

const ModalAtributos = ({ showAtributoModal, setShowAtributoModal, tiposUnidadDeMedida, idToUpdate, atributoToUpdate, setAtributoToUpdate, setIdToUpdate, createNewAtributo, updateAtributo}) => {

  const [ showModalOption, setShowModalOption ] = useState(false);

  const [ descripcion, setDescripcion] = useState('');
  const [ isDimensional, setIsDimensional] = useState();
  const [ idTipoMedida, setIdTipoMedida] = useState(0);

  const [ opciones, setOpciones ] = useState([]);
  const [ opcionesButtons, setOpcionesButtons ] = useState([]);

  const [ idOptionToUpdate, setIdOptionToUpdate ] = useState(0);
  const [ opcionToUpdate, setOpcionToUpdate ] = useState({});

  const [ consultandoOpciones, setConsultandoOpciones ] = useState(false);

  const [ validated, setValidated ] = useState(false);


  useEffect( () => {
    if( idToUpdate > 0 ){
        setDescripcion(atributoToUpdate.description);
        (atributoToUpdate.isDimensional === "Si") ? setIsDimensional(true) : setIsDimensional(false);
        setIdTipoMedida(atributoToUpdate.typeMeasureUnit.id)
        if( atributoToUpdate.typeMeasureUnit.id == 4 ) getOpcionesByIdAtributo( atributoToUpdate.id );
    }else{
        setDescripcion('');
        setIsDimensional(true);
        setIdTipoMedida(0);
    }

    //! Se ejecuta cuando se cierra el modal
    if( !showAtributoModal ){
        setDescripcion('');
        setIsDimensional(true);
        setIdTipoMedida(0);
        setOpciones([]);
    }
  }, [showAtributoModal]);

  useEffect( () => {
    if( !showModalOption ){
        setIdOptionToUpdate(0)
        setOpcionToUpdate({});
    }
  }, [showModalOption])


  useEffect( async () => {
    async function saveNewOptions() {
        
        let optionActions = opciones.map( o => {
            o.descripcion = o.descripcion
            o.acciones = [
                <IconButton
                    title="Editar"
                    variant="white"
                    size="sm"
                    icon="edit"
                    style={{ boxShadow: "none", width: "50%" }}
                    onClick={() => {
                        setIdOptionToUpdate(o.id);
                        setOpcionToUpdate(o);
                        setShowModalOption(true);
                    }}
                ></IconButton>,
                <IconButton
                    title="Eliminar"
                    variant="white"
                    size="sm"
                    icon="trash-alt"
                    style={{ boxShadow: "none", width: "50%" }}
                    onClick={() => eliminarOpcion(o.id)}
                ></IconButton>
            ];
            return o;
        })
        setOpcionesButtons(optionActions);
    }
    await saveNewOptions();
  }, [opciones]);

  const manipularAtributo = ( opcion ) => {
    if( !opcion.hasOwnProperty('id') ){
        //! AGREGANDO
        opcion.id = Math.floor(Math.random() * (10000000 - 1 + 1)) + 1;;
        setOpciones([...opciones, opcion]);
    }else{
        //! ACTUALIZANDO
        updateOpcion(opcion);
    }   
    setShowModalOption(false);
  }

  //! ACTUALIZAR UNA OPCION
  const updateOpcion = ( opcion ) => {
    let options = opciones.map( op => {
        if( op.id == opcion.id ) op.descripcion = opcion.descripcion;
        return op;
    } )
    setOpciones(options);
  }
    
  //! ELIMINAR UNA OPCION
  const eliminarOpcion = ( id ) => { 
    const newOpciones = opciones.filter( opc => opc.id !== id );
    setOpciones(newOpciones);
  }

  //! OBTENER OPCIONES DEL ATRIBUTO A ACTUALIZAR (SOLO EN CASO DE SER DE TIPO LISTA)
  const getOpcionesByIdAtributo = async ( id ) => {
    setConsultandoOpciones(true);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getOpcionesByIdAtributo/${id}`;
    try {
        const { data } = await axios.get(url);
        if( verifyResponseUnauthorizedAwait(data, 1) ) return;
        if( data.statusCode == 200 ) setOpciones(data.data);
    } catch (error) {
        console.error("Error al consultar las opciones del atributo");
    }
    setConsultandoOpciones(false);
  }

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }
    

    if( idToUpdate > 0 ){
        const atributo = { idToUpdate, descripcion, isDimensional, idTipoMedida, listadoOpciones: opciones }
        updateAtributo(atributo);
    }else{
        const atributo = { descripcion, isDimensional, idTipoMedida, listadoOpciones: opciones  }
        createNewAtributo(atributo);
    }
  }

  return (
    <>
    <Modal
        show={showAtributoModal}
        centered
        className={ showModalOption ? 'd-none' : '' }
        onHide={() => {
            setShowAtributoModal(false);
            setIdToUpdate(0)
            setAtributoToUpdate({})
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? 'Actualizar Atributo' : "Nuevo Atributo" }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowAtributoModal(false);
                    setIdToUpdate(0)
                    setAtributoToUpdate({})
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        <Form.Group className="mb-3" controlId="formGroupGender">
                            <Form.Label>Tipo de Unidades de Medida: </Form.Label>
                            <Form.Select 
                                required
                                name="tipoCosto" 
                                value={idTipoMedida}
                                onChange={(e) => setIdTipoMedida(Number(e.target.value))}
                            >
                                <option value=''>Seleccione Unidad de Medida</option>
                                {tiposUnidadDeMedida.map(( unidad ) => 
                                    <option 
                                        name="tipoCosto" 
                                        key={unidad.id} 
                                        value={unidad.id}
                                    >
                                        {unidad.descripcion}
                                    </option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccione una unidad de medida.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Descripción:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                required
                                value={descripcion}
                                onChange={(e) => {
                                    setDescripcion(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar descripción valida.
                            </Form.Control.Feedback>              
                        </Form.Group>


                        <Form.Group className={ idTipoMedida == 4 ? 'mb-1 ms-3' : 'mb-3 ms-3' }>
                            <Form.Label>¿Es Dimensional?</Form.Label>
                            <br/>
                            <div className='d-flex text-center justify space-between'>
                                <Form.Check
                                    inline
                                    label={`Si`}
                                    name="type"
                                    type={'radio'}
                                    id={`Si`}
                                    required
                                    checked={ ( isDimensional ) ? true : false }
                                    onChange={ () => { 
                                        setIsDimensional(true)
                                    }}
                                />
                                <Form.Check
                                    inline
                                    label={`No`}
                                    name="type"
                                    type={'radio'}
                                    id={`No`}
                                    required
                                    checked={ ( !isDimensional ) ? true : false }
                                    onChange={() => {
                                        setIsDimensional(false)
                                    }}

                                />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                    Por favor seleccionar una opción.
                            </Form.Control.Feedback>
                        </Form.Group>


                        {
                            idTipoMedida == 4 && (
                            ( consultandoOpciones ) ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>
                            : (    
                                <Card>
                                    <Card.Body>
                                        <AdvanceTableWrapper
                                        columns={columnsListaAtributos}
                                        data={opcionesButtons}
                                        sortable
                                        pagination
                                        perPage={50}
                                        >
                                        <Row className="mb-3">
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h6>Lista de opciones:</h6>
                                                <div id="new-document">
                                                    <IconButton
                                                    variant="falcon-success"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    className='me-2'
                                                    onClick={() => (setShowModalOption(true))}
                                                    >
                                                    Opción
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Row>
                                        {/* ESTA ES LA TABLA */}
                                        <AdvanceTable
                                            title='Atributos'
                                            subtitle='Atributos'
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                            bordered: true,
                                            striped: true,
                                            className: 'fs--1 mb-0 overflow-hidden'
                                            }}
                                        />
                                        </AdvanceTableWrapper>
                                    </Card.Body>
                                </Card> 
                            ))
                        }

                        <Form.Group aria-disabled={true}>
                            <Button
                                variant="secondary"
                                className="m-2"
                                onClick={() => {
                                    setShowAtributoModal(false)
                                    setAtributoToUpdate({})
                                    setIdToUpdate(0)
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" variant="success" className="m-2">
                                Guardar
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
    </Modal>


    <ModalAddEditOpcion showModal={showModalOption} setShowModal={setShowModalOption} idToUpdate={idOptionToUpdate} opcionToUpdate={opcionToUpdate} submitOpcion={manipularAtributo} />
    </>
  )
}

export default ModalAtributos
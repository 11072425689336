import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button} from 'react-bootstrap';

const ModalPaises = ({ showPaisesModal, setShowPaisesModal, idToUpdate, setIdToUpdate, paisToUpdate, setPaisToUpdate, createNewPais, updatePais }) => {

  const [ nombre, setNombre ] = useState( ( idToUpdate > 0 ) ? paisToUpdate.nombre : '' )
  const [ validated, setValidated ] = useState(false);

  useEffect(() => {
    ( idToUpdate > 0 ) ? setNombre(paisToUpdate.nombre) : setNombre('')
  }, [showPaisesModal])

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( idToUpdate > 0 ){
        const pais = { id: idToUpdate, nombre, descripcion: nombre }
        updatePais(pais)
    }else{
        createNewPais({ nombre, descripcion: nombre })
    }
  }



  return (
    <>
    <Modal
        show={showPaisesModal}
        centered
        onHide={() => {
            setShowPaisesModal(false)
            setIdToUpdate(0)
            setPaisToUpdate({})
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? 'Actualizar País' : "Nuevo País" }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowPaisesModal(false)
                    setIdToUpdate(0)
                    setPaisToUpdate({})
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>


                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                type="text"
                                name="Nombre"
                                required
                                value={nombre}
                                onChange={(e) => {
                                    setNombre(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar un nombre valido.
                            </Form.Control.Feedback>              
                        </Form.Group>
                        

                        <Form.Group aria-disabled={true}>
                            <Button
                                variant="secondary"
                                className="m-2"
                                onClick={() => {
                                    setShowPaisesModal(false)
                                    setIdToUpdate(0)
                                    setPaisToUpdate({})
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" variant="success" className="m-2">
                                Guardar
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default ModalPaises
import React, { useState, useEffect } from 'react'
import { columnsUsuarios } from '../data'
import { Card, Modal, Button, CloseButton, Spinner } from 'react-bootstrap'
import axios from "utils/axios";
import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

const ModalUsuariosDisabled = ({ showModalDisabled, setShowModalDisabled, enableData }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ usuariosDisabled, setUsuariosDisabled ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ idUserToEnable, setIdUserToEnable ] = useState(0);


  useEffect(() => {
    if (showModalDisabled) {
        setReadyContent(false);
        getAllUsuariosDisabled();
    };
  }, [showModalDisabled]);

  useEffect( () => {
    const toTable = usuariosDisabled.map( data => {
        let toReturn = {};
        toReturn.nombre = `${data.name} ${data.lastName} ${data.mothersLastName}`;
        toReturn.telefono = data.phone;
        toReturn.email = data.email;
        toReturn.rut = `${data.rut}-${data.dv}`;
        toReturn.acciones = [
          <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                  setShowConfirmation(true);
                  setIdUserToEnable(data.id);
                }}
            ></IconButton>
        ];
        return toReturn
      } );
      setReadyContent(true);
    setMensajesToTable(toTable)
  }, [usuariosDisabled]);

    //! OBTENER TODOS LOS USUARIOS DESHABILITADOS
    const getAllUsuariosDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/usuario/getAllUsuariosDisabled`;
          try {
            const { data } = await axios.get(url);
            if (data.statusCode === 200 || data.statusCode === 204){
                setUsuariosDisabled(data.data)
            }
          } catch (error) {
            console.error('Ha ocurrido un error al consultar los usuarios deshabilitados')
            throw 'Ha ocurrido un error al consultar los usuarios deshabilitados';
          }
      }

  return (
    <>
      <Modal
          size='xl'
          show={showModalDisabled}
          centered
          className={ showConfirmation ? 'd-none' : '' }
          onHide={() => {
              setShowModalDisabled(false)
          }}
          >
          <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                  Datos Deshabilitados
              </Modal.Title>
              <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0"
                  onClick={ () => {
                      setShowModalDisabled(false)
                  }}
              />
          </Modal.Header>

          <Modal.Body className="p-3">
          { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
              <Card className='p-3'>
                  { ( usuariosDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                      <AdvanceTableWrapper
                          columns={columnsUsuarios}
                          data={mensajesToTable}
                          sortable
                          pagination
                          perPage={5}
                      >
                      
                      <AdvanceTable
                        title='Usuarios Deshabilitados'
                        subtitle='Tabla de Usuarios Deshabilitados'
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />
                      <div className="mt-3">
                          <AdvanceTableFooter
                          rowCount={usuariosDisabled.length}
                          table
                          rowInfo
                          navButtons
                          rowsPerPageSelection
                          />
                      </div>
                      </AdvanceTableWrapper>
                  ) }
              </Card>
              )}
          </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmation}
        onHide={() => {
          setShowConfirmation(false);
          setIdUserToEnable(0);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Habilitar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de querer habilitar el usuario?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmation(false);
              setIdUserToEnable(0);
            }}
          >
            Cancelar
          </Button>
          <Button variant="success" 
            onClick={() => {
              setShowConfirmation(false);
              setShowModalDisabled(false);
              enableData(idUserToEnable);
            }}
          >
            Habilitar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalUsuariosDisabled
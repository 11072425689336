import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import AtributosAdmin from '../AtributosAdmin';

const InputAtributos = ({ item, handleChangeAtributos, unidadesMedida }) => {
  
  //! VALOR DEL INPUT
  const [ id, setId ] = useState((item.id) ? item.id : '')
  const [ value, setValue ] = useState((item.value) ? item.value : '');
  //! TIPO VALOR DEL INPUT
  const [ tipoValor, setTipoValor ] = useState({});

  useEffect( () => {
    if( item.value ){
      setValue(item.value);
      setTipoValor(item.measureUnit);
    }else{
      setValue('');
      setTipoValor(getTypeMedida());
    }
  }, []);

  //! OBTIENE LA DESCRIPCION DE LA PRIMERA UNIDAD DE MEDIDA COINCIDENTE; es el valor por defecto en el listado
  const getTypeMedida = () => {
    const medida = unidadesMedida.find((medida) => item.attribute.typeMeasureUnit.id === medida.idTipoUnidadMedida)
    return medida ? medida : null;
  }

  //! CAMBIA EL VALOR DEL INPUT
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //! CAMBIA EL VALOR DEL TIPO
  const handleClick = (value) => {
    setTipoValor(value);
  };

  //! ENVIAR VALORES AL PADRE
  const handleSubmit = () => {
    if(tipoValor == null && item.attribute.typeMeasureUnit.id !== 4) return
    let objToSend = { 
      id,
      value, 
      id_atributo: item.attribute.id, 
      is_lista: (item.attribute.typeMeasureUnit.id == 4) ? true : false,
      idTipoUnidadMedida: ( tipoValor && tipoValor.id ) ? tipoValor.id : null, 
      [item.attribute.description]: item.attribute.description
    };
    handleChangeAtributos(objToSend);
  };

  const dropdownItems = unidadesMedida.map((unidad, index) => {
    if( unidad.tipoUnidadMedida == 'Lista' ) return;
    if( item.attribute.typeMeasureUnit.id !== unidad.idTipoUnidadMedida ) return;
    if( tipoValor == null ) return;
    
    return (
      <Dropdown.Item key={unidad.id} onClick={() => handleClick(unidad)} active={tipoValor.id === unidad.id}>
        {unidad.nombre}
      </Dropdown.Item> 
    )
  })

  return (
    (item.attribute.isDimensional) &&
                  
    <Form.Group className="mb-3">
        <Row>
          <Col>
            <Form.Label>{item.attribute.description}:</Form.Label>
          </Col>
          <Col>
        
            <InputGroup className="mb-3" onBlur={handleSubmit}>

            { ( item.attribute.typeMeasureUnit.id == 4 ) ? (
                  /* ACA DEBEMOS MANDAR LA LISTA */
                  <>
                      <Form.Select 
                          name="Select" 
                          value={value} 
                          onChange={handleChange}
                      >
                          <option key={''} disabled={true} value="">Seleccione</option>

                          {item.attribute.listadoOpciones.map((option, index) => (
                              <option key={index} value={option.descripcion} >{option.descripcion}</option>
                            )
                          )}
                      </Form.Select>
                  </>
                  
              ) : (
                  /* ACA DEBEMOS MANDAR EL INPUT */
                  <>
                      <Form.Control 
                        aria-label="Text input with dropdown button"
                        type="number" 
                        value={value}
                        onChange={ (e) => {handleChange(e)}}
                      />

                      <DropdownButton
                        variant="outline-secondary"
                        title={ ( tipoValor == null || tipoValor == undefined ) ? "Seleccione" : tipoValor.descripcion }
                        id="input-group-dropdown-2"
                        align="end"
                      >
                          { dropdownItems }
                      </DropdownButton>
                  </>
              ) }
              
            </InputGroup>

          </Col>
        </Row>

    </Form.Group>
  )
}

export default InputAtributos
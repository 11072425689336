import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { CantidadEquiposPorAreaChart } from 'pages/common/components/CantidadEquiposPorAreaChart';
import { TiposEquiposPorAreaChart } from 'pages/common/components/TiposEquiposPorAreaChart';
import { PrincipalesEquiposAhorroEconomicoChart } from 'pages/common/components/PrincipalesEquiposAhorroEconomicoChart';

const InicioCliente = () => {

  const { user } = useAuth()

  const[ equipmentList, setEquipmentList ] = useState([]);
  const corporationList = [];
  const floorList = [];
  const typeEquipmentList = [];
  const industryList = [];

  const [ equiposMoreVida, setequiposMoreVida ] = useState();
  const [ totalAhorro, setTotalAhorro ] = useState(0);

  const [ readyContent, setReadyContent ] = useState(false);

  useEffect(() => {
      getCantidadEquiposVidaUtil();
      getCantidadAhorrado();
      axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getDetailEquipmentByIdUsuario/` + user.id).then(({data}) => {
        setEquipmentList(data.data);
        setReadyContent(true);
      });
    },[])
      equipmentList.forEach(element => {
      corporationList.push({name: element.corporation})
      floorList.push({name: element.floor})
      typeEquipmentList.push({name: element.type})
      industryList.push({name: element.industry})
    })


  const getCantidadEquiposVidaUtil = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getEquiposVidaUtilByIdUser/${user.id}`
    const { data } = await axios.get(url);
    setequiposMoreVida(data.data);
  }

  const getCantidadAhorrado = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getAhorroProyectadoByIdUser/${user.id}`
    const { data } = await axios.get(url);
    setTotalAhorro(data.data);
  }

  const statsData = [
    {
      title: 'Equipos en Monitoreo',
      value: equipmentList.length,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/cliente/equipos',
      linkText: 'Ver Equipos'
    },
    {
      title: 'Cantidad de equipos que superan su vida util proyectada',
      value: equiposMoreVida,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '',
      linkText: ''
    },
    {
      title: 'Ahorro proyectado por la totalidad de los equipos',
      value: totalAhorro,
      decimal: false,
      suffix: '',
      prefix: '$',
      valueClassName: 'text-warning',
      link: '',
      linkText: ''
    }
  ];

    return (
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="justify-content-between">
          <Col lg={6} className="ps-lg-4 my-5text-lg-start">
            <h3 className="text-primary">Inicio</h3>
          </Col>
        </Row>
        { (!readyContent) ? ( <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>) : (
        <Row className="g-3 mb-3">
          {statsData.map((stat, index) => (
            <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
              <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
            </Col>
          ))}
        </Row>
        )}

        { user.userGraphicsConfiguration != null && user.userGraphicsConfiguration.length > 1 && (
          <>
            { user.userGraphicsConfiguration.some( grafico => grafico._id === 1 ) && <Col md={12}><CantidadEquiposPorAreaChart /></Col> }
            <Row className='g-3'>
              { user.userGraphicsConfiguration.some( grafico => grafico._id === 2 ) && <Col sm={12} md={6}><TiposEquiposPorAreaChart /></Col> }
              { user.userGraphicsConfiguration.some( grafico => grafico._id === 3 ) && <Col sm={12} md={6}><PrincipalesEquiposAhorroEconomicoChart /></Col> }
            </Row>
          </>
        )}



      </Card.Body>
          
      );
    };

export default InicioCliente;

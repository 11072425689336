import React from 'react'
import TablaLiteratura from './TablaLiteratura'
import useAuth from 'hooks/useAuth';
import { Navigate } from "react-router-dom";

export const Literatura = () => {
  const { user } = useAuth();
  if( !user.profileTecnica ) return <Navigate to={"/"} />;
  return (
    <>
      <h4 className="text-primary mb-4">Literatura Técnica</h4>
      <TablaLiteratura />
    </>
  )
}

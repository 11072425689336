import React, { useEffect, useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsCargo } from '../data'
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';


export const DisabledCargo = () => {

    const { user } = useAuth();
    const { setIsLoading } = useLoadingContext();
    const [ readyContent, setReadyContent ] = useState(false);

    const [ cargosDisabled, setCargosDisabled] = useState([]);
    const [ enableCargo, setEnableCargo] = useState();
    const [ showEnableModal, setShowEnableModal] = useState(false);

    const startResetApp = async () => {
        getAllCargosDisabled();
      }

  const getAllCargosDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/cargo/getAllJobsDisabled`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      const list = response.data.data
      list.forEach((element) => {
        element.acciones = [
          <IconButton
          title="Habilitar"
          variant="white"
          size="sm"
          icon="check"
          style={{ boxShadow: "none", width: "100%" }}
          onClick={() => {
            setShowEnableModal(true)
            setEnableCargo(element.id)
          }}
          ></IconButton>
        ]
      })
      setCargosDisabled(list);
      setReadyContent(true);
    }).catch(error => {
      setReadyContent(true);
      console.error(error);
      toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
    });
  }

  // HABILITAR CLASIFICACION
  const handleEnable = async () => {
    setIsLoading(true);
    await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/cargo/enableJob/${enableCargo}/${user.id}`)
    .then((response) => {
      setIsLoading(false);
      if( verifyResponseUnauthorizedFetch(response, 3) ) return;
      startResetApp()
      setReadyContent(false);
      getAllCargosDisabled();
      setShowEnableModal(false)
      toastNotification('success', 'Cargo habilitado correctamente')
    })
    .catch((err) => {
      setIsLoading(false);
      setReadyContent(true);
      toastNotification('error', 'Ocurrió un error al intentar habilitar el cargo')
    })
  };

  useEffect( () => startResetApp(), [])
    

  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
      : (
        <>
                { !cargosDisabled.length > 0 ? (
                <h6>No hay Cargos Deshabilitados</h6>
              ) :
              (
                <div>
                  <AdvanceTableWrapper
                    columns={columnsCargo}
                    data={cargosDisabled}
                    sortable
                    pagination
                    perPage={10}
                  >  
                    <AdvanceTable
                      title='Cargos Deshabilitados'
                      subtitle='Tabla de Cargos Deshabilitados'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={cargosDisabled.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                </div>
              )
            }

            {/* MODAL ENABLE */}
            <div>
        <Modal
          show={showEnableModal}
          onHide={() => setShowEnableModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Cargo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere Habilitar el Cargo?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEnableModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleEnable}
            >
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
      )}
    </>
  )
}

import PropTypes from 'prop-types';
import { Button, Modal } from "react-bootstrap"

export const ModalConfirmacion = ({ 
    showModal = false, 
    setShowModal, 
    title = '', 
    msg = '', 
    confirmar,
    btnVariant = 'primary',
    textDecline = 'No',
    textAcept = 'Sí',
}) => {

    return (
        <Modal show={showModal} backdrop="static" centered keyboard={false} >

            <Modal.Header>
                <Modal.Title>{ title }</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                { msg }
            </Modal.Body>

            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={() => setShowModal(false) }
                >
                    { textDecline }
                </Button>
                <Button 
                    variant={btnVariant}
                    onClick={() => confirmar() } 
                >
                    { textAcept }
                </Button>
            </Modal.Footer>

        </Modal>
    );
};

ModalConfirmacion.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    msg: PropTypes.string,
    confirmar: PropTypes.func.isRequired,
    btnVariant: PropTypes.string,
    textDecline: PropTypes.string,
    textAcept: PropTypes.string,
};
import PropTypes from 'prop-types';
import { TableWrapper } from 'pages/common/components/TableWrapper';
import { Card, CloseButton, Modal } from 'react-bootstrap';

export const columnsAccesosUser = [
    {
        accessor: 'usuario_nombre',
        Header: 'Usuario'
    },
    {
        accessor: 'descripcion_perfil',
        Header: 'Perfil'
    },
    {
        accessor: 'nombre_cargo',
        Header: 'Cargo'
    },
    {
        accessor: 'conexiones',
        Header: 'Conexiones'
    },
];

export const ModalDetalle = ({ showModal, setShowModal, dataToShow = [], nombrePlanta = '', daySelected = '' }) => {
    return (
        <Modal
            size='lg'
            show={showModal}
            centered
            onHide={() => setShowModal(false)}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Detalle de Accesos</Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowModal(false)}
                />
            </Modal.Header>

            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        { dataToShow.length > 0
                            ? (
                                <TableWrapper
                                    key={'Tabla'}
                                    columnas={columnsAccesosUser}
                                    data={dataToShow}
                                    elementosPorPagina={10}
                                    titleToExport={`Accesos a la Planta`}
                                    subtitleToExport={`Accesos a la planta ${nombrePlanta} el dia ${daySelected}`}
                                />
                            )
                            : (
                                <p className='text-center fw-bold fs--1'>No se encuentran registros de accesos en el día {daySelected}</p>
                            )
                        }
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    )
}

ModalDetalle.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    dataToShow: PropTypes.arrayOf(PropTypes.shape({
        usuario_id: PropTypes.number,
        usuario_nombre: PropTypes.string,
        descripcion_perfil: PropTypes.string,
        nombre_cargo: PropTypes.string,
        conexiones: PropTypes.string,
    })),
    nombrePlanta: PropTypes.string,
    daySelected: PropTypes.string,
};

ModalDetalle.defaultProps = {
    dataToShow: [],
    nombrePlanta: '',
    daySelected: '',
}

import { useEffect, useState } from "react";
import axios from "utils/axios";
import { Button, Modal, Spinner } from "react-bootstrap"
import { toastNotification } from "helpers/utils";
import PdfViewer from "./PdfViewer";
import VideoPlayer from "./VideoPlayer";
import PreviewImage from "./PreviewImage";

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv'];
const pdfExtensions = ['pdf'];

const getFileBase64 = async (fileId) => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/file/getFileBase64/${fileId}`);
        return data.data;
    } catch (error) {
        console.error(error);
        toastNotification('error', 'Ocurrió un error al intentar obtener la previsualización del archivo')
        return '';
    }
}

export const ModalPreviewFile = ({ showModal, setShowModal, file }) => {

    const [ readyContent, setReadyContent ] = useState(false);
    const [ fileDecode, setFileDecode ] = useState('');
    
    const renderPreview = () => {
        if( pdfExtensions.includes(file.extension.toLowerCase()) ) return <PdfViewer base64={fileDecode} />;
        if( imageExtensions.includes(file.extension.toLowerCase()) ) return <PreviewImage base64Data={fileDecode} />;
        if( videoExtensions.includes(file.extension.toLowerCase()) ) return <VideoPlayer base64Data={fileDecode} />;
        return <p className="text-center fw-bold">Tipo de archivo no compatible para previsualización.</p>;
    };

    const initializatePreviewer = async () => {
        setReadyContent(false);
        const fileBase = await getFileBase64(file.id);
        setFileDecode(fileBase);
        setReadyContent(true);
    };

    useEffect(() => {
        if( showModal ) initializatePreviewer();
    }, [ showModal, file ]);

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Previsualización de archivo</Modal.Title>
            </Modal.Header>     


            <Modal.Body>
                {
                    !readyContent
                        && <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}>
                            <Spinner className='' animation="border" variant="success" />
                            </div>    
                }

                {
                    (fileDecode?.trim?.() !== '' && readyContent)
                    && (<div className="d-flex justify-content-center text-center">{ renderPreview() }</div>)
                }

            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="success"
                    onClick={() => setShowModal(false)}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal> 
    )
}

import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0, 0.5)', // Fondo transparente
        zIndex: 9999, // Por encima de todo el contenido
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner 
        animation="border text-primary"
        style={{ width: 45, height: 45 }}
        role="status"
      />
    </div>
  );
};

export default LoadingScreen;

import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { tooltipFormatter } from 'helpers/echart-utils';
import { formatterPesoChile, getColor, getPastDates, getPastDatesPropuesta, getPastDatesSituacionAnterior } from 'helpers/utils';
import { Inicio } from 'pages/vendedor/inicio/Inicio';
import PropTypes from 'prop-types';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (situacionAnterior, propuesta, situacionReal, fechaInicio, fechaTermino, periodo = null) => ({
  color: [getColor('info'), getColor('danger'), getColor('success')],
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('secondary') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (value) {
      let string = `<div style="font-size:14px;font-weight:400;line-height:1;"><p>Día: <span style="font-size:14px;font-weight:900">${value[0].axisValueLabel}</span></p></div>`
      value.forEach( val => {
        string += `<div style="margin: 0px 0 5px;line-height:1;">
                    ${val.marker}
                    <span style="font-size:14px;font-weight:400;margin-left:2px">${ val.seriesName }</span>
                    <span style="float:right;margin-left:20px;font-size:14px;font-weight:900">${ formatterPesoChile(val.value) }</span>
                    <div style="clear:both"></div>
                  </div>`
      });

      return string;
    }
  },
  xAxis: {
    type: 'category',
    data: ( fechaInicio && fechaTermino ) ? getPastDatesPropuesta(fechaInicio, fechaTermino).map(date => dayjs(date).format('DD/MM/YY')) : null,
    tickAmount: periodo,
    boundaryGap: false,
    // silent: false,
    /* axisPointer: {
      lineStyle: {
        color: getColor('gray-300')
      }
    }, */
    /* splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    }, */
    /* axisTick: {
      show: true,
      length: 20,
      lineStyle: {
        color: getColor('gray-200')
      },

      interval: 5
    }, */
    // LINEA DE ABAJO
    /* axisLabel: {
      color: getColor('gray-600'),
      formatter: value => dayjs(value).format('MMM DD YYYY'),
      align: 'left',
      fontSize: 11,
      padding: [0, 0, 0, 5],
      interval: 5
    } */
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisLabel: {
      show: true,
      color: getColor('gray-600'),
      formatter: value => `${formatterPesoChile(value)}`
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: [
    {
      type: 'line',
      name: 'Propuesta',
      data: propuesta,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('info'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('info')
      },
      symbolSize: 2
    },
    {
      type: 'line',
      name: 'Situación Anterior',
      data: situacionAnterior,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('danger'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('danger')
      },
      symbolSize: 2
    },
    {
      type: 'line',
      name: 'Situación Real',
      data: situacionReal,
      showSymbol: false,
      symbol: 'circle',
      itemStyle: {
        borderColor: getColor('success'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('success')
      },
      symbolSize: 2
    },
  ],
  grid: {
    containLabel: true,
    right: '5px',
    left: '30px',
    bottom: 0,
    top: 10
  }
}); 

const ActiveUsersChart = ({ situacionAnterior, propuesta, situacionReal, fechaInicio, fechaTermino, periodo = null }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(situacionAnterior, propuesta, situacionReal, fechaInicio, fechaTermino, periodo)}
      style={{ height: '16.25rem'}}
    />
  );
};

ActiveUsersChart.propTypes = {
  situacionAnterior: PropTypes.array,
  propuesta: PropTypes.array,
  situacionReal: PropTypes.array,
};

export default ActiveUsersChart;

import Camara from "components/camara/Camara";
import { getExtension, toastNotification } from "helpers/utils";
import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Button, Form, Tabs, Tab, Modal, CloseButton, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import { TablaArchivos } from "pages/common/components";

const EditarMensaje = ({messageToEdit, setMessageToEdit, startResetApp, showEditMessageModal, setShowEditMessageModal }) => {

  const { user } = useAuth();
  let params = useParams();
  const author = `${user.displayName} ${user.displayLastName}`;

  const [ issue, setIssue ] = useState('');
  const [ message, setMessage ] = useState('');

  const [ archivos, setArchivos ] = useState([]); // Listado de archivos para mostrar en pantalla
  const [ idCategoria, setIdCategoria ] = useState(undefined);
  const [ newArchivos, setNewArchivos ] = useState([]); // Nuevos archivos a agregar
  const [ archivosToDelete, setArchivosToDelete ] = useState([]); // Archivos para eliminar

  const [ categoriasMensajes, setCategoriasMensajes ] = useState([]);

  const [ validated, setValidated ] = useState(false);

  useEffect(() => {
    if( messageToEdit ) asignForm();
  }, [messageToEdit]);

  useEffect(() => {
    getCategoriaMensajes()
  }, []);

  useEffect( () => {
    if( !showEditMessageModal ) resetForm();
  }, [showEditMessageModal])

  const asignForm = () => {
    setIssue(messageToEdit.issue);
    setMessage(messageToEdit.message);
    setArchivos( messageToEdit.files );
    setIdCategoria( !messageToEdit.categoria_message ? undefined : messageToEdit.categoria_message.id )
  }

  const resetForm = () => {
    setIssue('');
    setMessage('');
    setArchivos([]);
    setNewArchivos([]);
    setArchivosToDelete([]);
    setMessageToEdit();
    setIdCategoria(0);
    setValidated(false);
  }

  const getCategoriaMensajes = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/categoriaMensaje/getAllCategoriaMensajes`;
    try {
      const { data } = await axios.get(url);
      ( data.statusCode === 200 )
        ? setCategoriasMensajes(data.data)
        : toastNotification('error', 'Ha ocurrido un error al obtener las categorias de mensajes');
    } catch (error) {
      console.error("Algo salio mal al intentar consultar la categorias de mensajes");
      toastNotification('error', 'Error al consultar las categorias de mensajes')
    }
  }

  const [inputKey, setInputKey] = useState(Date.now());

  const [ showCamaraModal, setShowCamaraModal ] = useState(false);
  const [ buttonDisabled, setButtonDisabled ] = useState(false);


  const handleSubmit = async (e) => {

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const formToSend = new FormData();
    
    formToSend.append("id_equipment", params.id);
    formToSend.append("id_user", user.id);
    formToSend.append("author", author);
    formToSend.append("id_category_message", idCategoria);
    
    if(issue !== null) formToSend.append("issue", issue);
    if(message !== null) formToSend.append("message", message);

    if( archivosToDelete.length > 0 ) formToSend.append("filesToDelete", archivosToDelete)
    
    if (newArchivos) {
      for (const element of newArchivos) {
        formToSend.append("files", element.file);
      }
    }

    setButtonDisabled(true)
    try {
      const resp = await axios.put(`${process.env.REACT_APP_EQUIPO_SERVICE}/message/${messageToEdit.id}`, formToSend);
      if( resp.status === 200 ){
        toastNotification('success', 'Mensaje actualizado correctamente');
        startResetApp();
        setShowEditMessageModal(false);
      }
      setButtonDisabled(false);
    } catch (error) {
      ( error.response?.data?.statusCode === 413) 
        ? toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
        : toastNotification("error", "Ha ocurrido un error al intentar eliminar el mensaje", 2000)
      
      setButtonDisabled(false);
      return;
    }

    setValidated(false);
  };

  const handleFilesChange = (e) => {
    setInputKey(Date.now()); // Para reiniciar el valor del input

    const filesRecibidos = [...e];
    let array = filesRecibidos.map((archivo) => {
      const nameExtension = getExtension(archivo.name);
      return {
        file: archivo,
        temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
        name_file: nameExtension[0],
        extension: nameExtension[1],
        date_file: Date.now(),
        size: archivo.size,
        user: { name: user.displayName, lastName: user.displayLastName },
      }
    })

    setNewArchivos([ ...newArchivos, ...array ]);
    setArchivos([ ...archivos, ...array]);
  };

  const handlePhotoChange = ( file ) => {
    const nameExtension = getExtension(file.name);
    const newPhoto = {
      file: file,
      temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
      name_file: nameExtension[0],
      extension: nameExtension[1],
      date_file: Date.now(),
      size: file.size,
      user: { name: user.displayName, lastName: user.displayLastName },
    };

    setArchivos([...archivos, newPhoto]);
    setNewArchivos([ ...archivos, newPhoto ]);
    setShowCamaraModal(false);
  }

  const handleDeleteFile = ( archivo ) => {
    if( archivo.temporalId ){ // Archivo no creado
      setNewArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
      setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
    }else{ // Archivo a eliminar
      setArchivos( archivos.filter( arch => arch.id !== archivo.id ) );
      setArchivosToDelete([ ...archivosToDelete, archivo.id]);
    }
  }


  return (
    <>
      {/* MODAL PARA EDITAR UN MENSAJE */}
      <Modal
        size="lg"
        show={showEditMessageModal}
        className={ showCamaraModal ? 'd-none' : '' }
        centered
        onHide={() => setShowEditMessageModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Mensaje
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowEditMessageModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card>
            <Card.Body className="fs--1 fw-normal p-4">


              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                
                <Form.Group className="mb-3" controlId="categoriaMensajeGroup">
                    <Form.Label>Categoría de Mensaje:</Form.Label>
                    <Form.Select
                        value={idCategoria}
                        onChange={(e) => setIdCategoria(e.target.value)}
                        name="categoriaMensaje"
                        required
                    >
                        <option disabled selected value="">--Seleccione Categoría--</option>
                        { categoriasMensajes.map( categoria => <option key={categoria.id} value={categoria.id}>{categoria.descripcion}</option> ) }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Por favor seleccione categoría de mensaje.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Asunto:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={messageToEdit?.issue}
                    name="issue"
                    onChange={(e) => setIssue(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar el asunto.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Mensaje:</Form.Label>
                  <Form.Control
                    onChange={(e) => setMessage(e.target.value)}
                    defaultValue={messageToEdit?.message}
                    as="textarea"
                    name="message"
                    rows={3}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar el mensaje.
                  </Form.Control.Feedback>
                </Form.Group>

                <Tabs defaultActiveKey="uploadFile" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="uploadFile"
                    title="Subir Archivo"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                      <Form.Control
                        key={inputKey}
                        type="file" 
                        multiple 
                        onChange={(e) => handleFilesChange(e.target.files)}
                      />
                    </Form.Group>

                  </Tab>

                  <Tab
                    eventKey="takePhoto"
                    title="Tomar Foto"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formPhotoMultiple" className="mb-3">
                      <Form.Label>Tome una fotografía:</Form.Label>
                      <Button
                        variant="success"
                        className="ms-4"
                        onClick={() => setShowCamaraModal(true)}
                        disabled={buttonDisabled}
                      >
                        Tomar Foto
                      </Button>
                    </Form.Group>

                  </Tab>
                </Tabs>

                { archivos.length > 0 && (
                    <div className="my-4">
                      <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                    </div>
                  )
                }

                <Form.Group>
                  <Button
                    className="m-2"
                    variant="secondary"
                    onClick={() => setShowEditMessageModal(false)}
                    disabled={buttonDisabled}
                  >
                    Cancelar
                  </Button>
                  <Button disabled={buttonDisabled} className="m-2" variant="success" type="submit">
                    Guardar
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      {/* MODAL CAMARA */}
      <Modal
        size="lg"
        show={showCamaraModal}
        centered
        onHide={() => setShowCamaraModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Cámara</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowCamaraModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="fs--1 fw-normal">
              <Camara savePhoto={handlePhotoChange} />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditarMensaje;

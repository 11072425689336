import React from 'react'
import TablaReportes from './TablaReportes'
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';


export const Reportes = () => {
  const { user } = useAuth();
  if( !user.profileReport ) return <Navigate to={"/"} />;
  return (
    <>
      <h4 className="text-primary mb-4">Reportes</h4>
      <TablaReportes />
    </>
  )
}

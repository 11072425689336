import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from 'hooks/useAuth';
import useLoadingContext from "hooks/useLoadingContext";

export const EditTipoUnidadMedida = ({data , handleChange, setShowEditModal}) => {

    const { user } = useAuth();
    const { setIsLoading } = useLoadingContext();

    const [validated, setValidated] = useState(false);

    const [ descripcion, setDescripcion ] = useState(data.descripcion);

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {

          const formData = {
            descripcion: descripcion
          }

          setIsLoading(true);
          await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/tipoUnidadMedida/${data.id}/${user.id}`,formData)
          .then((response) => {
              setIsLoading(false);
              if( verifyResponseUnauthorizedFetch(response, 3) ) return;
              handleChange(true);
              setShowEditModal(false);
              toastNotification('success', 'Tipo de unidad de medida editado correctamente')
            })
            .catch((err) => {
              setIsLoading(false);
              console.error(err)
              toastNotification('error', 'Ocurrió un error al editar la unidad de medida')
            })
          }
          setValidated(true);
    };

  return (
    <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import axios from 'utils/axios';
import { Card, Row, Col,  Modal, Button, Spinner } from 'react-bootstrap'

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { columnsEquipos } from './data'
import IconButton from 'components/common/IconButton';

import ModalTipoEquipo from './components/ModalTipoEquipo.js';
import ModalBitacoraEntidad from './components/BitacoraEntidad.js'
import ModalBitacoraData from './components/BitacoraData.js'
import ModalDeshabilitados from './components/ModalEquiposDeshabilitados'

import { showAlertaTokenExpired, toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import useLoadingContext from 'hooks/useLoadingContext';

const TiposEquiposAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { setIsLoading } = useLoadingContext();

  const [ listTypeEquipos, setListTypeEquipos ] = useState([]);
  const [ listTypeEquiposBotones, setListTypeEquiposBotones ] = useState([]);

  const [ idToDelete, setIdToDelete ] = useState(0);
  const [ idToUpdate, setIdToUpdate ] = useState(0);

  const [ readyContent, setReadyContent ] = useState(false);

  const [ showDisabled, setShowDisabled ] = useState(false);
  const [ showDeleteTypeModal, setShowDeleteTypeModal ] = useState(false);
  const [ showItemModal, setShowItemModal ] = useState(false);
  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
  const [ showBitacoraData, setShowBitacoraData ] = useState(false);
  const [ typeEquipoToUpdate, setTypeEquipoToUpdate ] = useState({});

  const [ atributos, setAtributos ] = useState([]);
  const [ idEntidad, setIdEntidad ] = useState(6);
  const [ idBitacoraData, setIdBitacoraData ] = useState([]);

  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profileTipoEquipoAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])


  const startResetApp = () => {
    Promise.all([ getAllTiposEquipos(), getAllAtributos() ])
      .then(([tiposEquipos, atributos ]) => {
        setReadyContent(true)
      })
      .catch(error => {
        toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
        setReadyContent(true)
      })
  }

  //! Obtener todos los atributos
  const getAllAtributos = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getAllAtributos`;
    try {
      const { data } = await axios.get(url);
      if(data.statusCode === 200 ) setAtributos(data.data);
    } catch (error) {
      console.error('Error al intentar obtener todos los atributos');
    }
  }

  //! Obtener todos los tipos de equipos
  const getAllTiposEquipos = async () => {
    setReadyContent(false)
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposDeEquipos`;
    try {
      const { data } = await axios.get(url);
      if( !data || data === undefined || data.statusCode === 401 ) {
        showAlertaTokenExpired();
        return;
      }
      setReadyContent(true)
      if( data.statusCode == 200 && data.data.length > 0 ) setListTypeEquipos(data.data);
    } catch (error) {
      console.error('Error al intentar obtener los tipos de equipo');
      setReadyContent(true)
    }
  };


  //! Crear un nuevo tipo de equipo
  const crearTypeEquipo = async ( typeEquipo ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/createNewTypeEquipo`
    try {
      setIsLoading(true);
      const { data } = await axios.post(url, typeEquipo);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 2) ) return;
      if(data.statusCode == 201) toastNotification('success', 'Tipo de equipo creado correctamente')
      if(data.statusCode !== 201) toastNotification('warning', 'Ocurrio un error al crear el tipo de equipo')
      
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar crear el tipo de equipo');
      toastNotification('error', 'Ocurrio un error al crear el tipo de equipo')
    }
    setShowItemModal(false);
    startResetApp();
  }
  
  //! Actualizar un nuevo tipo de equipo
  const updateTypeEquipo = async ( typeEquipo ) => {
    const { idToUpdate, ...rest } = typeEquipo;
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/updateTypeEquipoById/${idToUpdate}`
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, rest);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tipo de equipo editado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', 'Ocurrio un error al editar el tipo de equipo')
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar actualizar el tipo de equipo');
      toastNotification('error', 'Ocurrio un error al editar el tipo de equipo')
    }
    setShowItemModal(false);
    startResetApp();
  }


  //! Habilitar un tipo de equipo
  const habilitarTypeEquipo = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/enableTypeEquipoById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 3) ) return;
      if( data.statusCode == 200 ) {
        toastNotification('success', 'Tipo de equipo habilitado correctamente')
        startResetApp()
      }
      if(data.statusCode !== 200) toastNotification('warning', 'Ocurrio un error al habilitar el tipo de equipo')
    } catch (error) {
      setIsLoading(false);
      console.error('Error al intentar habilitar el tipo de equipo');
      toastNotification('error', 'Ocurrio un error al habilitar el tipo de equipo')
    }
  }
  

  //! Eliminar un tipo de equipo
  const deleteTypeEquipo = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/deleteTypeEquipoById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if( verifyResponseUnauthorizedAwait(data, 4) ) return;
      if( data.statusCode == 200 ) toastNotification('success', 'Tipo de equipo deshabilitado correctamente')
      if( data.statusCode !== 200  ) toastNotification('warning', 'Ocurrio un error al deshabilitar el tipo de equipo')
    } catch (error) {
    setIsLoading(false);
    console.error('Error al intentar deshabilitar el tipo de equipo');
      toastNotification('error', 'Ocurrio un error al deshabilitar el tipo de equipo')
    }

    setShowDeleteTypeModal(false)
    startResetApp();
  }

  useEffect( () => {
    const tiposEquipos = listTypeEquipos.map( equipo => {
      equipo.acciones = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowItemModal(true)
            setTypeEquipoToUpdate(equipo)
            setIdToUpdate( equipo.id )
          }}
        ></IconButton>,
        <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setShowDeleteTypeModal(true)
            setIdToDelete( equipo.id )
          }}
        ></IconButton>,
        <IconButton
            title="Ver Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={ () => {
              setIdBitacoraData(equipo.id);
              setShowBitacoraData(true);
            }}
          ></IconButton>
      ]
      return equipo;
    })
    setListTypeEquiposBotones(tiposEquipos);
  }, [ listTypeEquipos ] )


  return (
    <>
      <Card.Body className="overflow-hidden p-lg-6">
          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Tipos de Equipos</h3>
            </Col>
          </Row>

        { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
        : (
          <Card className="h-md-100">
            <Card.Body>
            <AdvanceTableWrapper
            columns={columnsEquipos}
            data={listTypeEquiposBotones}
            sortable
            pagination
            perPage={5}
          >

            <Row className="flex-end-center mb-3">
              <Col xs={12} lg={4} className='mx-auto mb-2'>
                <div className='text-center'>
                  <IconButton
                    variant="falcon-success"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => {
                      setTypeEquipoToUpdate({})
                      setIdToUpdate(0)
                      setShowItemModal(true)
                    }}
                  >
                    Nuevo Tipo de Equipo
                  </IconButton>
                </div>
              </Col>
              <Col xs={12} lg={4} className='mx-auto mb-2'>
                <div className='text-center'>
                  <IconButton
                    variant="falcon-primary"
                    size="sm"
                    icon="book"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => {
                      setShowBitacoraEntidad(true)
                    }}
                  >
                    Bitácora General
                  </IconButton>
                </div>
              </Col>
              <Col xs={12} lg={4} className='mx-auto mb-2'>
                <div className='text-center'>
                  <IconButton
                    variant="falcon-primary"
                    size="sm"
                    icon="trash"
                    transform="shrink-3"
                    className="me-2"
                    onClick={() => {
                      setShowDisabled(true)
                    }}
                  >
                    Deshabilitados
                  </IconButton>
                </div>
              </Col>
            </Row>
            
            <AdvanceTable
              title='Tipos de Equipos'
              subtitle='Tabla Tipos de Equipos'
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={listTypeEquipos.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        ) }
      </Card.Body>

      {/* MODALES */}
      
      <div>
        <ModalTipoEquipo showItemModal={showItemModal} setShowItemModal={setShowItemModal} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} typeEquipoToUpdate={typeEquipoToUpdate} setTypeEquipoToUpdate={setTypeEquipoToUpdate} crearTypeEquipo={crearTypeEquipo} updateTypeEquipo={updateTypeEquipo} atributos={atributos}/>
      </div>

      <div>
        <ModalBitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
      </div>
      
      <div>
          <ModalBitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
      </div>

      <div>
        <ModalDeshabilitados title={'Tipos de Equipos Deshabilitados'} showDisabled={showDisabled} setShowDisabled={setShowDisabled} habilitarTypeEquipo={habilitarTypeEquipo}/>
      </div>

      <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
        <Modal
          show={showDeleteTypeModal}
          onHide={() => setShowDeleteTypeModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Tipo de Equipo</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de deshabilitar el tipo de equipo?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteTypeModal(false)}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger"
              onClick={() => { 
                deleteTypeEquipo(idToDelete)
              }}
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
    </>
  )
}

export default TiposEquiposAdmin
import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button} from 'react-bootstrap';
import { toastNotification, validatePass } from 'helpers/utils';


const ModalChangePassword = ({ modalShow, setModalShow, changePassword }) => {
  const [ validated, setValidated ] = useState(false);
  
  const [ oldPassword, setOldPassword ] = useState('');
  const [ newPassword, setNewPassword ] = useState('');
  const [ repeatNewPassword, setRepeatNewPassword ] = useState('');
  const [ confirmacion, setConfirmacion ] = useState(false);

  //? MODAL OLVIDE PASSWORD 
  const [ showModalOlvidePassword, setShowModalOlvidePassword ] = useState(false);

  const reiniciarEstados = () => {
    setModalShow(false)
    setValidated(false)
    setOldPassword('')
    setNewPassword('')
    setRepeatNewPassword('')
  }

  const recuperarContraseña = ( correo ) => {

  }

  
  const validar = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( newPassword !== repeatNewPassword ) {
        toastNotification('error','Las contraseñas no coinciden');
        return;
    }

    const isValidPass = validatePass(newPassword);
    if( !isValidPass ) return;

    setModalShow(false)
    setConfirmacion(true);
  }

  const cambiarPass = () => {
    reiniciarEstados();
    
    changePassword(oldPassword, newPassword);
  }

  return (
    <>
    <Modal
        show={modalShow}
        centered
        onHide={() => {
            reiniciarEstados()
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Cambio de Contraseña
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    reiniciarEstados()
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={validar}>


                        <Form.Group className="mb-3" controlId="oldPassword">
                            <Form.Label>Ingrese la contraseña actual:</Form.Label>
                            <Form.Control 
                                placeholder='Contraseña Actual'
                                autoComplete="off"
                                type="password"
                                name="oldPassword"
                                required
                                value={oldPassword}
                                onChange={(e) => {
                                    setOldPassword(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese la contraseña actual
                            </Form.Control.Feedback>              
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="exampleForm.newPassword">
                            <Form.Label>Ingrese la nueva contraseña:</Form.Label>
                            <Form.Control 
                                placeholder='Nueva Contraseña'
                                autoComplete="off"
                                type="password"
                                name="newPassword"
                                required
                                value={newPassword}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, ingrese la nueva contraseña
                            </Form.Control.Feedback>              
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="exampleForm.repeatNewPassword">
                            <Form.Label>Repita la nueva contraseña:</Form.Label>
                            <Form.Control 
                                placeholder='Repita la nueva contraseña'
                                autoComplete="off"
                                type="password"
                                name="repeatNewPassword"
                                required
                                value={repeatNewPassword}
                                onChange={(e) => {
                                    setRepeatNewPassword(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, repita la nueva contraseña
                            </Form.Control.Feedback>              
                        </Form.Group>
                        

                        <Form.Group aria-disabled={true} className={'mt-4'}>
                            <Button
                                variant="secondary"
                                className="m-2"
                                disabled={false}
                                onClick={() => {
                                    reiniciarEstados()
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type='submit' variant="success" className="m-2" disabled={false} >
                                Cambiar Contraseña
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
    </Modal>

    <Modal
        show={confirmacion}
        centered
        onHide={() => {
            setConfirmacion(false)
            reiniciarEstados()
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Confirmar
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setConfirmacion(false)
                    reiniciarEstados()
                }}
            />
        </Modal.Header>

        <Modal.Body className="p-2 m-2">
            <p className=''>¿Está seguro de querer cambiar la contraseña?</p>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="secondary"
                className="m-2"
                disabled={false}
                onClick={() => {
                    reiniciarEstados()
                    setConfirmacion(false)
                }}
            >
                Cancelar
            </Button>
            <Button onClick={() => {
                cambiarPass();
                setConfirmacion(false);
            }} variant="success" className="m-2" disabled={false} >
                Cambiar Contraseña
            </Button>
        </Modal.Footer>
    </Modal>
    </>
  )
}

export default ModalChangePassword
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification } from "helpers/utils";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import useLoadingContext from "hooks/useLoadingContext";

export const DetenerSituacionReal = ({data, fechaInicio, idPropuesta, fechaRetorno, change, setShowDetenerModal, costosAnteriores}) => {
  
    registerLocale('es', es)
    
    const { isLoading, setIsLoading } = useLoadingContext();
    const [validated, setValidated] = useState(false);
    
    // DATOS DEL FORMULARIO
    const [comentarioDetener, setComentarioDetener] = useState();
    const [date, setDate] = useState();
    const [ fecha, setFecha] = useState();

    const handleSubmit = async(e) => {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      } else {

        const formDetenerSituacionReal = {
          comentarioDetener: comentarioDetener,
          idSituacionReal: Number(data.id),
          idPropuesta: Number(data.id_propuesta),
          fechaTermino: fecha,
          fechaRetornoInversion: fechaRetorno,
          costosAnteriores: costosAnteriores
        }
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/stopSituacionReal/`,formDetenerSituacionReal)
        .then(() =>{
          change(true);
          setIsLoading(false);
          setShowDetenerModal(false);
          window.location.reload();
        })
        .catch((err) => {
          console.error(err)
          toastNotification('error', 'Situación Real no se pudo Detener.', 2000)
          setIsLoading(false);
        })
      }
        setValidated(true);
  };

    const handleChangeDate = (e) => {
      const fechaFormat = moment(e).format();
      setFecha(fechaFormat);
    };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Comentario:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={comentarioDetener}
                onChange={(e) => setComentarioDetener(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Comentario para detener la Situación Real.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-4 me-4">
        <Form.Label>Fecha:</Form.Label>
          <DatePicker
            selected={date}
            onChange={(date)=>{
              setDate(date)
              handleChangeDate(date)
            }}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(fechaInicio)}
            maxDate={new Date()}
            value={date}
            formatWeekDay={day => day.slice(0, 3)}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
            required
          />
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowDetenerModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2" disabled={isLoading}>
            Detener
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";

import axios from "utils/axios";
import FalconCardHeader from "components/common/FalconCardHeader";
import ActiveUsers from "components/dashboards/default/ActiveUsers";
import { formatNumber, toastNotification } from "helpers/utils";

export const PrincipalesEquiposAhorroEconomicoChart = () => {

    useEffect(() => getData(), [])

    const [ readyContent, setReadyContent ] = useState(false);
    const [ data, setData ] = useState([]);

    const getData = async() => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/getAhorroEquipos`);
            const dataRefactorized = (data.statusCode === 200) 
                                     ? data.data.map( dato => { return { id: dato.idEquipo, name: dato.nameEquipo, profile: dato.nameArea, conexiones: formatNumber(dato.ahorro) } } )
                                     : [];
            setData(dataRefactorized);
        } catch (error) {
            toastNotification('error', 'Ocurrió un error al intentar obtener el ahorro de los equipos');
            console.error(error);
        }
        setReadyContent(true);
    };

    return (
        <Card className="mb-3 h-100">
            <FalconCardHeader light title={'Top 5 Equipos con más Ahorro Económico'} titleTag="h6" className="py-3" />
            <Card.Body className='p-3'>

                { !readyContent && <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> }

                { readyContent && (
                    data.length > 0 
                    ? <ActiveUsers users={data} />
                    : <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}>
                        <p>No se han encontrado equipos con ahorro</p>
                      </div>
                ) }

            </Card.Body>
        </Card>
    )
};
import { useCallback, useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Spinner } from 'react-bootstrap';

import PageLayout from 'layouts/PageLayout'
import { TableWrapper } from 'pages/common/components/TableWrapper';
import { TableActionIcon, TableHeaderButton } from 'pages/common/components';
import { columnasTabla } from '../config/ColumnasTabla';
import { getAllAvisosMantenciones, getAllEstadosAvisosMantencion, deleteAviso } from '../services';
import moment from 'moment';
import { ModalAddEditAviso } from '../components/ModalAddEditAviso';
import useLoadingContext from 'hooks/useLoadingContext';
import { ModalConfirmacion } from 'pages/common/components/ModalConfirmacion';
import BitacoraData from 'pages/common/components/Bitacora';


const idEntidad = 30;
let idDatoBitacora = 0;
let idAvisoToDelete = 0;

export const AvisoMantencionesPage = () => {

  const { setIsLoading } = useLoadingContext();

  const [ readyContent, setReadyContent ] = useState(false);
  const [ avisosMantencion, setAvisosMantencion ] = useState([]);
  const [ estadosAvisosMantencion, setEstadosAvisosMantencion ] = useState([]);
  const [ avisoToUpdate, setAvisoToUpdate ] = useState({});

  const [ showModalBitacora, setShowModalBitacora ] = useState(false);
  const [ showAddEditModal, setShowAddEditModal ] = useState();
  const [ showModalDelete, setShowModalDelete ] = useState(false);

  const getAvisosMantencion = async () => {
    const avisos = await getAllAvisosMantenciones();
    const avisosFormated = formatAvisosToTable(avisos);
    setAvisosMantencion(avisosFormated);
  };

  const formatAvisosToTable = ( avisos ) => {
    const avisosFormated = avisos.map( aviso => {
      aviso.nombre_estado = getEstadoAviso(aviso.estadoAviso.nombreEstado);
      aviso.fechaInicio = moment(aviso.fechaInicio).format('DD/MM/yyyy - HH:mm');
      aviso.fechaFin = moment(aviso.fechaFin).format('DD/MM/yyyy - HH:mm');
      aviso.acciones = createBtnActions(aviso);
      return aviso;
    });
    return avisosFormated;
  }

  const createBtnActions = (aviso) => [
    (aviso.estadoAviso.id === 1 || aviso.estadoAviso.id == 2) ? <TableActionIcon key={'edit'} title='Editar' width='33%' icon='edit' onClick={() => { setAvisoToUpdate(aviso); setShowAddEditModal(true) }} /> : null,
    (aviso.estadoAviso.id === 1 || aviso.estadoAviso.id == 2) ? <TableActionIcon key={'cancel'} title='Cancelar' width='33%' icon='trash' onClick={() => { idAvisoToDelete = aviso.id; setShowModalDelete(true) }} /> : null,
    <TableActionIcon key={'bitacora'} title='Ver Bitácora' width='33%' icon='book' onClick={() => { idDatoBitacora = aviso.id; setShowModalBitacora(true) }} />,
  ];
  
  const getEstadoAviso = (estado) => {
    if( estado.trim().toLowerCase() == 'planificado' ) return ( <Badge pill bg='primary'><span className='fs--2'>{ estado }</span></Badge> );
    if( estado.trim().toLowerCase() == 'en curso' ) return ( <Badge pill bg='warning'><span className='fs--2'>{ estado }</span></Badge> );
    if( estado.trim().toLowerCase() == 'completado' ) return ( <Badge pill bg='success'><span className='fs--2'>{ estado }</span></Badge> );
    if( estado.trim().toLowerCase() == 'cancelado' ) return ( <Badge pill bg='danger'><span className='fs--2'>{ estado }</span></Badge> );
  }

  const addNewAviso = useCallback((aviso) => {
    setAvisosMantencion([...formatAvisosToTable([aviso]), ...avisosMantencion,])
  }, [avisosMantencion, createBtnActions])

  const updateAviso = (aviso) => {
    const avisoFormated = formatAvisosToTable([aviso])[0];
    const avisosActualizados = avisosMantencion.map( oldAviso => oldAviso.id === avisoFormated.id ? avisoFormated : oldAviso );
    setAvisosMantencion(avisosActualizados);
  };

  const handleDeleteAviso = async () => {
    setIsLoading(true);
    const avisoToCanceled = await deleteAviso(idAvisoToDelete);
    const avisoFormated = formatAvisosToTable([avisoToCanceled.data])[0];
    
    const avisosActualizados = avisosMantencion.map( oldAviso => oldAviso.id === avisoFormated.id ? avisoFormated : oldAviso );
    idAvisoToDelete = 0;
    
    setAvisosMantencion(avisosActualizados);

    setIsLoading(false);
    setShowModalDelete(false);
  };

  useEffect( async () => {
    getAvisosMantencion();
    setEstadosAvisosMantencion(await getAllEstadosAvisosMantencion());
    setReadyContent(true);
  }, []);

  return (
    <>
      <PageLayout key={'avisoMantenciones'} title='Aviso de Mantenciones' >
        
        { readyContent === false 
          ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>
          : (
            <Card className="h-md-100">
              <Card.Body>

                <TableWrapper
                  key={'Tabla'}
                  columnas={columnasTabla}
                  data={avisosMantencion}
                  elementosPorPagina={10}
                  titleToExport={'Avisos de Mantencion'}
                  subtitleToExport={'Tabla de avisos de mantenciones del sistema'}
                >
                  <Row className="mb-3 text-center">
                    <Col xs={12} md={4} lg={3} className='mx-auto mb-2'>
                      { <TableHeaderButton key={'nuevaCategoria'} text='Nuevo Aviso' icon='plus' type='success' onClick={() => setShowAddEditModal(true)} /> }
                    </Col>
                    <Col xs={12} md={4} lg={3} className='mx-auto mb-2'>
                      { <TableHeaderButton key={'bitacoraGeneral'} text='Bitácora General' icon='book' type='primary' onClick={() => { setShowModalBitacora(true); idDatoBitacora = 0 }} /> }
                    </Col> 
                  </Row>
                </TableWrapper>
                  
              </Card.Body>
            </Card>
          )
        }

      </PageLayout>

      <BitacoraData
        title={ idDatoBitacora > 0 ? 'Bitácora del Dato' : 'Bitacora General' }
        titleToExport={'Bitácora'}
        subtitleToExport={'Tabla de Bitácora'}
        showBitacoraData={showModalBitacora}
        setShowBitacoraData={setShowModalBitacora}
        idData={idDatoBitacora}
        idEntidad={idEntidad}
      />

      {/* MODALES */}
      <ModalAddEditAviso 
        showAddEditModal={showAddEditModal}
        setShowAddEditModal={setShowAddEditModal}
        avisoToUpdate={avisoToUpdate}
        estadosList={estadosAvisosMantencion}
        setAvisoToUpdate={setAvisoToUpdate} 
        addAviso={addNewAviso}
        editAviso={updateAviso}
        setIsLoading={setIsLoading}
      />

      {/* Modal Eliminar */}
      <ModalConfirmacion
        showModal={showModalDelete}
        setShowModal={setShowModalDelete}
        title='Cancelar Aviso de Mantencion'
        msg='¿Está seguro de querer cancelar el aviso de mantencion?'
        confirmar={handleDeleteAviso}
        btnVariant='danger'
        textDecline='Volver'
        textAcept='Aceptar'
      />

    </>
  )
}
